<template>
  <div>
    <div v-if="thread" class="bg-black z-3">
      <div @click="goBack" class="d-md-none">
        Back
      </div>
      <div class="container-fluid g-0">
        <div class="row g-0">
          <div class="col-4 col-md-2">
            <PostBit :user="thread.author"/>
          </div>
          <div class="col-8 col-md-10">
            <div class="p-3">
              <h4>{{ thread.title }}</h4>

              <div v-html="thread.formatted_post" class="p-3"></div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="thread.comments" class="container-fluid g-0">
        <div class="row border-bottom border-dark g-0"
             v-for="(comment, index) in thread.comments.data" :key="index">
          <ForumPost :comment="comment" @quote="quote"/>
        </div>
        <div class="position-relative p-3 border-1 rounded-2 border-white p-md-5">
          <loading-spinner :loading="form.loading"/>
          <div class="form-group mb-2">
            <ReplyField name="comment" @reply-update="updateComment" :value="form.comment"/>
          </div>

          <button @click="reply" class="btn btn-primary">Comment</button>
        </div>

        <div>
          <Bootstrap5Pagination :data="thread.comments" :show-disabled="true"
                                :limit="1"
                                @pagination-change-page="getPosts"/>
        </div>
      </div>
      <div v-else>
        test
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import TimeAgo from "@/components/timeago";
import Username from "@/components/Username";
import {Bootstrap5Pagination} from "laravel-vue-pagination";
import ReplyField from "@/components/ReplyField.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PostBit from "@/components/forums/PostBit.vue";
import ForumPost from "@/components/feed/ForumPost.vue";

export default {
  name: 'ForumThread',
  components: {
    PostBit,
    ForumPost, LoadingSpinner, ReplyField, Bootstrap5Pagination
  },
  data() {
    return {
      pagination: {
        per_page: 15,
        page: 1
      },
      form: {
        comment: '',
        loading: false,
      }
    }
  },
  watch: {
    thread() {
      if (this.thread?.new) {
        this.thread.new = false;
      }

      this.getPosts();
    }
  },
  mounted() {

    this.getPosts();
  },
  computed: {
    ...mapGetters({
      findThread: "forum/threadBySlug"
    }),
    thread() {
      return this.findThread(this.$route.params.slug)
    },
  },
  methods: {
    updateComment(value) {
      this.form.comment = value;
    },
    goBack() {
      this.$router.go(-1);
    },
    getPosts(page = 1) {
      if (this.$route.params['slug'])
        this.$http.get(`/forums/${this.$route.params['slug']}/posts?page=${page}`)
          .then(res => {
            const thread = this.$store.state.forum?.threads?.data?.find(t => t.id === this.thread.id);
            if (thread)
              thread.comments = res.data;
          });
    },
    quote(details) {
      this.form.comment += "[quote=" + details.username + "]" + details.post + "[/quote]\n";
      document.getElementById('text-comment').focus()
    },
    reply() {
      if (this.form.loading)
        return;

      this.form.loading = true;


      this.$http.post(
        `forums/${this.$route.params['slug']}/reply`,
        {
          post: this.form.comment
        }
      ).finally(() => {
        this.form.comment = '';
        this.form.loading = false;
      }).catch(err => {
      })
    }
  }
}
</script>
