<template>
  <component :is="typeOfComponent" :title="timeFormatted">
    <i class="bi bi-clock me-1 text-small" v-if="icon"/> {{ timeAgo }} {{ timeAgo !== "Now" ? "ago" : ""}}
  </component>
</template>
<script>
export default {
  name: 'TimeAgo',
  props: {
    time: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    typeOfComponent() {
      return this.inline ? 'span' : 'div'
    },
    timeAgo() {

      const seconds = Math.floor((new Date(this.$store.state.game.time) - new Date(this.time)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " year" + (Math.floor(interval) !== 1 ? 's' : '');
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " month" + (Math.floor(interval) !== 1 ? 's' : '');
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " day" + (Math.floor(interval) !== 1 ? 's' : '');
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hour" + (Math.floor(interval) !== 1 ? 's' : '');
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minute" + (Math.floor(interval) !== 1 ? 's' : '');
      }
      return "< 1 minute";
    },
    timeFormatted() {
      return (new Intl.DateTimeFormat('en-gb', {
        dateStyle: "full",
        timeStyle: "full"
      })).format(new Date(this.time))
    }
  }
}
</script>
