<template>
  <div class="position-relative h-100">
    <div class="container-fluid g-0">
      <div class="row g-0" v-if="hasJob">
        <Transition>
          <Job :id="hasJob.id" class="col-12" />
        </Transition>
      </div>
      <div class="row g-0 fade-single-container" v-else>
        <TransitionGroup>
          <Job class="col-12 col-md-6 col-lg-4" v-for="job in jobs" :key="job.id" :id="job.id"/>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import Job from "@/components/jobs/Job";

export default {
  name: 'Jobs',
  components: {Job},
  mounted() {
    if (this.jobs.length === 0)
      this.$http.get('jobs')
        .then(res => this.$store.state.jobs.jobs = res.data)
  },
  computed: {
    ...mapState({
      jobs: state => state.jobs.jobs,
      user: state => state.user,
    }),
    hasJob() {
      return this.jobs.find(j => j.user_id === this.user.id && j.completed_at === null)
    }
  },
  methods: {

  }
}
</script>
