import {createApp} from 'vue';
import VueSocketIO from 'vue-3-socket.io';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/scss/app.scss';
import http from "./helpers/http";
import VueAxios from "vue-axios";
import FloatingVue from 'floating-vue'
import Vue3Toastify from "vue3-toastify";
import * as Sentry from '@sentry/vue'
import {VTooltip} from "v-tooltip";
import {PushNotifications} from "@capacitor/push-notifications";
import {App as CapacitorApp} from '@capacitor/app'

let app = createApp(App);

app.use(store)
app.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_SOCKET_URL,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_',
  },
  options: {
    transports: ['websocket']
  }
}))
app.use(VueAxios, http)
app.use(Vue3Toastify, {
  position: 'top-right',
  theme: 'dark',
  transition: 'slide'
})
app.use(router)
app.use(FloatingVue);
app.provide('axios', app.config.globalProperties.axios);

CapacitorApp.addListener('resume', async () => {
  try {
    const info = await CapacitorApp.getInfo();

  } catch (err) {
  }
})


Sentry.init({
  app,
  dsn: "https://abccf4418ba44dd092ec38fef59f710b@o4505446987399168.ingest.sentry.io/4505446988513280",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["*"],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app = app.mount("#app");

window.date = new Date();

app.$socket.on("reconnect", () => {
  // Re authenticate on disconnect
  app.$socket.emit('authentication', localStorage.getItem('_token'));
})

setInterval(() => store.state.game.time = new Date(), 1000);

try {

  await PushNotifications.addListener('registration', token => {
    console.info('PN Registration token: ', token.value);
    app.$http.post('/push-notifications', {
      token: token.value
    });
  });


  await PushNotifications.addListener('registrationError', err => {
    console.error('PN Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', notification => {

  });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {

  });

} catch (err) {
}

CapacitorApp.addListener('backButton', ({canGoBack}) => {
  if (!canGoBack) {
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});
