<template>
  <div class="scroll-container message-list-scroll-container" ref="MessageListContainer">
    <div class="alert-info position-sticky" style="top: 0;" v-if="newMessages">
      You have new messages, scroll down
    </div>
    <div class="message" v-for="(message, index) in messages"
         :key=index
         :class="{
                'me': message.user.id === userid,
                'them': message.user.id !== userid,
                'bg': !this.isGiphy(message.body),
            }">
      <div>
        <img :src="message.body" v-if="isGiphy(message.body)"
             style="width: 320px;height: 200px;"/>
        <span v-else>{{ message.body }}</span>
      </div>
    </div>
  </div>
</template>
<script>

import {mapGetters} from "vuex";
import {nextTick} from "vue";

export default {
  name: 'MessageList',
  props: [
    'messages'
  ],
  data: function () {
    return {
      newMessages: false,
    }
  },
  computed: {
    ...mapGetters({
      userid: "user/id"
    }),

    conversationId() {
      return parseInt(this.$root.$route.params.conversation);
    }
  },
  components: {},
  created() {

    // let el = this.$root.$el.querySelector(".message-list-container")
    //
    // el.scrollTop = el.scrollHeight;

    setTimeout(() => {
      //
      // this.$refs.MessageListContainer.$el.scrollToY(10000000);
    }, 50)
  },
  methods: {
    scrollCheck() {

      // let el = this.$root.$el.querySelector(".message-list-container"),
      //     diff = (el.scrollHeight - el.scrollTop) - el.clientHeight;
      //
      //
      //
      // if (diff === 0 && this.newMessages)
      //     this.newMessages = false;
    },
    isGiphy(message) {
      try {
        const url = new URL(message);
        if (url.origin.endsWith('giphy.com'))
          return true;
      } catch (e) {
        return false;
      }
    }
  },
  watch: {
    messages() {
      // setTimeout(() => {
      //   const container = this.$refs['MessageListContainer'];
      //
      //
      //   if (container) {
      //     container.scrollTop = container.scrollHeight;
      //   }
      // }, 0)
    }
  }
}
</script>
