<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <Card>
          <template #header>
            <h3>Layout Colors</h3>
          </template>
          <template #body>
            <table class="">
              <tr>
                <td>Primary Color</td>
                <td>
<!--                  <ColorPicker picker-type="chrome" v-model:pureColor="primary"/>-->
                </td>
              </tr>
              <tr>
                <td>
                  Secondary Color
                </td>
                <td>
<!--                  <ColorPicker picker-type="chrome" v-model:pureColor="secondary"/>-->
                </td>
              </tr>
            </table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/layout/Card";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'Settings',
  components: {Card},
  data() {
    return {
      primary: '#000',
      secondary: '#000',
    }
  },
  computed: {
    ...mapGetters({
      layout: "layout/layout"
    }),

  },
  mounted() {

    this.primary = this.layout.colors.primary;
    this.secondary = this.layout.colors.secondary;
  },
  watch: {
    primary(value, old) {
      this.$store.dispatch("layout/UPDATE_THEME", {
        primary: value,
      })
    },
    secondary(value, old) {
      this.$store.dispatch("layout/UPDATE_THEME", {
        secondary: value
      })
    },
  },
  methods: {
    change() {

    },
  }
}
</script>
