import {createRouter, createWebHistory} from 'vue-router';
import Game from '@/views/Game'
import Blank from "@/views/Blank";
import Airport from "@/views/game/Airport";
import Crimes from "@/views/game/Crimes";
import AvatarBuilder from "@/views/Various/AvatarBuilder";
import Home from "@/views/Various/Home";
import Forum from "@/views/Communication/Forums/Forum";
import ForumThread from "@/views/Communication/Forums/ForumThread";
import GTA from "@/views/game/GTA";
import Settings from "@/views/game/Settings";
import Garage from "@/views/game/Garage";
import Slots from "@/views/Casino/Slots";
import CreateThread from "@/views/Communication/Forums/CreateThread";
import Jail from "@/views/game/Jail";
import GameStats from "@/views/Various/GameStats";
import Jobs from "@/views/game/Jobs";
import Profile from "@/views/Profile";
import Achievements from "@/views/Various/Achievements";
import EditProfile from "@/views/EditProfile";
import TrainStation from "@/views/game/TrainStation";
import Bank from "@/views/Money/Bank";
import BankDeposit from "@/views/Money/Bank/BankDeposit";
import Lottery from "@/views/Casino/Lottery/Lottery";
import CrewHome from "@/views/Crews/Crews.vue";
import CrewCity from "@/views/Crews/CrewCity";
import CrewBase from "@/views/Crews/CrewBase";
import Chase from "@/views/Chase/Chase";
import ViewMessage from "@/views/Communication/Messaging/ViewMessage.vue";
import Messaging from "@/views/Communication/Messaging/Messaging.vue";
import SendMessage from "@/views/Communication/Messaging/SendMessage.vue";

import store from "@/store";
import War from "@/views/Casino/War.vue";
import Feed from "@/views/Various/Feed.vue";
import MyAccount from "@/views/Various/MyAccount.vue";
import Blackjack from "@/views/Casino/Blackjack.vue";
import Keno from "@/views/Casino/Keno.vue";
import Extortion from "@/views/game/Extortion.vue";
import Inventory from "@/views/game/Inventory.vue";
import Healthcare from "@/views/game/Healthcare.vue";
import Character from "@/views/Auth/Character.vue";
import CrewMembers from "@/views/Crews/CrewMembers.vue";
import Helpdesk from "@/views/Helpdesk/Helpdesk.vue";
import Smuggling from "@/views/game/Smuggling.vue";
import Ticket from "@/views/Helpdesk/Ticket.vue";
import MyProgress from "@/views/Various/MyProgress.vue";
import Gym from "@/views/game/Gym.vue";
import Fight from "@/views/game/Fight.vue";
import MySkills from "@/views/Various/MySkills.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Game,
  },
  {
    path: '/game',
    component: Game,
    children: [
      {
        path: 'bullet-factory',
        name: 'bullet-factory',
        component: Blank,
      },
      {
        path: 'cities',
        name: 'cities',
        component: Blank,
      },
      {
        path: 'gta',
        name: 'gta',
        component: GTA,
        meta: {
          showTimer: 'jail'
        }
      },
      {
        path: 'crimes',
        name: 'crimes',
        component: Crimes,
        meta: {
          showTimer: 'jail'
        }
      },
      {
        path: 'extortion',
        name: 'extortion',
        component: Extortion,
        meta: {
          showTimer: 'jail'
        }
      },
      {
        path: 'protection-racket',
        name: 'protection-racket',
        component: Blank,
      },
      {
        path: 'airport',
        name: 'airport',
        component: Airport,
        meta: {
          showTimer: 'jail'
        }
      },
      {
        path: 'train-station',
        name: 'train-station',
        component: TrainStation,
        meta: {
          showTimer: 'jail'
        }
      },
      {
        path: 'prison',
        name: 'prison',
        component: Jail,
      },
      {
        path: 'smuggling',
        name: 'smuggling',
        component: Smuggling,
        meta: {
          showTimer: 'jail'
        }
      },
      {
        path: 'hijack',
        name: 'hijack',
        component: Blank,
        meta: {
          showTimer: 'jail'
        }
      },
      {
        path: 'crews/turf',
        name: 'crew-turf',
        component: Blank,
      },
      {
        path: 'crews/base',
        name: 'crew-base',
        component: CrewBase,
      },
      {
        path: 'crews/city',
        name: 'crew-city',
        component: CrewCity,
      },
      {
        path: 'crews/members',
        name: 'crew-members',
        component: CrewMembers,
      },
      {
        path: 'crews',
        name: 'crews',
        component: CrewHome,
      },
      {
        path: 'criminal-jobs',
        name: 'criminal-jobs',
        component: Jobs,
      },
      {
        path: 'gun-smuggling',
        name: 'gun-smuggling',
        component: Blank,
      },
      {
        path: 'healthcare',
        name: 'health-care',
        component: Healthcare,
      },
      {
        path: 'murder',
        name: 'murder',
        component: Blank,
      },
      {
        path: 'operations',
        name: 'operations',
        component: Blank,
      },
      {
        path: 'organised-crime',
        name: 'organised-crime',
        component: Blank,
      },
      {
        path: 'robbery',
        name: 'robbery',
        component: Blank,
      },
      {
        path: 'warehousing',
        name: 'warehousing',
        component: Blank,
      },
      {
        path: 'bank',
        name: 'bank',
        component: Bank,
      },
      {
        path: 'bank/deposit',
        name: 'bank-deposit',
        component: BankDeposit,
      },
      {
        path: 'credits',
        name: 'credits',
        component: Blank,
      },
      {
        path: 'marketplace',
        name: 'marketplace',
        component: Blank,
      },
      {
        path: 'escrow',
        name: 'escrow',
        component: Blank,
      },
      {
        path: 'gun-store',
        name: 'gun-store',
        component: Blank,
      },
      {
        path: 'forums/:category',
        name: 'forums',
        component: Forum,
        children: [
          {
            path: 'thread/create',
            name: 'forums.new.thread',
            components: {
              thread: CreateThread
            },
          },
          {
            path: ':slug',
            components: {
              thread: ForumThread
            },
            name: 'forum.thread'
          },
        ]
      },
      {
        path: 'messaging',
        name: 'messaging',
        component: Messaging,
        children: [
          {
            path: ':conversation',
            name: 'messaging.view',
            component: ViewMessage
          }
        ]
      },
      {
        path: 'messaging/create',
        name: 'messaging.send',
        component: SendMessage
      },
      {
        path: 'help-desk',
        name: 'help-desk',
        component: Helpdesk,
      },
      {
        path: 'help-desk/:id',
        name: 'help-desk.ticket',
        component: Ticket,
      },
      {
        path: 'ask-a-question',
        name: 'ask-a-question',
        component: Blank,
      },
      {
        path: 'knowledge-base',
        name: 'knowledge-base',
        component: Blank,
      },
      {
        path: 'player-search',
        name: 'player.search',
        component: Blank,
      },
      {
        path: 'blackjack',
        name: 'blackjack',
        component: Blackjack,
      },
      {
        path: 'keno',
        name: 'keno',
        component: Keno,
      },
      {
        path: 'racetrack',
        name: 'racetrack',
        component: Blank,
      },
      {
        path: 'lottery',
        name: 'lottery',
        component: Lottery,
      },
      {
        path: 'poker',
        name: 'poker',
        component: Blank,
      },
      {
        path: 'roulette',
        name: 'roulette',
        component: Blank,
      },
      {
        path: 'slots',
        name: 'slots',
        component: Slots,
      },
      {
        path: 'war',
        name: 'war',
        component: War
      },
      {
        path: 'video-poker',
        name: 'video-poker',
        component: Blank,
      },
      {
        path: 'achievements',
        name: 'achievements',
        component: Achievements,
      },
      {
        path: 'game-stats',
        name: 'game-stats',
        component: GameStats,
      },
      {
        path: 'home',
        name: 'home',
        component: Home,
      },
      {
        path: '',
        component: Home,
      },
      {
        path: 'feed',
        name: 'feed',
        component: Feed,
      },
      {
        path: 'my-account',
        name: 'my-account',
        component: MyAccount,
      },
      {
        path: 'my-progress',
        name: 'my-progress',
        component: MyProgress,
      },
      {
        path: 'my-skills',
        name: 'my-skills',
        component: MySkills,
      },
      {
        path: 'players-online',
        name: 'players-online',
        component: Blank,
      },
      {
        path: 'support-crimebloc',
        name: 'support-crimebloc',
        component: Blank,
      },
      {
        path: 'garage',
        name: 'garage',
        component: Garage,
      },
      {
        path: 'gym',
        name: 'gym',
        component: Gym,
      },
      {
        path: 'fight',
        name: 'fight',
        component: Fight
      },
      {
        path: 'profile/:username',
        name: 'profile',
        component: Profile,
      },
      {
        path: 'profile/:username/edit',
        name: 'profile-edit',
        component: EditProfile,
      },
      {
        path: 'avatar/builder',
        name: 'avatar-builder',
        component: AvatarBuilder,
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings
      },
      {
        path: 'chase',
        name: 'chase',
        component: Chase,
        meta: {
          showTimer: 'jail'
        }
      },
      {
        path: 'inventory',
        name: 'inventory',
        component: Inventory
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Auth/Register.vue')
  },
  {
    path: '/character',
    name: 'Character',
    component: Character
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    const container = document.querySelector('#layout-content');

    if (container) {
      container.scrollTop = 0;
    }

    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {

  const isLoggedIn = localStorage.getItem('_token');
  const characterSelected = localStorage.getItem('_character')

  const loggedOutRoutes = [
    'Login',
    'Register',
  ];

  if (isLoggedIn) {

    if(!characterSelected && to.name !== 'Character') {
      router.push({
        name: 'Character'
      });

      return next();
    }

    if(loggedOutRoutes.includes(to.name)) {
      if(characterSelected) {
        router.push({
          name: 'home'
        });
      } else {
        router.push({
          name: 'Character'
        })
      }
    }
  }

  if (!isLoggedIn && !loggedOutRoutes.includes(to.name)) {
    router.push({
      name: 'Login'
    });
  }

  // Hack to make sure slide animation works on mobile
  if (window.innerWidth < 992)
    store.state.layout.menu_nav_open = false;

  return next();
})

export default router;
