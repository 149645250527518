export const SearchStore = {
  namespaced: true,
  state: () => ({
    overlayOpen: false,
    results: [],
    loading: []
  }),
  mutations: {
    OPEN_SEARCH(state) {
      state.overlayOpen = true;
    },
    CLOSE_SEARCH(state) {
      state.overlayOpen = false;
    },
    PUSH_RESULTS(state, data) {
      state.results.push(data);

      const index = state.loading.indexOf(data.name);

      if(index < 0) return;
      state.loading.splice(index, 1)
    },
    CLEAR_RESULTS(state, name) {
      state.results = state.results.filter(r => r === name)
    },
    FILTER_RESULTS(state, data) {
      let {name, term} = data;
      let res = state.results.find(c => c === name)

      res.data = res.data.filter(d => d.username.includes(term))
    },
    PUSH_LOADING(state, data) {
      state.loading.push(data);

    },
  },
  actions: {
  },
  getters: {
  }
}
