<template>
  <div class="bg-gradient-black-transparent min-h-100">
    <div class="container-fluid">
      <div class="row" v-if="user">
        <div class="col-12 col-lg-2 d-flex flex-column bg-gradient-black-transparent"
             :class="{'bg-success': user && user.last_online === 'Online','bg-danger': user && user.last_online !== 'Online'}">
          <div class="d-flex flex-column sticky-top">
            <router-link v-if="isLoggedInUser"
                         :to="{name: 'profile-edit', params: {username: user.username}}"
                         class="btn btn-primary btn-block bg-gradient-primary"
            >
              Edit Profile
            </router-link>

            <button @click="removeFriend" v-if="friend">Remove Friend</button>
            <button @click="addFriend" v-if="!friend && !pending">Add Friend</button>
            <button @click="removeFriend" v-if="pending">Cancel Friend Request</button>
            <button @click="unblockUser" v-if="blocked">Unblock User</button>
            <button @click="blockUser" v-else>Block User</button>

<!--            <img :src="user.avatar.body"/>-->

            <h5>Rank</h5>
            <div class="mb-4 d-flex align-items-center">
              <i class="flaticon-experience me-2 icon-gradient"/>{{ user.rank.name }}
            </div>
            <h5>Money Rank</h5>
            <div class="mb-5 d-flex align-items-center">
              <i class="flaticon-money me-2 icon-gradient"/> {{ user.money_rank }}
            </div>

            <div v-if="user.roles.length > 0">
              <h5>Roles</h5>
              <div class="d-flex flex-wrap">
              <span v-for="(role, index) in user.roles" :key="index" v-html="role"
                    class="badge rounded-pill bg-dark d-inline p-2 px-3 me-2 mb-2"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="d-flex flex-row">
            <div class="d-flex align-items-center">
              <h2 class="">
                {{ user.username }}
              </h2>

              <span :class="{'text-success': user.last_online === 'Online'}"
                    class="ms-5 fs-6 badge rounded-pill bg-dark">
              {{ user.last_online }}
            </span>
            </div>

            <div class="mb-4" v-if="user.status">
              {{ user.status || "No status set yet" }}
            </div>

            <div class="mb-3 ms-auto">

              <AchievementBar :achievements="user.achievements"></AchievementBar>

            </div>
          </div>

          <div v-html="user.quote || `<center>No Profile Quote :(</center>`"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import http from "@/helpers/http";
import AchievementBar from "@/components/achievements/AchievementBar";

export default {
  name: 'Profile',
  components: {AchievementBar},
  data() {
    return {
      userProfileData: null
    }
  },
  watch: {
    username() {
      if (this.$route.name === 'profile')
        this.getProfile()
    }
  },
  mounted() {
    this.getProfile();
  },
  computed: {
    ...mapGetters({
      isFriend: "user/isFriend",
      isBlocked: "user/isBlocked",
      isPendingFriendRequest: "user/isPendingFriendRequest"
    }),
    username() {
      return this.$route.params.username
    },
    user() {
      let queryUser = this.$route.params.username;

      // if(queryUser === this.userLoggedIn.username)
      //   return this.userLoggedIn;

      return this.userProfileData;
    },
    ...mapState({
      userLoggedIn: state => state.user
    }),
    isLoggedInUser() {
      return this.userLoggedIn.username === this.$route.params.username
    },
    ...mapGetters({
      getUserByUsername: "userCollection/getUserByUsername",
    }),
    friend() {

      return this.isFriend(this.user.username)
    },
    blocked() {
      return this.isBlocked(this.user.username)
    },
    pending() {
      return this.isPendingFriendRequest(this.user.username);
    }
  },
  methods: {
    getProfile() {

      http.get(`/profile/username/${this.$route.params.username}/overview`)
        .then(res => {
          this.userProfileData = res.data
        });
    },
    addFriend() {
      this.$http.post('/relationships/befriend', {
        username: this.username,
      }).catch(() => {
      });
    },
    declineFriendRequest(username) {
      this.$http.post('/relationships/ignore', {
        username
      }).catch(() => {
      })
    },
    removeFriend() {
      if (window.confirm("Are you sure you want to remove this person?"))
        this.$http.post('/relationships/removeFriend', {
          username: this.username
        })
    },
    blockUser() {
      if (window.confirm("Are you sure you want to block this person?"))
        this.$http.post('/relationships/block', {
          username: this.username
        })
    },
    unblockUser() {
      if (window.confirm("Are you sure you want to un block this person?"))
        this.$http.post('/relationships/unblock', {
          username: this.username
        })
    },

  }
}
</script>
