export const NotificationStore = {
  namespaced: true,
  state: () => ({
    notifications: [],
    page: 0,
  }),
  actions: {
    SOCKET_PRIVATE_NOTIFICATION(ctx, data) {
      ctx.state.notifications.unshift(data)
      //
      if(ctx.state.notifications.length > 100)
        ctx.state.notifications = ctx.state.notifications.slice(0, 100)
    },
    SOCKET_GLOBAL_NOTIFICATION(ctx, data) {
      ctx.state.notifications.unshift(data)
      //
      if(ctx.state.notifications.length > 100)
        ctx.state.notifications = ctx.state.notifications.slice(0, 100)
    },
  },
  mutations: {
    SET_NOTIFICATIONS(state, data) {

      for (let i =0;i<data.length;i++) {
        state.notifications.push(data[i])
      }
      state.page += 1;
    }
  },
  getters: {
    items: (state) => {
      return state.notifications;
    },
    privateItems: (state) => {
      return state.notifications.filter(d => d.event === 'PRIVATE_NOTIFICATION');
    },
    item: (state) => (id) => {
      return state.notifications.find(s => s.id === id)
    },
    page: (state) => {
      return state.page
    }
  }
}
