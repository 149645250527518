<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col">
        <Card class="mb-4">
          <template #body>
            You currently have <strong :class="{'text-success': stats['skill_points'] > 0}">{{ stats['skill_points'] }}</strong> skill points to spend.

            <p class="mb-0">
              The more you level up a particular skill the more points it will cost per level
            </p>
          </template>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-4" v-for="(skill, index) in config" :key="index">
        <Skillbox :skill="skill" :skill-name="index"/>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import Skillbox from "@/components/SkillBox.vue";
import Card from "@/components/layout/Card.vue";

export default {
  name: 'MySkills',
  components: {Card, Skillbox},
  computed: {
    ...mapGetters({
      "skills": "user/skills",
      "config": "game/skills",
      "stats": "user/stats"
    })
  }
}
</script>
