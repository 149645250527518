<template>
  <div class="bg-black bg-opacity-75 z-20 d-flex" :class="{'layout-timer-overlay absolute-top-left w-100': !wholePage}">
      <div class="d-flex justify-content-center align-items-center flex-column w-100">
        <h4 class="fw-bold text-uppercase">{{ header ?? "In Jail"}}</h4>

        <h1 class="fw-bolder text-secondary shadow-secondary text-gradient-primary">{{ timerSeconds === '' ? 0 : timerSeconds }}s</h1>
        <h6>{{ postText ?? "until release" }}</h6>
      </div>
  </div>
</template>
<script>
import TimeUntil from "@/components/timeuntil";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'WaitTimerOverlay',
  props: {
    header: {
      type: String
    },
    postText: {
      type: String
    },
    timer: {
      type: String,
      required: true,
    },
    wholePage: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState({
      jailTimer: state => state.user.timers.jail
    }),
    ...mapGetters({
      timeLeft: "user/timerTimeLeft"
    }),
    timerSeconds() {
      return this.timeLeft(this.timer)
    }
  }
}
</script>
