<template>
  <div class="d-flex flex-row">

    <Avatar :user="item.item" class="me-3"/>
    <div class="flex-grow-1">
      <div class="d-flex flex-row mb-2">
        <username :username="item.item.username" :icon="false"/>
        &nbsp;achieved a new milestone&nbsp;
        <span class="ms-auto">
          <TimeAgo :time="item.created_at"/>
        </span>
      </div>

      <Card>
        <template #body>
          Ranked up to {{ item.type_misc.rank }}
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import Username from "@/components/Username.vue";
import Card from "@/components/layout/Card.vue";
import Avatar from "@/components/user/Avatar.vue";
import TimeAgo from "@/components/timeago.vue";

export default {
  name: "RankUp",
  components: {TimeAgo, Avatar, Card, Username},
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
