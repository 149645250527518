<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8 col-xl-6 offset-lg-2">
          <Card>
            <template #header>
              <h3>Keno</h3>
            </template>

            <template #body>
              <div class="d-flex justify-content-center align-items-center flex-column w-100">
                <p>Match 5 or more numbers to win</p>

                <div class="d-flex flex-row w-100 overflow-x-scroll justify-content-center">
                  <div class="keno-grid d-flex flex-row flex-wrap">
                    <div v-for="i in 80" :key="i"
                         class="keno-cell d-flex justify-content-center align-items-center"
                         :class="{
                  'bg-success text-dark': isSelected(i) && !hasSelectedWinner(i),
                  'bg-secondary': isWinner(i) && !hasSelectedWinner(i),
                  'bg-danger text-white': hasSelectedWinner(i),
                  'bg-dark': !isSelected(i) && !isWinner(i)
               }"
                         @click="select(i)">
                      {{ i }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #footer>
              <button class="btn" @click="chooseRandom">Lucky Dip</button>

              <FancyNumber :id="'bet'" @ev="(input) => {bet = input}"
                           :value="bet"></FancyNumber>
              <button @click="makeBet" class="btn">Bet</button>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/layout/Card.vue";
import FancyNumber from "@/components/FancyNumber.vue";

export default {
  name: "Keno",
  components: {FancyNumber, Card},
  data() {
    return {
      selected: [],
      winningNumbers: [],
      bet: '$'
    }
  },
  methods: {
    isSelected(i) {
      return this.selected.includes(i)
    },
    isWinner(i) {
      return this.winningNumbers.includes(i)
    },
    hasSelectedWinner(i) {
      return this.isSelected(i) && this.isWinner(i)
    },
    select(i) {
      if (!this.isSelected(i) && this.selected.length < 10)
        this.selected.push(i);
      else if (this.isSelected(i)) {
        const index = this.selected.findIndex(a => a === i)
        this.selected.splice(index, 1)
      }
    },
    chooseRandom() {
      this.selected = [];

      while (this.selected.length < 10) {
        const rand = this.randomNumber(1, 80);
        if (!this.selected.includes(rand)) {
          this.selected.push(rand);
        }
      }
    },
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    makeBet() {
      this.$http.post('/casino/keno/bet', {
        choices: this.selected,
        bet: this.bet
      }).then(res => {
        this.winningNumbers = res.data.numbers;
      });
    }
  }
}
</script>
