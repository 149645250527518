<template>
  <div>
    <div class="card card-crimebloc mb-4">
      <div class="card-body d-flex align-items-center">
        Catch the train to {{ destination.name }} for just
        <i class="flaticon-money"/> {{ getPriceTo(name) }}
      </div>
      <div>
        {{ destination.weather }} {{ destination.temperature }}&#8451;

      </div>
      <div class="card-footer">
        <button @click.prevent="travel(destination.id)"
                class="btn btn-primary text-capitalize px-5 py-3">Travel to {{ destination.name }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'CityCard',
  props: {
    name: {
      type: String,
      required: true,
    },
    cityId: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      userCurrentCity: "user/city"
    }),
    ...mapState({
      cities: state => state.game.cities
    }),
    destination() {
      return this.cities.find(l => l.name === this.name)
    },
    currenCity() {
      return this.cities.find(l => l.id === this.userCurrentCity)
    }
  },
  methods: {
    getPriceTo(city) {
      let price = this.currentCity?.train_prices.find(p => p.destination === city)?.price || 0

      return new Intl.NumberFormat('en-gb').format(price)
    },

    travel(id) {
      this.loading = true;
      this.$http.post('train-station', {id})
        .then(() => {
          this.loading = false;
        })
    },
  }
}
</script>
