<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-12 col-md-8">
        <Card class="mb-5">
          <template #header><h4>Ticket #{{ $route.params.id }} - {{ ticket.subject }}</h4>
          </template>
          <template #body>
            {{ ticket.question }}
          </template>
        </Card>

        <Card v-for="(reply, index) in ticket.replies" :key="index" class="mb-3">
          <template #body>
            {{ reply.reply }}
          </template>
          <template #footer>
            <div class="d-flex justify-content-between align-items-center">
              <span>Written by <Username :username="reply.author.username" :icon="false" />
              <p class="mb-0">
                <span v-html="reply.author.roles.join(', ')"></span>
              </p></span>
              <TimeAgo :time="reply.created_at" inline :icon="false"/>
            </div>
          </template>
        </Card>

        <Card class="mt-4">
          <template #body>
            <form @submit.prevent="reply">
              <div class="mb-3">
                <label for="your_reply" class="form-label">Your reply</label>
                <textarea name="reply" id="your_reply" cols="30" rows="10" class="form-control" v-model="form.reply"></textarea>
              </div>

              <Button type="submit">
                Reply
              </Button>
            </form>
          </template>
        </Card>
      </div>
      <div class="col-12 col-md-4">
        <Card class="position-sticky" style="top:5px">
          <template #header>
            <h4>Details</h4>
          </template>
          <template #body>
            <table class="table table-dark table-hover table-bordered mb-0">
              <tbody>
              <tr>
                <th>ID</th>
                <td>#{{ ticket.id }}</td>
              </tr>
              <tr>
                <th>Category</th>
                <td>{{ ticket.category }}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{{ ticket.display_status }}</td>
              </tr>
              <tr>
                <th>Created</th>
                <td><TimeAgo :time="ticket.created_at" /></td>
              </tr>
              <tr>
                <th>Last Updated</th>
                <td><TimeAgo :time="ticket.updated_at" /></td>
              </tr>
              </tbody>
            </table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Card from "@/components/layout/Card.vue";
import TimeAgo from "@/components/timeago.vue";
import Button from "@/components/Button.vue";
import Username from "@/components/Username.vue";

export default {
  name: "Ticket",
  components: {Username, Button, TimeAgo, Card},
  data() {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters({
      "findTicket": "helpdesk/ticket"
    }),
    ticket() {
      return this.findTicket(this.$route.params.id)
    }
  },
  mounted() {
    this.getTicket();
  },
  watch: {
    id() {
      this.getTicket();
    }
  },
  methods: {
    getTicket() {
      this.$http.get('helpdesk/tickets')
        .then(res => {
          this.$store.commit("helpdesk/SET_TICKETS", res.data.tickets);
          this.$store.commit("helpdesk/SET_CATEGORIES", res.data.categories)
        })
    },
    reply() {
      this.$http.post(`helpdesk/ticket/${this.$route.params.id}`, this.form)
        .then(res => {
          this.$store.commit("helpdesk/ADD_REPLY", {
            id: this.$route.params.id,
            reply: res.data
          })
        })
    }
  }
}
</script>
