<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12">
        <Card>
          <template #header>
            <h4>Inventory</h4>
          </template>
          <template #body>
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="d-flex flex-row justify-content-between">
                    <div class="mb-4 text-center text-capitalize fs-2">
                      {{ getTitle(category) }}
                    </div>
                    <div class="filter-bar">
                      <ul class="list-unstyled d-flex flex-row justify-content-center">
                        <li v-for="(cat, index) in availableCategories" :key="index"
                            class="cursor-pointer"
                            :class="{'bg-dadrk': cat === category, 'opacity-25': cat !== category}"
                            @click="changeCategory(cat)">
                          <VTooltip>
                            <i :class="`flaticon-` + getIcon(cat)" class="fs-2 mx-3"/>

                            <template #popper>
                              <span class="text-capitalize">
                                {{ cat }}
                              </span>
                            </template>
                          </VTooltip>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <Item v-for="(item) in inventoryItemsList" :key="item.id" :item="item"/>
                <div class="alert alert-info mb-0" v-if="!inventoryItemsList.length">You have no
                  items in your inventory
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Card from "@/components/layout/Card.vue";
import Item from "@/components/inventory/Item.vue";

export default {
  name: "Inventory",
  components: {Item, Card},
  data() {
    return {
      inventoryItemsToShow: [
        'laptops',
        'pistols',
        'baseball_bats',
        'balaclavas',
        'bolt_cutters',
        '2_way_radios',
        'cell_phones',
        'pistol_magazines',
        'police_scanners',
        'custom_weapons',
        'special_bullets',
        'documents',
        'vault_burners',
        'gas_masks',
        'tear_gas_canisters',
      ],
      smuggledGoodsToShow: [],
      category: 'all',
      availableCategories: [
        'all',
        'weapon',
        'protection',
        'robbery_items',
        'misc',
      ]
    }
  },
  computed: {
    ...mapGetters({
      inventory: "user/items"
    }),
    inventoryItemsList() {
      return Object.entries(this.inventory)
        .filter(([, s]) => {
          if (this.category === 'all') {
            console.log(this.availableCategories, s.item.type, this.availableCategories.includes(s.item.type))
            return this.availableCategories.includes(s.item.type);
          }

          return s.item.type === this.category
        })
        .map(([, i]) => i);

    },
    smuggledItemsList() {
      return Object.entries(this.inventory)
        .filter(([, s]) => s.item.type === 'smuggle_misc')
        .map(([, i]) => i);
    }
  },
  methods: {
    changeCategory(category) {
      this.category = category;
    },
    getTitle(category) {
      switch (category) {
        case 'all':
          return 'Everything'
        case 'weapon':
          return 'guns'
        case 'protection':
          return 'armor'
        case 'robbery_items':
          return 'robbery items'
        default:
          return category
      }
    },
    getIcon(category) {
      switch (category) {
        case 'all':
        case 'weapon':
          return 'guns'
        case 'protection':
          return 'armor'
        case 'robbery_items':
          return 'robbery'
        default:
          return category
      }
    }
  }
}
</script>
