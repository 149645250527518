<template>
  <div>
    <CrewHeader/>

    <div class="container">
      <div class="row">
        <div class="col">

          <Card>
            <template #header>
              <h5>Members</h5>
            </template>
            <template #body>
              <table class="table table-striped table-hover">
                <thead>
                <tr>
                  <th width="10%"></th>
                  <th>Display Name</th>
                  <th>Rank</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="m in members" :key="m.id" class="align-middle">
                  <td>
                    <Avatar :user="m.user" />
                  </td>
                  <td>
                    <Username :username="m.user.username" />
                  </td>
                  <td>
                    {{ getRank(m.user.stats.find(s => s.key === 'rank').value)}}
                  </td>
                  <td class="">
                    <div class="d-flex">

                      <Button class="d-inline">Kick</Button>
                      <Button class="d-inline">Kick</Button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CrewHeader from "@/views/Crews/CrewHeader.vue";
import Card from "@/components/layout/Card.vue";
import Avatar from "@/components/user/Avatar.vue";
import {mapState} from "vuex";
import Button from "@/components/Button.vue";
import Username from "@/components/Username.vue";

export default {
  name: "CrewMembers",
  components: {Username, Button, Avatar, Card, CrewHeader},
  data() {
    return {
      members: []
    }
  },
  computed: {
    ...mapState({
      ranks: state => state.game.ranks
    })
  },
  mounted() {
    this.$http.get('/crews/members')
      .then(res => this.members = res.data)
  },
  methods: {
    getRank(rank) {
      return this.ranks[rank].name
    }
  }
}
</script>
