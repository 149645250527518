<template>
  <div>
    <Card :show-header="false" class="p-0" :class="{'bg-gradient-active-link bg-opacity-25': isSelected}">
      <template #body>
        <div @click="select"
             class="cursor-pointer">
          <div class="">
            <h4>{{ car.car.name }}</h4>
          </div>
          <div class="text-center">
            <img :src="'/images/cars/' + car.car.image" height="100"/>
            <div class="d-flex flex-column">
              <span class="d-flex align-items-center">
                <i class="flaticon-money me-2"/> {{ sellAmount }}
              </span>
              <span class="d-flex align-items-center">
                <i class="flaticon-bullets me-2"/> {{ smeltAmount }}
              </span>
              <span class="d-flex align-items-center">
                <i class="flaticon-car-damage me-2 text-success" :class="{
                'text-warning': car.damage > 0 && car.damage < 70,
                 'text-danger': car.damage >= 70
                }"/> {{ car.damage }}%
              </span>
              <span class="d-flex align-items-center">
                <i class="flaticon-location me-2"/> {{ city }}
              </span>

            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import Card from "@/components/layout/Card";

export default {
  name: 'Car',
  components: {Card},
  props: {
    id: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapState({
      cars: state => state.garage.cars,
    }),
    ...mapGetters({
      isMobile: "game/isMobile"
    }),
    car() {
      return this.cars.find(c => c.id === this.id)
    },
    sellAmount() {
      const num = ((parseInt(this.car.car.cost) / 100) * (100 - parseInt(this.car.damage)));

      return new Intl.NumberFormat('en-gb').format(num);
    },
    smeltAmount() {
      const num = Math.floor(((parseInt(this.car.car.smelt) / 100) * (100 - parseInt(this.car.damage))))

      return new Intl.NumberFormat('en-gb').format(num);
    },
    isSelected() {
      return !!this.$store.state.garage.selectedCars.includes(this.car.id);
    },
    city() {
      return this.$store.state.game.cities.find(c => c.id === this.car.city_id).name
    }
  },
  methods: {
    select(e) {
      const state = this.$store.state.garage;

      e.stopPropagation();

      let index = state.selectedCars.findIndex(c => c === this.car.id);
      if (index > -1) {
        state.selectedCars.splice(index, 1);
      } else {
        state.selectedCars.push(this.car.id);
      }
    }
  }
}
</script>
