<template>
  <Button disabled v-if="!timerReady()">
    <time-until :time="getAbsoluteTimer()"/>
  </Button>
  <Button class="float-end" :loading="loading" type="submit" v-else>
    {{ text }}
  </Button>
</template>
<script>
import Button from "@/components/Button.vue";
import TimeUntil from "@/components/timeuntil.vue";

export default {
  name: 'CountdownButton',
  components: {Button, TimeUntil},
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    timer: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true,
    }
  },
  data() {
    return {}
  },
  computed: {
  },
  methods: {
    getAbsoluteTimer() {
      return this.$store.getters['user/getTimerAbsoluteTime'](this.timer)
    },

    timerReady() {
      return this.$store.getters['user/timerReady'](this.timer)
    },
  }
}

</script>
