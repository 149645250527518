<template>
  <div v-if="crime && (isUnlocked || isNextCrime)"
       class="position-relative mb-4 h-100"
       @click="doCrime"
       :class="{
         'locked': isNextCrime,
         'btn-hover-active': !isNextCrime && timer === '',
         'cursor-pointer': !this.loading && timer === '',
         'pointer-events-none': this.loading
       }">
    <div class="d-flex flex-column align-items-center position-relative border-gradient-gold">
      <LoadingSpinner :loading="loading" :size="50"/>

      <Transition>
        <LockedFeature v-if="id > user.stats.crimes_unlocked" :text="unlockText" :icon="'lock'"
                       class="p-3 text-center"/>
      </Transition>
      <Transition>
        <WaitTimerOverlay :timer="`crimes_timer_${id}`" header="rest" post-text="left"
                          v-if="timer !== ''"/>
      </Transition>


      <Transition name="crime-result">
        <div v-if="result" class="crime-result">
          {{ result }}
        </div>
      </Transition>

      <div class="pe-none d-flex flex-column w-100 justify-content-end"
           :style="{
              background: `url(${crime.image})`,
              backgroundSize: 'cover'
           }">

        <div class="mt-0 mt-lg-5">

          <div class="progress w-100">
            <div class="progress-bar bg-success text-black"
                 :style="{width: `${progress}%`}"
            >
              {{
                progress >= 100 && user.stats[`crimes_mastery_${id}`] === 5 ? 'Complete' : progress + '%'
              }}
            </div>
          </div>
        </div>

        <span class="position-absolute top-0 right-0 m-3">
          <i class="flaticon-star me-1" v-for="n in user.stats[`crimes_mastery_${id}`]"
             :key="n">*</i>
        </span>

        <div class="d-flex justify-content-center bg-dark bg-opacity-75 flex-column">
          <h3 style="text-shadow: 0 0 3px black" class="mb-0 p-3 d-none d-md-block">
            {{ crime.title }}
          </h3>

          <h5 class="d-md-none p-2 mb-0">{{ crime.title }}</h5>
          <div class="d-flex justify-content-center">
            <Button :loading="loading" :disabled="timer !== ''" class="mb-0 mb-md-3">
              <div class="bg-clip">Commit</div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import WaitTimerOverlay from "@/components/WaitTimerOverlay";
import LockedFeature from "@/components/LockedFeature";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Button from "@/components/Button.vue";

export default {
  name: "Crime",
  components: {Button, LoadingSpinner, LockedFeature, WaitTimerOverlay},
  props: {
    id: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
      result: null
    }
  },
  computed: {
    ...mapState({
      crimes: state => state.game.crimes,
      user: state => state.user
    }),
    ...mapGetters({
      timeLeft: "user/timerTimeLeft"
    }),
    crime() {
      return this.crimes[this.id]
    },
    progress() {
      return this.user.stats[`crimes_progress_${this.id}`] || 0
    },
    timer() {
      return this.timeLeft(`crimes_timer_${this.id}`)
    },
    isUnlocked() {
      return this.user.stats.crimes_unlocked >= this.id
    },
    isNextCrime() {
      return this.crimes[this.user.stats.crimes_unlocked + 1].title === (this.crime.title) || false
    },
    unlockText() {
      let crime = this.crimes[(this.id - 1)]
      return `<span>This crime is unlocked when you have completed the first level of mastery for <strong>${crime.title}</strong></span>`
    }
  },
  methods: {
    doCrime() {
      if (this.loading || this.timer !== '' || !this.isUnlocked) return;

      this.loading = true;

      this.$http.post('crimes', {
        id: this.id
      }).then(res => {
        this.result = res.data.result;
        setTimeout(() => {
          this.result = false;
        }, 3500)
      })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }

}
</script>
