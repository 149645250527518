<template>
  <div class="col-12 col-md-6 col-lg-4">
    <div class="m-1 p-3 mb-3 border-gray">
      <div class="d-flex flex-row">
        <div
          :style="`width: 120px`"
          class="d-flex justify-content-center align-items-center">
          <img :src="image" />
        </div>
        <div class="flex-column flex-fill mx-3 d-flex">
          <span class="text-capitalize">
            {{ name }}
          <span class="float-end">Price: ${{ smuggle_price }}</span>

          </span>
          <span class="fw-bold">You hold {{ amount }}</span>
          <div>
            <FancyNumber @ev="(input) => amountToHandle = input" :prefix="''" class="my-3"/>
            <div class="d-flex flex-row justify-content-between">
              <Button @click="sell" :loading="loading">Sell</Button>
              <Button @click="purchase" :loading="loading">Purchase</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import FancyNumber from "@/components/FancyNumber.vue";
import {mapGetters} from "vuex";

export default {
  name: "SmuggledItem",
  components: {FancyNumber, Button},
  data() {
    return {
      loading: false,
      amountToHandle: ''
    }
  },
  props: {
    itemName: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      "inventory": "user/items",
      "city": "user/city",
      "stats": "user/stats",
    }),
    multiplier() {
      return this.stats['smuggle_multiplier'] || 0
    },
    smuggle_price() {
      let price = 0;
      let amount = this.amountToHandle.replaceAll(',', '') || 1;

      price = this.city.smuggling_prices[this.itemName] * amount;

      let discount = Math.floor(price / 100) * this.multiplier;

      price += discount;

      return new Intl.NumberFormat()
        .format(price)
    },
    item() {

      if (this.inventory.length === 0)
        return {};

      const item = Object.entries(this.inventory)
        .find(([, s]) => {
          return s.item.display_name === this.itemName;
        });

      if (!item)
        return;

      return item[1];
    },
    name() {
      return this.itemName
    },
    image() {
      return '/images/smuggling/' + this.itemName.replaceAll(' ', '_').toLowerCase() + '.png'
    },
    amount() {
      if (!this.item || this.item.length > 0)
        return 0;

      return new Intl.NumberFormat()
        .format(this.item.amount)
    }
  },
  methods: {
    purchase() {
      this.loading = true;
      const item = {}
      console.log(this.item);
      item[this.itemName] = this.amountToHandle

      this.$http.post('smuggle/purchase', item)
        .then()
        .finally(() => {
          this.loading = false;
        })
        .catch(() => {
        })
    },
    sell() {
      this.loading = true;
      const item = {}
      console.log(this.item);
      item[this.itemName] = this.amountToHandle

      this.$http.post('smuggle/sell', item)
        .then()
        .finally(() => {
          this.loading = false;
        })
        .catch(() => {
        })
    },
  }
}
</script>
