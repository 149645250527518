<template>
  <div class="w-100" id="detail-bar">
    <div class="d-flex justify-content-between w-100">
      <div class="d-flex justify-content-between w-100">
        <ul class="d-flex mb-0 flex-row list-unstyled">
          <li>
            <i class="flaticon-time icon-gradient me-2"/> {{ time }}
          </li>
          <li class="cursor-pointer" @click="goToAirport">
            <i class="flaticon-location icon-gradient me-2"/> {{ city.name }}, {{
              city.country.name
            }}
          </li>
          <li>
            <i class="flaticon-rainy-day icon-gradient me-2"/> {{ city.weather }}, {{
              city.temperature
            }}&#8451;
          </li>
        </ul>
        <ul class="list-unstyled d-flex flex-row mb-0 ms-auto">
          <li class="cursor-pointer" @click="goToBank">
            <VTooltip :delay="0">
              <i class="flaticon-money icon-gradient me-2"/>
              <CountUp :number="money || 0"/>
              <template #popper>
                Cash Banked: {{ cashInBank }}
              </template>
            </VTooltip>
          </li>
          <li>
            <VTooltip :delay="0">
              <i class="flaticon-bullets icon-gradient me-2"/>
              <CountUp :number="bullets || 0"/>

              <template #popper>
                Bullets
              </template>
            </VTooltip>
          </li>
          <NotificationIcon />
        </ul>
      </div>
    </div>
    <div class="gradient-bar"></div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import CountUp from "@/components/count-up";
import NotificationIcon from "@/components/notifications/NotificationIcon.vue";

export default {
  name: 'DetailBar',
  components: {NotificationIcon, CountUp},
  computed: {
    ...mapGetters({
      time: "game/time",
      city: "user/city",
      money: "user/moneyRaw",
      bullets: "user/bulletsRaw",
      cashInBank: "user/cashInBank"
    }),
  },
  methods: {
    goToAirport() {
      this.$router.push({
        name: 'airport'
      })
    },
    goToBank() {
      this.$router.push({
        name: 'bank'
      })
    }
  }
}
</script>
