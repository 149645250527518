<template>
  <div class="">
    <ul class="list-unstyled d-flex flex-row justify-content-center flex-wrap">
      <li v-for="(s, i) in smilies" :key="i">
        <img :src="'/images/smilies/' + s.image" :alt="s.code" @click="addBB(s.code)"/>
      </li>
    </ul>

    <textarea :name="name" :id="'text-' + name" ref="comment-box" cols="30" rows="10"
              class="form-control"
              v-model="comment"></textarea>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: 'reply'
    },
    value: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      smilies: [
        {
          code: ':shifty:',
          image: 'shifty_cb.gif'
        },
        {
          code: ':mad:',
          image: 'mad.gif'
        },
        {
          code: ':$',
          image: 'blush.gif',
        },
        {
          code: '8)',
          image: 'cool.gif',
        },
        {
          code: ':D',
          image: 'grin.gif',
        },
        {
          code: ':)',
          image: 'happy.gif',
        },
        {
          code: 'lol',
          image: 'laugh.gif',
        },
        {
          code: ':rolleyes:',
          image: 'rolleyes.gif',
        },
        {
          code: ':(',
          image: 'sad.gif',
        },
        {
          code: ':o',
          image: 'ooh.gif',
        },
        {
          code: ':|',
          image: 'shocked.gif',
        },
        {
          code: ':tdn:',
          image: 'tdn.gif',
        },
        {
          code: ':tup:',
          image: 'tup.gif',
        },
        {
          code: ':p',
          image: 'tongue.gif',
        },
        {
          code: ':S',
          image: 'confused.gif',
        },
        {
          code: ';)',
          image: 'wink.gif',
        },
        {
          code: '|-)',
          image: 'yawn.gif',
        },
        {
          code: ':angry:',
          image: 'angry.gif',
        }
      ]
    }
  },
  methods: {
    addBB(code) {

      const field = this.$refs['comment-box'];

      if (document.selection) {
        field.focus();
        let sel = document.selection.createRange();
        sel.text = code;
      }
      //MOZILLA and others
      else if (field.selectionStart || field.selectionStart == '0') {
        var startPos = field.selectionStart;
        var endPos = field.selectionEnd;
        field.value = field.value.substring(0, startPos)
          + code
          + field.value.substring(endPos, field.value.length);
        field.selectionStart = startPos + code.length;
        field.selectionEnd = startPos + code.length;
      } else {
        field.value += code;
      }

      field.focus();

      this.$emit('reply-update', field.value)
    }
  },
  computed: {
    comment: {
      set(v) {
        this.$emit('reply-update', v);
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
