<template>
  <div>
    <div class="page-header">
      <div class="text">
        <h2 class="mb-0 text-uppercase px-4 py-3">Airport</h2>
      </div>
    </div>
    <div class="container">
      <div class="row" v-if="!timerReady('airport')">
        <div class="col">
          <div class="card">
            <div class="card-body text-center">
              <time-until :time="$store.state.user.timers.airport" /> before you can fly again
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-sm-12 col-md-6" v-for="(city, index) in cities" :key="index">
          <div class="card mb-4">
            <div class="card-body">
              Fly to <span class="text-capitalize">{{ city.name }}</span> for just
              ${{ costToCity(city) }}
            </div>
            <div class="card-footer">
              <button @click.prevent="fly(city.id)"
                      class="btn btn-primary">Fly to {{ city.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import TimeUntil from "@/components/timeuntil";

export default {
  name: 'Airport',
  components: {TimeUntil},
  beforeMount() {
    const airports = this.$store.state.game.cities;

    if (airports.length === 0)
      this.$http.get('airport').then(res => {
        this.$store.state.game.cities = res.data.cities;
      })
  },
  data: function () {
    return {
      loading: false,
      timer: 0
    }
  },
  computed: {
    cities() {
      return this.$store.state.game.cities.filter(c => c.id !== this.$store.getters['user/city'].id);
    },
    ...mapGetters({
      timerReady: 'user/timerReady',
      rankMeta: "user/rankMeta"
    }),
  },
  methods: {
    fly(id) {
      this.loading = true;
      this.$http.post('airport', {id})
        .then(res => {
          // this.$store.state.game.cities = res.data.cities;
        })
        .catch(err => {
        })
        .then(() => {

          this.loading = false;
        })
    },

    getCurrentAirportName() {
      return this.$options.filters.location(this.$root.$data.user.stats.location) + ' Airport'
    },

    updateLocalTimer: function (seconds) {
      this.timer = seconds;
    },
    resetTimer() {
      window.axios.post('airport/reset')
    },
    cityName(city) {
      return city.key.replace('cost_to_fly_to_', '').replace('_', ' ');
    },
    costToCity(city) {

      return 0;
    }
  }
}
</script>
