<template>
  <div class="mt-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-8" id="feed-content" ref="feedContent">
          <div class="mb-4" v-for="item in feedItems" :key="item.id">
            <component
              v-if="getComponent(item)"
              :is="getComponent(item)"
              :item="item"/>
          </div>
          <LoadingSpinner :loading="feedData.loading" :inline="feedData.page !== 0"
                          v-if="feedData.loading"/>
        </div>
        <div class="col-12 col-md-4">
          <ul v-for="(update, index) in updates" :key="index">
            <li>{{ update }}</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FriendRequest from "@/components/feed/FriendRequest.vue";
import ForumPost from "@/components/feed/ForumPost.vue";
import ForumThread from "@/components/feed/ForumThread.vue";
import Card from "@/components/layout/Card.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import RankUp from "@/components/feed/RankUp.vue";

export default {
  name: "Feed",
  components: {LoadingSpinner, Card, ForumPost, FriendRequest},
  data() {
    return {
      feedData: {
        page: 0,
        pages: 10,
        loading: false,
        timer: null,
      },
      feedItems: [],
      updates: [
        {
          title: 'Update',
          content: ''
        }
      ],
    }
  },
  mounted() {
    this.getFeed();
    document.getElementById('layout-content').addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    document.getElementById('layout-content').removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getFeed() {
      if (!this.feedData.loading && this.feedData.page < this.feedData.pages) {
        this.feedData.loading = true;
        this.$http.get('/feed', {
          params: {
            page: this.feedData.page
          }
        })
          .then(res => {
            this.feedData.loading = false;
            if (this.feedData.page === 0) {
              this.feedData.pages = res.data.last_page;
            }
            this.feedData.page++;
            this.feedItems.push(...res.data.data)
          });
      }
    },
    getComponent(item) {
      switch (item.item_type) {
        case 'App\\Models\\Friendship':
          return FriendRequest
        case 'App\\Models\\ForumPost':
          return ForumPost
        case 'App\\Models\\ForumThread':
          return ForumThread
      }

      switch (item.type_misc?.type) {
        case 'RankUp':
          return RankUp;
      }

      return false;
    },
    handleScroll() {
      const element = this.$refs['feedContent'];
      if (Math.floor(element.getBoundingClientRect().bottom) <= window.innerHeight) {

        this.getFeed();
      }
    }

  }
}
</script>
