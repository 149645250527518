<template>
  <div>
    <div class="container" v-if="state === 'lobby'">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-4 offset-md-3">
          <Card>
            <template #header>
              <h5>Blackjack</h5>
            </template>
            <template #body>
              <div class="input-group mb-3">
                <FancyNumber :id="'savings-cash'" @ev="(input) => {bet = input}"
                             :value="bet"></FancyNumber>
                <input type="submit" name="" value="Bet" @click="startGame" class="btn">
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
    <div id="blackjack-table" v-else>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p>
              Player: {{ playerTotal }}
            </p>
            <img :src="getCard(card)" alt="" style="max-height: 150px;"
                 v-for="(card, index) in playerCards" :key="index">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>
              Casino: {{ casinoTotal }}
            </p>
            <img :src="getCard(card)" alt="" style="max-height: 150px;"
                 v-for="(card, index) in casinoCards" :key="index">
          </div>
        </div>
      </div>
    </div>
    <div v-if="playerCards.length > 0">
      <div>
        Outcome: {{ outcome }}
      </div>
      <div v-if="outcome === ''">
        <button @click="hit" class="btn">Hit</button>
        <button @click="stand" class="btn">Stand</button>
        <button @click="double" class="btn" v-if="playerTotal > 8 && playerTotal < 12">Double</button>
      </div>
    </div>
    <div v-if="outcome !== ''">
      <button class="btn" @click="playAgain">Play again</button>
    </div>
  </div>
</template>
<script>
import Card from "@/components/layout/Card.vue";
import FancyNumber from "@/components/FancyNumber.vue";

export default {
  name: "Blackjack",
  components: {FancyNumber, Card},
  data() {
    return {
      playerCards: [],
      casinoCards: [],
      bet: "$",
      loading: true,
      outcome: '',
      state: 'lobby'
    }
  },
  mounted() {
    this.getGame();
  },
  computed: {
    playerTotal() {
      return this.calculateHandTotal(this.playerCards)
    },
    casinoTotal() {
      return this.calculateHandTotal(this.casinoCards)
    },
  },
  methods: {
    calculateHandTotal(hand) {
      let total = 0;
      let numAces = 0;

      const cards = hand.map((card) => {
        let realCard = card.replace('S', '')
          .replace('D', '')
          .replace('H', '')
          .replace('C', '');

        if (realCard !== 'cover')
          return parseInt(realCard);
        else
          return 'cover'
      });

      // First, calculate the total value of non-Ace cards
      for (let i = 0; i < cards.length; i++) {
        if (cards[i] === 14) {
          numAces++;
        } else if (cards[i] > 9 && cards[i] < 14) {
          total += 10;
        } else if (cards[i] !== 'cover') {
          total += cards[i];
        }
      }

      // Calculate the total value with Aces
      let result = '';
      for (let i = 0; i < numAces; i++) {
        if (total + 11 <= 21 - numAces + 1) {
          total += 11;
          result = total.toString() + ' or ' + (total - 10).toString();
        } else {
          total += 1;
          result = total.toString();
        }
      }

      if (result === '')
        return total;


      return result;
    },
    getCard(card) {
      if (!card)
        card = 'cover';

      return "/images/cards/" + card + ".png"
    },
    startGame() {
      this.$http.post('casino/blackjack', {
        bet: this.bet
      })
        .then(res => {

          this.playerCards = res.data.player_cards.split('|')
          this.casinoCards = res.data.dealer_cards.split('|')
          this.outcome = res.data.outcome
          this.state = 'in-game'
        }).catch(() => {
          this.state = 'lobby'
      });
    },
    stand() {
      this.$http.post('casino/blackjack/stand')
        .then(res => {
          res.data.casino_cards.forEach((card, index) => {
            setTimeout(() => {
              if (index === 1)
                this.casinoCards.splice(0, 2)

              this.casinoCards.push(card);


              if (index === (res.data.casino_cards.length - 1))
                this.outcome = res.data.outcome;

            }, 500 * (index - 1))
          });
        });
    },
    hit() {
      this.$http.post('casino/blackjack/hit')
        .then(res => {

          this.playerCards.push(res.data.card)
          this.outcome = res.data.outcome
        });

    },
    double() {
      this.$http.post('casino/blackjack/double')
        .then(res => {

          this.playerCards.push(res.data.card)

          res.data.casino_cards.forEach((card, index) => {
            setTimeout(() => {
              if (index === 1)
                this.casinoCards.splice(0, 2)

              this.casinoCards.push(card);


              if (index === (res.data.casino_cards.length - 1))
                this.outcome = res.data.outcome;

            }, 500 * (index - 1))
          });
        });
    },
    getGame() {
      this.$http.get('casino/blackjack')
        .then(res => {
          this.playerCards = res.data.player_cards.split('|')
          this.casinoCards = res.data.dealer_cards.split('|')
          this.state = 'in-game'

        })
        .catch(() => {
        })
        .then(() => {
          this.loading = false;
        })
    },
    playAgain() {
      this.playerCards = [];
      this.casinoCards = [];
      this.outcome = '';

      this.startGame()
    }
  }
}
</script>
