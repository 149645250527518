<template>
  <div id="giphy-container" class="z-100">
    <div
      @keyup.enter="searchGifs()"
      v-if="searchBar"
      id="giphy-search-bar"
      class="input-group w-100 d-flex flex-row"
    >
      <input
        v-model="searchText"
        :placeholder="placeholder"
        class="form-control"
        ref="giphy-search-bar"
      />

      <div class="input-group-append">
        <input class="btn btn-primary" @click="searchGifs" type="submit" id="button-addon2"
               value="Search">
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100">
      <h1 class="text-muted" v-if="this.result.length === 0">Search for Gifs</h1>
      <div v-if="this.result && !this.loading">
        <div class="container" :style="direction">
          <img
            :key="image.index"
            v-for="image in this.result"
            :width="width"
            :src="image"
            :style="imgStyle"
            @click="onClickImage"
          />
        </div>
      </div>
      <LoadingSpinner :loading="loading"></LoadingSpinner>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {LoadingSpinner},
  props: {
    apiKey: {
      type: String,
    },
    width: {
      type: Number,
      default: 100,
    },
    searchBar: {
      type: Boolean,
      default: true,
    },
    imgStyle: {
      type: String,
      default: "padding-top: 10px;margin: 0 10px; cursor: pointer",
    },
    row: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Gif search...",
    },
    searchButtonText: {
      type: String,
      default: "Go!",
    },
    resultNumbers: {
      type: Number,
      default: 20,
    },
    clearSearchBar: {
      type: Boolean,
      default: true,
    },
    clearResultOnClick: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchText: "",
      result: [],
      direction: "",
      loading: false,
    };
  },
  mounted: function() {

    this.$refs['giphy-search-bar'].focus();
  },
  methods: {
    async searchGifs() {
      this.result = [];
      this.loading = true;
      await axios
        .get(
          "https://api.giphy.com/v1/gifs/search?api_key=" +
          "SZ6Mcg3umUmLYS7kjnwNtEO3cPUdYURb" +
          "&q=" +
          this.searchText
        )
        .then((response) => {
          this.searchText = "";
          let count = 0;
          for (let el of response.data.data) {
            this.result.push(el.images.fixed_width.url);
            if ((count += 1) >= this.resultNumbers) break;
          }

          this.loading = false;
        });
      if (this.clearSearchBar) this.searchText = "";
    },
    onClickImage(image) {
      this.$emit("clicked", image.srcElement.currentSrc);
      if (this.clearResultOnClick) this.result = [];
    },
  },
};
</script>

<style scoped>
#giphy-container {
  top: -280px;
  position: absolute;
  height: 280px;
  overflow: scroll;
  width: 100%;
  background:black;
}

#giphy-search-bar {
  position: sticky;
  top: 0;
}
</style>
