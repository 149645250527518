<template>
  <div>
    <div class="achievement d-flex flex-column"
         :class="[{'bg-success': isComplete},`bg-grade-${achievement.grade}`]">
      <div class="bg-gradient-black-transparent">
        <div class="d-flex flex-row align-items-center p-3 mx-3">
          <div class="">
            <h5 :class="`text-${achievement.grade}`">{{ achievement.grade }}</h5>
            <h4>{{ achievement.title }}</h4>
            <p>{{ achievement.msg }}</p>
          </div>

          <div class="ms-auto icon-container">
            <i :class="`flaticon-${achievement.icon} fs-0 icon-large icon-gradient d-block`"/>
          </div>
        </div>
        <div
          v-for="(amountNeeded, key, i) in achievement.completed" :key="i" class="text-center p-3 pt-0">
          {{ this.user.stats[key] > amountNeeded ? format(amountNeeded) : format(this.user.stats[key]) || 0 }}/{{ format(amountNeeded) }}
          <div class="progress w-100 bg-dark">
            <div class="progress-bar bg-success text-black"
                 :style="{width: getCurrentProgress(key, amountNeeded) + '%'}"
                 :key="i"
            >
              {{
                getCurrentProgress(key, amountNeeded) === 100 ? 'Completed' : getCurrentProgress(key, amountNeeded) + '%'
              }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'Achievement',
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      achievements: state => state.achievements.items,
      user: state => state.user,
    }),
    achievement() {
      return this.achievements.find(a => a.id === this.id)
    },
    isComplete() {
      let done = true;
      if (!this.achievement) return false;

      for (let key in this.achievement.completed) {
        let achievement = this.achievement.completed[key];

        let pro = this.getCurrentProgress(key, achievement);

        if (pro !== 100)
          done = false;
      }

      return done;
    },
  },
  methods: {

    getCurrentProgress(stat, needed) {
      let stats = this.user.stats,
        currentProgress = 0;

      if (stats.hasOwnProperty(stat))
        currentProgress = stats[stat];

      if (currentProgress > needed)
        return 100;

      return ((currentProgress / needed) * 100).toFixed();
    },
    format(num) {

      if(!num)
        return 0;

      return new Intl.NumberFormat()
        .format(num)
    }
  },
}
</script>
