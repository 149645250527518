import axios from "axios";
import {toast} from "vue3-toastify";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

http.interceptors.request.use(function (config) {
  if (localStorage.getItem('_token') !== null)
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('_token');
  config.headers.Character = localStorage.getItem('_character')

  // Do something before request is sent
  return config;
});

http.interceptors.response.use(function (res) {

  if (res.data.message) {
    toast(res.data.message.message, {
      type: res.data.message.options.type || 'error',
    })
  }
  return res;
}, function (error) {

  const res = error.response;


  if (res.status === 422) {

    if (res.data.message && !res.data.errors) {
      let data = res.data;

      toast(data.message.message, {
        type: data.message.options.type || 'error',
      })
      return Promise.reject(error);
    }

    if (res.data.errors) {
      let errors = Object.values(res.data.errors)

      toast(errors.join(', '), {
        type: 'error',
      })
      return Promise.reject(error);
    }

  } else if (res.status === 401) {
    if (localStorage.getItem('_token')) {
      localStorage.removeItem('_token');

      window.location.href = '/';

      return Promise.reject(error);
    }
    // } else if (res.status === 404) {
    //   toast(res.data.message, {
    //     type: 'error'
    //   });
    //
    //   return Promise.reject(error)
  } else if (res.status === 403) {
    toast('Unauthorized', {
      type: 'error'
    });

    return Promise.reject(error)
  } else if (res.status === 500) {
    toast('Something went wrong', {
      type: 'error'
    });

    return Promise.reject(error)
  } else {
    return Promise.resolve();
  }

  return Promise.reject(error);
})
export default http;
