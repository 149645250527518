<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col">
        <Card>
          <template #header><h4>Smuggling</h4></template>
          <template #body>
            <div class="d-flex w-100 align-items-center justify-content-between">
              <span>Your current multipler is {{ multiplier }}%</span>
              <form action="" @submit.prevent="negotiate">
                <CountdownButton text="Negotiate Prices" timer="smuggle_negotiate"
                                 :loading="loading"/>
              </form>
            </div>
            <p>
              Travel with as many goods as you can carry to make a tidy profit by selling to a
              dealer in another city. Accept the price on offer or negotiate the rate with the
              dealer, but be careful, you could make less!
            </p>
            <div class="container">
              <div class="row">
                <template v-for="(item, key) in items" :key="key">
                  <SmuggledItem :item-name="item"/>
                </template>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/layout/Card.vue";
import {mapGetters} from "vuex";
import SmuggledItem from "@/components/inventory/SmuggledItem.vue";
import Button from "@/components/Button.vue";
import TimeUntil from "@/components/timeuntil.vue";
import CountdownButton from "@/components/CountdownButton.vue";

export default {
  name: 'Smuggling',
  components: {CountdownButton, SmuggledItem, Card},
  data() {
    return {
      itemsToManage: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      "inventory": "user/items",
      "stats": "user/stats",
      "items": "game/smuggleItems",
      "timeTillReady": "user/getTimerAbsoluteTime"
    }),
    multiplier() {
      return this.stats['smuggle_multiplier'] || 0
    },
    timer() {
      return this.timeTillReady('smuggle_negotiate')
    }
  },
  methods: {
    negotiate() {
      this.loading = true;
      this.$http.post('smuggle/negotiate')
        .finally(() => {
          this.loading = false;
        }).catch(() => {
      })
    }
  }
}
</script>
