<template>
  <div class="h-100 d-flex flex-column justify-content-center align-items-center">
    <WaitTimerOverlay :timer="'chase'" header="rest" post-text="left" v-if="timer !== ''" />
    <div class="d-flex flex-row text-center">
      <div class="d-flex align-items-center">
        <button class="p-5 m-2" @click="move('left')">Left</button>
      </div>
      <div class="d-flex flex-column justify-content-between m-2">
        <button class="p-5 m-2" @click="move('up')">Up</button>
        <button class="p-5 m-2" @click="move('down')">Down</button>
      </div>
      <div class="d-flex align-items-center">
        <button class="p-5 m-2" @click="move('right')">Right</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import WaitTimerOverlay from "@/components/WaitTimerOverlay";

export default {
  name: "Chase",
  components: {WaitTimerOverlay},
  computed: {
    ...mapState({
      user: state => state.user
    }),
    ...mapGetters({
      timeLeft: "user/timerTimeLeft"
    }),
    timer() {
      return this.timeLeft('chase')
    }
  },
  methods: {
    move(direction) {
      if(!direction) return;

      this.$http.post('chase/move', {
        direction
      })
    }
  }
}
</script>
