<template>
  <div class="bg-dark">
    Edit profile

    <textarea name="quote" id="" cols="30" rows="10" class="form-control"
              v-model="profile.quote"></textarea>

    <button @click="updateProfile">Update profile</button>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'EditProfile',
  mounted() {
    this.profile.quote = this.user.quote_raw;
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  data() {
    return {
      profile: {
        quote: null,
      }
    }
  },
  methods: {
    updateProfile() {
      this.$http.post("user/profile", {
        quote: this.profile.quote
      })
        .then(res => {
          this.$store.state.user.quote = res.data.data.formatted;
          this.$store.state.user.quote_raw = res.data.data.raw;
        })
    }
  }
}
</script>
