<template>
  <div>
    choose character

    <div class="container">
      <div class="row">
        <div class="col-4" v-for="(character, index) in characters" :key="index">
<!--          <Avatar :user="character" />-->
          {{ character.username }} - {{ character.rank }}

          <Button @click="play(character.id)">Play</Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "@/components/user/Avatar.vue";
import Button from "@/components/Button.vue";

export default {
  name: "Character",
  components: {Button},
  data() {
    return {
      characters: []
    }
  },
  mounted() {
    this.$http.get('characters')
      .then(res => this.characters = res.data);
  },
  methods: {
    play(character) {
      localStorage.setItem('_character', character);
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>
