<template>
  <div class="col-12 col-md-6 col-lg-4">
    <div class="m-1 p-3 mb-3 border-gray d-flex flex-row">
      <div :style="`width: 120px;`" class="d-flex justify-content-center align-items-center">
        <img :src="image" @load="loadImage" @error="showDefaultImage" :class="{'opacity-100': imageLoaded, 'd-none': showDefault}" class="transition-slow opacity-0" />
        <i class="flaticon-help-desk opacity-50 fs-2 " v-if="showDefault" />
      </div>
      <div class="flex-column flex-fill mx-3 d-flex">
        <span class="text-capitalize">{{ name }}</span>
        <div class="d-flex align-items-center">
          <span class="fw-bold">x{{ item.amount }}</span>
          <VTooltip class="ms-auto">
            <span class="ms-auto me-3 d-flex"><i class="flaticon-strength me-1" /> {{ item.item.strength }}</span>
            <template #popper>
              Strength
            </template>
          </VTooltip>
          <VTooltip>
            <span class="d-flex"><i class="flaticon-defense me-1" /> {{ item.item.defense }}</span>
            <template #popper>
              Defense
            </template>
          </VTooltip>
        </div>
        <div>
        </div>

        <div>
<!--          <Button class="ms-auto" :loading="loading" @click="sell">Sell x5</Button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "Item",
  components: {},
  data() {
    return {
      loading: false,
      imageLoaded: null,
      showDefault: false,
    }
  },
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    name() {
      return this.item.item.display_name
    },
    image() {
      return '/images/inventory/' + this.name.replaceAll(' ', '_').toLowerCase() + '.png'
    }
  },
  methods: {
    sell() {
      this.loading = true;

      this.$http.post('/inventory/sell', {
        id: this.item.id
      }).catch(err => {})
        .finally(() => {
          this.loading = false;
        })
    },
    loadImage() {
      this.imageLoaded = true;
    },
    showDefaultImage() {
      this.showDefault = true;
    }
  }
}
</script>
