<template>
  <div id="message-container">
    <div class="d-flex align-items-center border-bottom border-dark" id="message-container-header">
      <div class="chat-preview-avatar flex-shrink-0 p-1">
        <!--<img :src="thread.preview.picture" width="50"/>-->
      </div>
      <div class="message-meta flex-grow-1 p-1">

        <div @click="goBack" class="d-md-none">
          Back
        </div>
        <h6 class="text-muted mb-0">Last message:
          <time-ago :icon="false" :inline="true"
                    :time="conversation.messages[conversation.messages.length - 1].updated_at"></time-ago>
        </h6>
        <!--<h5 v-if="thread.preview.last_seen === 'Online'" class="text-success">Online</h5>-->
        <!--<h5 v-else-if="thread.preview.last_seen === 'Away'" class="text-warning">Away</h5>-->
        <!--<h5 v-else>Away</h5>-->
      </div>
    </div>

    <div id="message-container-list" ref="mcl">
      <message-list :messages="conversation.messages"></message-list>
    </div>

    <div id="message-container-input" class="position-relative">
      <Giphy v-if="giphyOpen" @clicked="sendGif"/>
      <form @submit.prevent="reply">
        <div class="form-group mb-0">
          <div class="input-group">
            <input type="text" maxlength="255" id="reply" name="reply" class="form-control"
                   placeholder="Type your message here..."
                   autocomplete="off"
                   v-model="message">
            <div class="input-group-append">
              <input class="btn btn-primary" type="button" id="button-addon2"
                     value="Gif" @click="toggleGiphy">
            </div>
            <div class="input-group-append">
              <input class="btn btn-primary" type="submit" id="button-addon2"
                     value="Send">
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import MessageList from './MessageList'
import {mapGetters, mapState} from "vuex";
import TimeAgo from "@/components/timeago.vue";
import Giphy from "@/components/Giphy.vue";

export default {
  name: 'ViewMessage',
  components: {
    Giphy,
    TimeAgo,
    MessageList
  },
  data: function () {
    return {
      message: '',
      giphyOpen: false,
    }
  },

  watch: {
    '$route': function (newValue) {

    },
    conversationId() {
      if (this.$route.name === 'messaging.view')
        setTimeout(() => {
          this.$nextTick(this.scrollBottom(true))
          this.readMessages()
        }, 100)
    }
  },
  computed: {
    ...mapGetters({
      userid: "user/id"
    }),
    conversation() {
      return this.$store.getters["messaging/conversations"](
        this.conversationId
      );
    },
    conversationId() {
      return parseInt(this.$root.$route.params.conversation);
    }
  },
  beforeMount() {
    this.readMessages()
  },
  methods: {
    readMessages() {
      this.$http.post(`conversations/${this.$root.$route.params.conversation}/read`)
        .then(() => {
          this.$store.commit("messaging/READ_CONVERSATION", {
            conversationId: this.$root.$route.params.conversation,
            userId: this.userid
          });
        });
    },
    sendGif(gif) {

      this.$http.post(`/conversations/${this.$root.$route.params.conversation}`, {
        message: gif
      })
      this.toggleGiphy();
    },
    reply() {
      this.loading = true;
      this.$http.post(`/conversations/${this.$root.$route.params.conversation}`, {
        message: this.message
      })
        .finally(() => {
          this.message = '';
          this.loading = false;
        }).catch(() => {})
    },
    toggleGiphy() {
      this.giphyOpen = !this.giphyOpen;
    },
    scrollBottom(force = false) {
      const container = this.$refs['mcl'];

      if (!container) return;

      const {scrollHeight, scrollTop, clientHeight} = container;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 300 || force) {
        container.scrollTop = container.firstChild.lastElementChild.offsetTop;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  sockets: {
    'REPLY_TO_PRIVATE_MESSAGE'(data) {
      if (data.data.message.thread_id === this.conversationId) {
        setTimeout(() => {
          this.scrollBottom();
        }, 100)
      }
    },
  },
}
</script>
