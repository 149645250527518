export const Jobs = {
  namespaced: true,
  state: () => ({
    jobs: [],
    justFinishedJob: false,
  }),
  mutations: {
    SOCKET_REFRESH_JOBS(state, data) {
      state.jobs = data.data;
    },
    SOCKET_YOUR_JOB_UPDATE(state, data) {
      let job = state.jobs.find(j => j.id === data.data.job_id)

      job.user_id = data.data.user_id;
    },
    SOCKET_JOB_UPDATE(state, data) {
      let job = state.jobs.find(j => j.id === data.data.job_id)
      job.progress = data.data.progress;

    },
    SOCKET_JOB_COMPLETE(state, data) {
      let job = state.jobs.find(j => j.id === data.data.job_id)
      job.completed_at = data.data.completed_at;


      state.justFinishedJob = true;

      setInterval(() => {
        state.justFinishedJob = false;
      }, 2500)

    },
    SOCKET_JOB_TAKEN(state, data) {
      let job = state.jobs.find(j => j.id === data.data.job_id);
      job.user_id = data.user_id
    }
  },
  getters: {}
}
