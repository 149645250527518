<template>
  <div>
    <div class="slot-machine m-auto mt-5">
      <img src="/images/slot_machine.png"/>
      <div class="current-jackpot d-flex align-items-center">
        <i class="flaticon-money me-2"/> <CountUp :number="meta[0]?.jackpot || 0" />
      </div>
      <div class="last-winner-avatar d-flex align-items-center justify-content-center" v-if="meta[1]?.user?.avatar">
        <img :src="meta[1]?.user.avatar.head_url"/>
      </div>
      <div class="last-winner d-flex flex-column">
        <span class="d-flex align-items-center mb-1"><Username
          v-if="meta[1]?.user" :username="meta[1]?.user.username"/></span>
        <span class="d-flex align-items-center"><i
          class="flaticon-money me-2"/> <CountUp :number="meta[1]?.jackpot||0" /></span>
      </div>
      <div style="height: 80px;bottom:25px;z-index:5"
           class="position-absolute d-flex flex-row align-items-center">
        <div class=" me-5">
          <FancyNumber :id="'bet'" @ev="(input) => {bet = input}"
                       :value="bet"></FancyNumber>
        </div>

        <img src="/images/slot_machine_spin.png" @click="spin"/>

      </div>
      <div class="reels">
        <Reel v-for="(reel, index) in reels" :reel="reel" :key="index" :index="index"/>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.slot-machine {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  //max-height: calc(100vh - 148px);
  width: 800px;

  img {
    max-height: 100%;
  }

  .current-jackpot,
  .last-winner,
  .last-winner-avatar {
    position: absolute;
  }

  .last-winner-avatar {
    height: 50px;
    width: 50px;
    left: 500px;
    top: 40px;
  }

  .current-jackpot {
    left: 100px;
    top: 58px;
    font-size: 26px;
    width: 220px;
    text-align: center;
    justify-content: center;
    font-weight: bold;
  }

  .last-winner {
    left: 570px;
    top: 65px;
  }

  > img {

  }

  .reels {
    position: absolute;
    left: auto;
    right: auto;
    top: 180px;
    display: flex;
    flex-direction: row;
    width: 570px;
    height: 280px;
    overflow: hidden;

    .reel {
      width: 20%;
      color: black;
      height: 280px;

      &:nth-of-type(1) {
        width: 104px;
      }

      &:nth-of-type(2) {
        width: 117px;
      }

      &:nth-of-type(3) {
        width: 123px;
      }

      &:nth-of-type(4) {
        width: 120px;
      }

      &:nth-of-type(5) {
        width: 106px;
      }

      &.end {
        //transform: initial;
        //transition: none;
      }

      > div {
        text-align: center;
        font-size: 2rem;
        width: 100%;
        height: calc(280px / 3);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  > .reel {
  }
}
</style>
<script>
import Reel from "@/views/Casino/Slots/Reel";
import Username from "@/components/Username";
import CountUp from "@/components/count-up";
import FancyNumber from "@/components/FancyNumber.vue";

export default {
  name: "Slots",
  components: {FancyNumber, CountUp, Username, Reel},
  data() {
    return {
      meta: {},
      disabled: false,
      bet: "$",
      win: {
        winningNumbersPosition: null,
      },
      reels: [
        {
          reel: [],
          winPos: null,
          running: false,
          state: 'start'
        },
        {
          reel: [],
          winPos: null,
          running: false,
          state: 'start'
        },
        {
          reel: [],
          winPos: null,
          running: false,
          state: 'start'
        },
        {
          reel: [],
          winPos: null,
          running: false,
          state: 'start'
        },
        {
          reel: [],
          winPos: null,
          running: false,
          state: 'start'
        },
      ],
      spinning: false,
    }
  },
  mounted() {

    this.$http.get('/casino/slots/meta')
      .then(res => {
        this.meta = res.data;
      })


    for (const key of Object.keys(this.reels)) {
      let ree = this.reels[key];

      let reel = [];

      for (let ii = 0; ii < 300; ii++) {
        reel.push(this.random())
      }

      ree.reel = [
        ...ree.reel,
        ...reel
      ]
    }
  },
  sockets: {
    CASINO_SLOTS_META_UPDATE(data) {

      this.meta[0].jackpot = data.data.jackpot;
    },
    RESET_JACKPOT() {
      this.$http.get('/casino/slots/meta')
        .then(res => {
          this.meta = res.data;
        })
    }
  },
  methods: {
    spin() {

      if (this.disabled) return;

      this.disabled = true;

      this.$http.post('/casino/slots', {
        bet: this.bet
      })
        .then(res => {

          this.addAnotherPull();

          this.win.winningNumbersPosition = this.random(200, 300);

          for (let i = 0; i < this.reels.length; i++) {
            this.reels[i].state = 'spin';
            this.reels[i].running = true;
          }

          const startNumber = this.win.winningNumbersPosition - 1;
          const endNumber = this.win.winningNumbersPosition + 2;

          for (let i = 0; i < this.reels.length; i++) {
            let ree = this.reels[i];

            ree.winPos = this.win.winningNumbersPosition;

            // Add in the rolled number
            ree.reel.splice(this.win.winningNumbersPosition, 0, res.data.prize[i])

            setTimeout(() => {
              ree.state = 'end';
              ree.reel = ree.reel.slice(startNumber, endNumber);
              ree.running = false;

              // Only show toast when slots is finished
              if (i === (this.reels.length - 1)) {

                this.$http.get('/casino/slots/claim')
                  .then(res => {

                    for (let message in res.data.messages) {
                      res.data.messages.map(m => {

                        m.type = m.options.type

                        // @todo fix toast
                        // toast(m)

                        return m;
                      })
                    }
                  }).finally(() => {

                  this.disabled = false;
                })
              }

            }, (i + 1) * 1000)

          }
        })
        .catch(() => {
          this.disabled = false;
        }).finally(() => {
      })


    },
    random(min = 0, max = 10) {
      return Math.floor(Math.random() * (max - min)) + min + 1;
    },
    addAnotherPull() {
      for (let i = 0; i < this.reels.length; i++) {
        let ree = this.reels[i];

        if (ree.reel.length > 10) return;

        let reel = [];

        for (let ii = 0; ii < 300; ii++) {
          reel.push(this.random())
        }

        ree.reel = [
          ...ree.reel,
          ...reel
        ]
      }
    }
  }
}
</script>
