export const MessagingStore = {
  namespaced: true,
  state: () => ({
    conversations: []
  }),
  mutations: {
    SOCKET_REPLY_TO_PRIVATE_MESSAGE(state, data) {
      const convo = state.conversations.find(c => c.id === data.data.message.thread_id);
      convo.messages.push(data.data.message)
    },
    SOCKET_ADD_CONVERSATION(state, data) {
      state.conversations.unshift(data.data);
    },
    ADD_CONVERSATION(state, data) {
      state.conversations.unshift(data)
    },
    READ_CONVERSATION(state, data) {
      const conversationId = Number(data.conversationId)
      const userId = Number(data.userId);
      const convo = state.conversations.find(c => c.id === conversationId);
      const user = convo.participants.find(c => c.user_id === userId);

      user.last_read = new Date();
    }

  },
  getters: {
    conversations: (state) => (id) => {
      return state.conversations.find(c => c.id === id)
    },
    getAllConversationsByLatest: (state) => {
      return state.conversations.sort((a, b) => {
        return new Date(b.messages[b.messages.length - 1].updated_at) - new Date(a.messages[a.messages.length - 1].updated_at)
      });
    },
    unreadCount: (state) => (userid) => {
      let count = 0;

      state.conversations.forEach(c => {
        if(!c.participants) return;

        const u = c.participants.find(p => p.user_id === userid)
        if(new Date(u.last_read) < new Date(u.updated_at))
          count++;

        count += c.messages.filter(m => new Date(m.created_at) > new Date(u.last_read) && m.user_id !== userid).length
      });

      return count;
      // return state.conversations.filter(t => t.user_id === this.userid && new Date(t.last_read) < new Date(t.updated_at))
    }
  }
}
