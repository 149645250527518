export const GarageStore = {
  namespaced: true,
  state: () => ({
    cars: null,
    selectedCars: [],
    totalSellAmount: 0,
    totalSmeltAmount: 0,
    totalCarShippingAmount: 0,
  }),
  actions: {
    SOCKET_ADD_CAR(ctx, data) {

      ctx.state.cars.push(data.data)
    },
    SOCKET_UPDATE_CAR(ctx, data) {
      for (let car in ctx.state.cars) {
        if (ctx.state.cars[car].id === data.data.id) {
          ctx.state.cars[car] = data.data
        }
      }
    },
    SOCKET_REMOVE_CAR(ctx, data) {

      let carId = data.data;



      const carSelect = ctx.state.selectedCars.findIndex(c => c === carId);

      if (carSelect >= 0)
        ctx.state.selectedCars.splice(carSelect, 1)

      const car = ctx.state.cars.findIndex(c => c.id === carId)

      if (car >= 0)
        ctx.state.cars.splice(car, 1)

    }
  },
  getters: {
    totalRepairAmount(state) {
      let cost = 0;

      state.selectedCars.map(c => {
        let car = state.cars.find(g => g.id === c);
        cost += car.car.cost - ((parseInt(car.car.cost) / 100) * (100 - parseInt(car.damage)))
      })

      return cost;
    },
    totalSellAmount(state) {
      let cost = 0;

      state.selectedCars.map(c => {
        let car = state.cars.find(g => g.id === c);
        cost += Math.floor(((car.car.cost / 100) * (100 - car.damage)))
      });

      return cost;
    },
    totalSmeltAmount(state) {
      let cost = 0;

      state.selectedCars.map(c => {
        let car = state.cars.find(g => g.id === c);
        cost += Math.floor(((parseInt(car.car.smelt) / 100) * (100 - parseInt(car.damage))));
      });

      return cost;
    },
    totalCarShippingAmount(state) {
      let cost = 0;

      state.selectedCars.map(c => {
        let car = state.cars.find(g => g.id === c)
        cost += car.car.cost / 100;
      });

      return cost;
    },
  }
}
