import http from '../../helpers/http'

export const UserCollectionStore = {
  namespaced: true,
  state: () => ({
    users: [],
    usersLoading: [],
  }),
  actions: {
    SET_USER(ctx, user) {
      console.error('FUCK', ctx, user);
      ctx.commit('SET_USER', user)
    },
    SOCKET_JAIL_INIT(ctx, data) {
      data.map(inmate => {
        ctx.commit('SET_USER_BY_ID', inmate.user_id)
      })
    }
  },
  mutations: {
    SOCKET_UPDATE_USER_GLOBAL(state, data) {
      const user = state.users.findIndex(u => u.username === data.data.username);


      state.users[user] = {
        ...state.users[user],
        ...data.data
      };
    },
    SET_USER_BY_USERNAME: function (state, username) {
      if(state.users.findIndex(u => u.username === username) >= 0)
        return;

      state.usersLoading.push(username);

      http.get(`/profile/username/${username}/overview`)
        .then(res => {
          state.users.push(res.data);
          state.usersLoading = state.usersLoading.filter(u => u.username !== username)
        });
    },
    SET_USER_BY_ID: function (state, user_id) {
      if(state.users.findIndex(u => u.id === user_id) >= 0)
        return;

      state.usersLoading.push(user_id);

      http.get(`/profile/id/${user_id}/overview`)
        .then(res => {
          state.users.push(res.data);
          state.usersLoading = state.usersLoading.filter(u => u.user_id !== user_id)
        });
    },
  },
  getters: {
    getUserById: (state) => function (id) {
      const find = state.users.find(u => u.id === id);

      if (!find && !state.usersLoading.includes(id)) {
        this.$store.commit("userCollection/SET_USER_BY_ID", id)
      }

      return state.users.find(u => u.id === id);
    },
    getUserByUsername: (state) => function (username, force = false) {
      const find = state.users.find(u => u.username === username);

      if ((!find && !state.usersLoading.includes(username)) || force) {
        this.$store.commit("userCollection/SET_USER_BY_USERNAME", username)
      }

      return state.users.find(u => u.username === username);
    },
  }
}
