<template>
  <div class="position-relative h-100">
    <div class="container-fluid g-0">
      <BankBar/>
      <div class="row g-0">
        <div class="col-12 bg-gradient-black-transparent">
          <div class="p-3">
            <h3>Send Money</h3>

            <div class="form-group mb-4">
              <label for="deposit-user">Who to send money to</label>
              <MultiUserAutoComplete @usersChanged="updateRecipients"></MultiUserAutoComplete>
            </div>

            <div class="form-group mb-4">
              <label for="deposit-money">Amount to send</label>
              <FancyNumber :id="'savings-cash'" @ev="(input) => {sendMoneyForm.amount = input}"
                           :value="sendMoneyForm.amount"></FancyNumber>
            </div>

            <Button @click="sendMoney">Send Money</Button>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-12 col-lg-6 p-4">
          <div class="card">
            <div class="card-header">
              <h4>Incoming Transactions</h4>
            </div>
            <div class="card-body">
              <table class="table table-dark">
                <thead>
                <tr>
                  <th>From</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="transaction in transactions.incoming" :key="transaction.id">
                  <td>
                    <Username :username="transaction.sender.username"/>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <i class="flaticon-money me-2" /> {{ money_format(transaction.amount) }}
                    </div>
                  </td>
                  <td>
                    <TimeAgo :time="transaction.created_at" />
                  </td>
                </tr>
                <tr v-if="transactions.incoming.length === 0">
                  <td colspan="3" class="text-center p-5">
                    Your incoming transactions will appear here
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 p-4">
          <div class="card">
            <div class="card-header">
              <h4>Outgoing Transactions</h4>
            </div>
            <div class="card-body">
              <table class="table table-dark table-hover">
                <thead>
                <tr>
                  <th>To</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="transaction in transactions.outgoing" :key="transaction.id">
                  <td>
                    <Username :username="transaction.receiver.username"/>
                  </td>
                  <td>{{ money_format(transaction.amount) }}</td>
                  <td><TimeAgo :time="transaction.created_at" /></td>
                </tr>
                <tr v-if="transactions.outgoing.length === 0">
                  <td colspan="3" class="text-center p-5">
                    Your outgoing transactions will appear here
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import formats from '@/helpers/formats';
import Username from "@/components/Username";
import BankBar from "@/views/Money/Bank/components/BankBar";
import FancyNumber from "@/components/FancyNumber";
import MultiUserAutoComplete from "@/components/MultiUserAutoComplete";
import TimeAgo from "@/components/timeago";
import Button from "@/components/Button.vue";

export default {
  name: "Bank",
  components: {Button, TimeAgo, MultiUserAutoComplete, FancyNumber, BankBar, Username},
  data() {
    return {
      transactions: {
        incoming: [],
        outgoing: [],
      },
      sendMoneyForm: {
        receiver_ids: [],
        amount: "$",
      }
    }
  },
  mounted() {
    this.getTransactions()
  },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  methods: {
    getTransactions() {
      this.$http.get('bank/transactions')
        .then(res => {
          this.transactions = res.data;
        })
    },
    updateRecipients(recipients) {
      this.sendMoneyForm.receiver_ids = recipients.map(el => {
        return el.id
      });
    },
    sendMoney: function () {
      this.loadingSendMoneyForm = true;
      this.$http.post('/bank/transfer', {
        receiver_ids: this.sendMoneyForm.receiver_ids,
        amount: this.sendMoneyForm.amount
      }).finally(() => {
        this.getTransactions()
      })
    },
    money_format(money) {
      return formats.money(money)
    },
  }
}
</script>
