<template>
  <div class="position-relative h-100">
    <div class="container-fluid g-0">
      <BankBar/>
      <div class="row g-0">
        <div class="col-12 col-lg-6">
          <Card>
            <template #header>
              <h4>Deposit</h4>
            </template>
            <template #body>

              <div class="form-group mb-4">
                <FancyNumber :id="'savings-cash'" @ev="(input) => {form.depositAmount = input}"
                             :value="form.depositAmount"></FancyNumber>
              </div>

              <Button @click="depositMoney" :disabled="isDepositing">Deposit</Button>
            </template>
          </Card>
        </div>
        <div class="col-12 col-lg-6">
          <Card>
            <template #header>
              <h4>Withdraw</h4>
            </template>
            <template #body>


              <div class="form-group mb-4">
                <FancyNumber :id="'withdraw-cash'" @ev="(input) => {form.withdrawAmount = input}"
                             :value="form.withdrawAmount"></FancyNumber>
              </div>

              <Button @click="withdrawMoney" :disabled="isWithdrawing">Withdraw</Button>
            </template>
          </Card>
        </div>
      </div>
      <div class="row g-0">
        <div class="col-12">
          <Card>
            <template #header>
              <h4>Your transactions</h4>
            </template>
            <template #body>
              <table class="table border-dark table-striped align-middle">
                <thead class="">
                <tr>
                  <th>Amount</th>
                  <th>Balance</th>
                  <th>Time Deposited</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="deposits.length === 0">
                  <td colspan="9">You haven't made any transactions yet</td>
                </tr>
                <tr v-for="deposit in deposits" :key="deposit.id">
                  <td :class="[(deposit.amount > 0 ? 'text-success' : 'text-danger')]">
                    <div class="d-flex align-items-center">
                      <i class="flaticon-money fs-0 me-2"/> {{ deposit.amount }}
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center"><i class="flaticon-money fs-0 me-2"/>
                      {{ deposit.balance }}
                    </div>
                  </td>
                  <td>
                    <TimeAgo :time="deposit.created_at"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FancyNumber from "@/components/FancyNumber";
import BankBar from "@/views/Money/Bank/components/BankBar";
import TimeAgo from "@/components/timeago";
import Button from "@/components/Button.vue";
import Card from "@/components/layout/Card.vue";

export default {
  name: 'BankDeposit',
  components: {Card, Button, TimeAgo, BankBar, FancyNumber},
  data() {
    return {
      form: {
        depositAmount: "$",
        withdrawAmount: "$",
      },
      deposits: [],
      isWithdrawing: false,
      isDepositing: false,
    }
  },
  mounted() {
    this.getDeposits();
  },
  methods: {
    depositMoney() {
      this.isDepositing = true;
      this.$http.post('/bank/deposit', {
        amount: this.form.depositAmount
      })
        .then(res => {
          this.getDeposits();
          this.depositCash = "$"
          this.isDepositing = false
        });
    },
    withdrawMoney() {
      this.isWithdrawing = true;
      this.$http.post('/bank/withdraw', {
        amount: this.form.withdrawAmount
      })
        .then(res => {
          this.getDeposits();
          this.depositCash = "$"
          this.isWithdrawing = false;
        });
    },
    getDeposits() {
      this.$http.get('bank/deposits')
        .then(res => {
          this.deposits = res.data;
        })
    }
  }
}
</script>
