<template>
  <div class="position-relative h-100">
    <div class="container-fluid g-0">
      <div class="row g-0">
        <div class="col bg-dark">
          <div class="d-flex flex-column flex-md-row py-4 px-4 overflow-x-auto">
            <div class="filter d-flex align-items-center">
              <i class="flaticon-filter me-3" />
              <span v-for="(grade, index) in grades" :key="index" class="badge rounded-pill bg-primary fs-7 text-uppercase cursor-pointer px-3 py-2 me-2"
                            :class="[{'bg-success': this.gradeSelected === grade, 'text-black': this.gradeSelected === grade}]"
                            @click="toggleGrade(grade)">{{
                grade
              }}</span>
            </div>
            <div class="ms-md-auto d-flex align-items-center">
              <h3 class="mb-0">Completed {{ completed }} / {{ notCompleted + completed }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0 fade-single-container">
          <Achievement class="col-12 col-md-6 col-lg-4"
                       v-for="key in filteredAchievements"
                       :key="key.id"
                       :id="key.id"/>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import Achievement from '@/components/achievements/Achievement'

export default {
  name: "Achievements",
  components: {Achievement},
  mounted() {
    const achievements = this.$http.get('achievements')
      .then(res => {

        this.$store.state.achievements.items = res.data;
        this.calculateAchievementStats();
      })
  },
  data() {
    return {
      completed: 0,
      notCompleted: 0,
      grades: ['common', 'uncommon', 'rare', 'epic'],
      gradeSelected: null,
    }
  },
  computed: {
    ...mapState({
      achievements: state => state.achievements.items,
      user: state => state.user,
    }),
    filteredAchievements() {
      if (!this.gradeSelected)
        return this.achievements;

      return this.achievements.filter(a => a.grade === this.gradeSelected)
    }
  },
  watch: {
    'gradeSelected'() {
      this.calculateAchievementStats();
    }
  },
  methods: {
    calculateAchievementStats() {
      this.completed = 0;
      this.notCompleted = 0;

      for (let i = 0; i < this.filteredAchievements.length; i++) {
        let achievement = this.filteredAchievements[i];
        if (!achievement) return false;

        for (let key in achievement.completed) {
          let ach = achievement.completed[key];

          let pro = this.getCurrentProgress(key, ach);

          if (pro === 100)
            this.completed++
          else
            this.notCompleted++;
        }
      }

    },
    toggleGrade(grade) {
      if (this.gradeSelected === grade)
        this.gradeSelected = null;
      else
        this.gradeSelected = grade;
    },
    getCurrentProgress(stat, needed) {
      let stats = this.user.stats,
        currentProgress = 0;

      if (stats.hasOwnProperty(stat))
        currentProgress = stats[stat];

      if (currentProgress > needed)
        return 100;

      return ((currentProgress / needed) * 100).toFixed();
    },
  }
}
</script>
