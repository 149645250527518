<template>
  <div id="chat-page-container">
    <div class="contacts">
      <router-link :to="{name: 'messaging.send'}" class="btn btn-primary mb-3 w-100">Start a new
        conversation
      </router-link>
      <div v-for="(thread, index) in conversations"
           :key="index">
        <router-link :to="{
          name: 'messaging.view',
          params: {
            conversation: thread.id
          },
        }" class="message-sidebar-details">
          <div class="message-preview d-flex">
            <div class="chat-preview-avatar flex-shrink-0 p-1">
              <!--<img :src="thread.preview.picture" width="50"/>-->
            </div>
            <div class="message-meta flex-grow-1 p-1">
              <div class="d-flex flex-row">
                <div>{{ getTitle(thread.users) }}</div>
                <div class="ms-auto" v-if="isUnread(thread)">!</div>
              </div>
              <span><time-ago
                :time="thread.messages[thread.messages.length - 1].created_at"></time-ago></span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="messages-container" :class="{'active': isActive}">
      <router-view></router-view>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>

import {mapGetters, mapState} from "vuex";
import TimeAgo from "@/components/timeago.vue";

export default {
  name: 'Messaging',
  components: {TimeAgo},
  methods: {
    goToConversation(thread) {
      this.$root.$router.push({
        name: 'messaging.view',
        params: {
          conversation: thread
        }
      })
    },
    getTitle(users) {
      let filter = users.filter(item => {
        //
        return item.id !== this.userid;
      });

      if (filter.length > 0) {
        return filter[0].username;
      } else {
        //
      }

      return 'No one';
    },
    isUnread(thread) {
      if (!thread.participants) return false;

      return thread.participants.find(t => t.user_id === this.userid && new Date(t.last_read) < new Date(t.updated_at));
    }
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      userid: "user/id",
      conversations: "messaging/getAllConversationsByLatest"
    }),
    currentThread() {
      return parseInt(this.$route.params.conversation);
    },
    isActive() {
      return this.$route.name === 'messaging.view'
    },
  },
}
</script>
