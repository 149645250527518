export const AchievementsStore = {
  namespaced: true,
  state: () => ({
    items: []
  }),
  mutations: {
    SOCKET_WEATHER_CHANGE(state, data) {
      // data = data.data
      // const cities = state.cities;
      // const city = cities.find(c => c.id === data.id);
      // city.weather = data.weather;
      // city.temperature = data.temperature;
    },
  },
  getters: {
  }
}
