<template>
  <div class="d-flex flex-row">
    <template v-if="!hasBlockedUser || (hasBlockedUser && show)">
      <div class="col-4 col-md-2 d-flex flex-column">
        <div class="position-sticky top-0 d-flex flex-fill">
          <PostBit :user="comment.author"/>
        </div>
      </div>
      <div class="col-8 col-md-10 d-flex flex-column p-3">
        <div class="d-flex flex-row flex-wrap justify-content-start">
          <div>
            <span><TimeAgo :time="comment.created_at"/></span>
          </div>

          <ul class="list-unstyled ms-auto d-flex flex-row ml-auto">
            <li class="me-2">
              <button class="btn" @click="quote(comment.post, comment.author.username)">Quote
              </button>
            </li>
            <li>
              <button class="btn">Report</button>
            </li>
          </ul>
        </div>
        <div class="mb-3" v-html="comment.formatted_post"></div>
      </div>
    </template>
    <template v-else>
      <div class="m-4 d-flex flex-row align-items-center w-100">
        <p class="mb-0">
          This reply has been hidden because you have blocked this user
        </p>

        <button @click="show = true" class="btn ms-auto">Show Reply</button>
      </div>
    </template>
  </div>
</template>
<script>
import Username from "@/components/Username.vue";
import Card from "@/components/layout/Card.vue";
import Avatar from "@/components/user/Avatar.vue";
import TimeAgo from "@/components/timeago.vue";
import {mapGetters} from "vuex";
import PostBit from "@/components/forums/PostBit.vue";

export default {
  name: "ForumPost",
  components: {PostBit, TimeAgo},
  data() {
    return {
      show: false
    }
  },
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isBlocked: "user/isBlocked"
    }),
    hasBlockedUser() {
      return this.isBlocked(this.comment.author.username)
    }
  },
  methods: {
    quote(post, username) {
      this.$emit("quote", {post, username})
    }
  }
}
</script>
