<template>
  <div class="city-grid">
    <div class="position-relative" id="city">
      <Grid v-if="buildingsLoaded" :active-building="selectedBuilding" :buildings="buildings"></Grid>
    </div>

    <div id="city-options" v-if="buildingsLoaded">
      <div v-for="building in buildings" :key="building.id"
           @click="selectBuilding(building)"
           class="building"
           :class="{ 'selected': building.id === selectedBuilding?.id }"
           :style="{ 'background-image': `url(${building.image.src})` }"></div>
    </div>
  </div>
</template>
<style lang="scss">

.city-grid {
  width: 100%;
  height: calc(100vh - 149px);
  display: grid;
  grid: "city" auto
        "buildings" auto;
  grid-template-rows: 4fr 1fr;

  #city {
    grid-area: city
  }

  #city-options {
    grid-area: buildings;

    .building {
      display: inline-block;
      border: 1px solid green;
      height: 100px;
      width: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;

      &:hover {
        background-color: green;
      }

      &.selected {
        background-color: blue;
      }
    }
  }
}

</style>
<script>
import {mapGetters, mapState} from "vuex";
import Grid from "@/views/Crews/Cities/components/Grid"
import buildings from "./buildings.json"

export default {
  name: "CrewCity",
  components: {Grid},

  data() {
    return {
      buildingsLoaded: false,
      selectedBuilding: null,
      buildings
    }
  },

  mounted() {
    /*
    this.$http.get('crews/cities/available-buildings')
      .then(res => {
        this.$store.state.crewCities.buildings = res.data
      });

    this.$http.get('crews/cities')
      .then(res => {
        this.$store.state.crewCities.city = res.data
      });
     */

    this.loadBuildings();
  },
  computed: {
    /*
    ...mapState({
      buildings: state => state.crewCities.city?.buildings,
      availableBuildings: state => state.crewCities.buildings,
      selectedBuilding: state => state.crewCities.selectedBuilding,
    }),
     */
    ...mapGetters({
      usedTiles: "crewCities/tilesUsed"
    }),
  },
  methods: {
    selectBuilding(building) {
      // Unselect if already selected
      if(this.selectedBuilding?.id === building.id) {
        this.selectedBuilding = null;
      } else {
        this.selectedBuilding = building;
      }
    },

    loadBuildings() {
      // Load all buildings in a promise
      let promises = [];
      for(let building of buildings) {
        promises.push(new Promise((resolve) => {
          building.image = new Image();
          building.image.src = `/images/cities/buildings/out/${building.filename}`;
          building.image.onload = () => {
            resolve();
          };
        }));

        Promise.all(promises).then(() =>
        {
          this.buildingsLoaded = true;
        });
      }
    }
  }
}
</script>
