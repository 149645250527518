<template>
  <div id="viewport" ref="viewport">
    <canvas ref="canvas"
            :class="{dragging}"
            v-on:mousedown="onMouseDown"
            v-on:mousemove="onMouseMove"
            v-on:mouseup="onMouseUp"
            v-on:wheel="onWheel"
            :width="canvasWidth"
            :height="canvasHeight">
    </canvas>
  </div>
</template>

<style lang="scss">
#viewport {
  position: relative;
  height: 100%;

  canvas {
    position: absolute;

    &.dragging {
      cursor: grabbing;
    }
  }
}
</style>

<script>

const SQ3 = Math.sqrt(3);
const SQ3D2 = SQ3 / 2;
const TILESIZE = 32;
const ZOOMFACTOR = 0.1;

export default {
  name: "Grid",

  props: ["activeBuilding", "buildings"],

  data() {
    return {
      gridRows: 50,
      gridCols: 50,

      context: null,
      activeCell: null,

      cells: null,
      placedBuildings: [],
      canPlace: false,

      zoomLevel: 1,

      viewportRect: null,

      mousedown: false,
      dragging: false,
      dragX: 0,
      dragY: 0,
      offsetX: 0,
      offsetY: 0
    }
  },

  mounted() {
    this.initCells();
    this.initCanvas();
  },

  unmounted() {
    // Remove resize listener
    window.removeEventListener("resize", this.resize);
  },

  computed: {
    gridWidth() {
      return this.gridCols * TILESIZE;
    },

    gridHeight() {
      return this.gridRows * TILESIZE;
    },

    canvasWidth() {
      return this.viewportRect?.width;
    },

    canvasHeight() {
      return this.viewportRect?.height;
    },

    canvasOffset() {
      return TILESIZE * this.gridRows * SQ3D2;
    },

    zoomOffsetX() {
      // This is the offset to adjust the x offset by when zooming
      // It uses the total grid width divided by 2 to keep it centered
      return (TILESIZE * (this.gridRows + this.gridCols) * SQ3D2) / 2;
    },

    zoomOffsetY() {
      // Same as above, but for the Y axis
      return (TILESIZE * ((this.gridRows + this.gridCols) / 2)) / 2;
    }
  },

  methods: {
    initCells() {
      this.cells = [];
      for(let y = 0; y < this.gridRows; ++y) {
        this.cells[y] = [];

        for(let x = 0; x < this.gridCols; ++x) {
          // Pre-calculate the paths for each cell
          let coords = [[x, y], [x + 1, y], [x + 1, y + 1], [x, y + 1]].map(e => e.map(c => c * TILESIZE)).map(e => this.carToIso(...e));

          let path = new Path2D();
          for(let c = 0; c < coords.length; ++c) {
            if(c === 0) {
              path.moveTo(...coords[c]);
            } else {
              path.lineTo(...coords[c]);
            }
          }

          path.closePath();

          // Add the path and bottom corner for building alignment and the x, y itself for events
          this.cells[y][x] = {
            path,
            bottom: {
              x: coords[2][0],
              y: coords[2][1]
            },
            coords: {x, y},
            occupied: false
          };
        }
      }
    },

    initCanvas() {
      // Set the context and initial view port rect
      this.context = this.$refs.canvas.getContext('2d');

      // Register a resize handler to update the viewport rect and call it once for init
      window.addEventListener('resize', this.resize);
      this.resize();

      requestAnimationFrame(this.loop);
    },

    resize() {
      // Re-calculate the viewport rect
      this.viewportRect = this.$refs.viewport?.getBoundingClientRect();
    },

    loop() {
      let ctx = this.context;
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      // Save state to reset later
      ctx.save();

      // Background
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

      // Offset
      ctx.translate(this.offsetX, this.offsetY);

      // Zoom level
      ctx.scale(this.zoomLevel, this.zoomLevel);

      // Draw the grid
      this.drawGrid();

      // Draw the buildings
      this.drawBuildings();

      // Draw the mouse actions
      this.drawMouseActions();

      // Restore the state
      ctx.restore();

      // Next frame
      requestAnimationFrame(this.loop);
    },

    carToIso(x, y) {
      // Convert cartesian (x, y) to isometric coordinates
      return [
        Math.round((x - y) * SQ3D2 + this.canvasOffset),
        Math.round((x + y) / 2)
      ];
    },

    mouseToIso(x, y) {
      // Convert mouse (x, y in canvas) to grid coordinates
      return [
        ((x - this.canvasOffset) + SQ3 * y) / SQ3,
        (this.canvasOffset - x + SQ3 * y) / SQ3
      ];
    },

    drawGrid() {
      let ctx = this.context;

      // Draw lines
      ctx.beginPath();
      ctx.lineWidth = 1;

      // Rows
      for(let i = 0; i <= this.gridRows; ++i) {
        ctx.moveTo(...this.carToIso(0, i * TILESIZE));
        ctx.lineTo(...this.carToIso(this.gridWidth, i * TILESIZE));
      }

      // Columns
      for(let i = 0; i <= this.gridCols; ++i) {
        ctx.moveTo(...this.carToIso(i * TILESIZE, 0));
        ctx.lineTo(...this.carToIso(i * TILESIZE, this.gridHeight));
      }

      ctx.stroke();
    },

    drawBuildings() {
      // Draw the buildings
      for(let i = 0; i < this.placedBuildings.length; ++i) {
        let building = this.placedBuildings[i];
        this.drawBuildingAt(this.cells[building.y][building.x], building.building);

        /* TODO: REMOVE
        let ctx = this.context;
        ctx.save();

        ctx.strokeStyle = "red";
        ctx.moveTo(building.overlap.minH, 1000);
        ctx.lineTo(building.overlap.minH, 0);

        ctx.moveTo(building.overlap.maxH, 1000);
        ctx.lineTo(building.overlap.maxH, 0);

        ctx.stroke();

        ctx.restore();

         */
      }
    },

    drawBuildingAt(cell, building, outline=false) {
      let ctx = this.context;

      // Draw the building outline
      let x = cell.bottom.x - building.origin.x;
      let y = cell.bottom.y - building.origin.y;

      // Set transparency for outline
      if(outline) {
        ctx.globalAlpha = 0.5;
      }

      ctx.drawImage(building.image, x, y, building.image.width, building.image.height);
      ctx.globalAlpha = 1;
    },

    drawMouseActions() {
      // Footprint of building, if any, or indicator square
      if(this.activeCell) {
        let ctx = this.context;

        if(this.activeBuilding) {
          let paths = this.calculateFootprint(this.activeCell.coords.x, this.activeCell.coords.y, this.activeBuilding);

          // Draw the footprint
          for(let path of paths) {
            ctx.fillStyle = path.invalid ? "red" : "green";
            ctx.fill(path.path);
          }

          // Draw the building outline
          this.drawBuildingAt(this.activeCell, this.activeBuilding, true);
        } else {
          // Just a blue square
          ctx.fillStyle = "blue";
          ctx.fill(this.activeCell.path);
        }
      }
    },

    getFootprintCells(x, y, building) {
      // Get the footprint cells
      let startX = x - building.footprint.width + 1;
      let startY = y - building.footprint.height + 1;

      let cells = [];
      for(let row = startY; row <= y; ++row) {
        if(!this.cells[row]) {
          // Out of bounds
          continue;
        }

        for(let col = startX; col <= x; ++col) {
          if(!this.cells[row][col]) {
            // Out of bounds
            continue;
          }

          cells.push(this.cells[row][col]);
        }
      }

      return cells;
    },

    calculateFootprint(x, y, building) {
      let cells = this.getFootprintCells(x, y, building);

      // Check if it's out of bounds (less cells than needed)
      let oob = cells.length < building.footprint.width * building.footprint.height;

      // Get all cell paths and their colours
      let allValid = true;
      let paths = [];
      for(let cell of cells) {
        // Return path and occupied status
        let invalid = oob || cell.occupied
        paths.push({'path': cell.path, invalid});
        if(invalid) {
          allValid = false;
        }
      }

      /*
       * SHADE VISUALISATION BELOW
       */
      /*
      // Calculate top x, y
      let topX = x - building.footprint.width;
      let topY = y - building.footprint.height;

      // Calculate the "shade" - left diagonal
      let dX = topX;
      let dY = y;
      while(dX >= 0 && dY >= 0) {
        for(let sY = dY; sY >= dY - building.footprint.height && sY >= 0; --sY) {
          paths.push({path: this.cells[sY][dX].path, invalid: true});
        }

        --dX;
        --dY;
      }

      // Right diagonal
      dX = x;
      dY = topY;
      while(dX >= 0 && dY >= 0) {
        for(let sX = dX; sX >= dX - building.footprint.width && sX >= 0; --sX) {
          paths.push({path: this.cells[dY][sX].path, invalid: true});
        }

        --dX;
        --dY;
      }
       */

      // Store a placement flag
      this.canPlace = allValid;

      // Return the paths and their status
      return paths;
    },

    getCellForPoint(x, y) {
      [x, y] = this.mouseToIso(x, y);

      // Check for out of bounds
      if(x < 0 || y < 0 || x > this.gridWidth || y > this.gridHeight) {
        return null;
      }

      // Calculate cell index
      x = Math.floor(x / TILESIZE);
      y = Math.floor(y / TILESIZE);

      // Return cell
      return this.cells[y][x];
    },

    onMouseDown(e) {
      // Mouse is now down
      this.mousedown = true;

      // Set drag start in case we drag, deduct the offsets so we keep the offsets already in place
      this.dragX = e.clientX - this.offsetX;
      this.dragY = e.clientY - this.offsetY;
    },

    onMouseUp(e) {
      this.mousedown = false;

      // If we were dragging, it is now over
      if(this.dragging) {
        this.dragging = false;
      } else {
        // This was a click
        this.onClick(e);
      }
    },

    onMouseMove(e) {
      if(this.mousedown) {
        // If the mouse is down, we are now dragging
        this.dragging = true;
        this.onDrag(e);
      } else {
        // If the mouse is not down, this is a normal move, set the active cell by applying the proper modifiers to the coordinates
        this.activeCell = this.getCellForPoint(
          (e.clientX - this.viewportRect.left - this.offsetX) / this.zoomLevel,
          (e.clientY - this.viewportRect.top - this.offsetY) / this.zoomLevel);
      }
    },

    onDrag(e) {
      // Set the canvas offset
      this.offsetX = e.clientX - this.dragX;
      this.offsetY = e.clientY - this.dragY;
    },

    onClick(e) {
      if(this.activeBuilding && this.activeCell && this.canPlace) {
        // Can no longer place a building here (avoid double-click)
        this.canPlace = false;

        // Toggle all the cells to occupied
        let cells = this.getFootprintCells(this.activeCell.coords.x, this.activeCell.coords.y, this.activeBuilding);
        cells.forEach(c => c.occupied = true);

        // Place the building
        let x = this.activeCell.coords.x;
        let y = this.activeCell.coords.y;
        this.placedBuildings.push({x, y, cells, overlap: this.calculateOverlap(x, y, this.activeBuilding), building: this.activeBuilding});

        // Sort buildings by draw index
        this.sortBuildingDrawOrder();
      }
    },

    sortBuildingDrawOrder() {
      // First find the front-most buildings, these are buildings that are not behind any others
      let frontBuildings = [];
      for(let i = 0; i < this.placedBuildings.length; ++i) {
        let front = true;

        // Reset the order to -1 for all buildings
        this.placedBuildings[i].order = -1;

        for(let j = 0; j < this.placedBuildings.length; ++j) {
          if(i === j) {
            // Don't compare a building with itself
            continue;
          }

          // Check if building i is behind building j
          if(this.isBuildingBehind(this.placedBuildings[i], this.placedBuildings[j])) {
            // It is, this is not a front building
            front = false;
            break;
          }
        }

        if(front) {
          // This is a front building
          frontBuildings.push(this.placedBuildings[i]);
        }
      }

      // Recursively grade all buildings, starting at the front
      this.gradeDrawOrder(frontBuildings, 0);

      // Sort them by draw order
      this.placedBuildings.sort((b1, b2) => b1.order - b2.order);
    },

    gradeDrawOrder(buildings, value) {
      let behind = [];
      for(let building of buildings) {
        building.order = value;

        // Find all others behind this building
        for(let other of this.placedBuildings) {
          if(other === building) {
            // Do not compare against self
            continue;
          }

          if(this.isBuildingBehind(other, building)) {
            behind.push(other);
          }
        }
      }

      // Recursively grade the buildings behind
      if(behind.length > 0) {
        this.gradeDrawOrder(behind, value + 1);
      }
    },

    calculateOverlap(x, y, building) {
      // This calculates the min and max x, y and h coordinates, with h in real space
      // Calculate the top X and Y coords
      let topX = x - building.footprint.width;
      let topY = y - building.footprint.height;

      // Get the real coordinates for these positions
      let coordRight = this.carToIso(x * TILESIZE, topY * TILESIZE);
      let coordLeft = this.carToIso(topX * TILESIZE, y * TILESIZE);

      // Return min H, max H in real space and min and max X and Y in iso space
      return {
        minH: coordLeft[0],
        maxH: coordRight[0],
        minX: topX,
        maxX: x,
        minY: topY,
        maxY: y
      }
    },

    isBuildingBehind(b1, b2) {
      // Check if the buildings intersect at all
      if(b1.overlap.minH >= b2.overlap.maxH || b2.overlap.minH >= b1.overlap.maxH) {
        // There is no overlap, so no building is behind another
        return false;
      }

      // Test for intersection on the x axis (lower x is in front)
      if(b1.overlap.minX >= b2.overlap.maxX) {
        return true;
      } else if(b2.overlap.minX >= b1.overlap.maxX) {
        return false;
      }

      // Test for intersection on the y axis (lower y is in front)
      if(b1.overlap.minY >= b2.overlap.maxY) {
        return true;
      }

      // No known condition applies
      return false;
    },

    onWheel(e) {
      // Change the zoom level depending on deltaY positive or negative
      let prevZoom = this.zoomLevel;
      if(e.deltaY > 0) {
        this.zoomLevel = Math.max(ZOOMFACTOR, this.zoomLevel - ZOOMFACTOR);
      } else if(e.deltaY < 0) {
        this.zoomLevel = Math.min(1, this.zoomLevel + ZOOMFACTOR);
      }

      // Fix the offsets so the zoom keeps its center
      if(this.zoomLevel !== prevZoom) {
        let diff = prevZoom - this.zoomLevel;
        this.offsetX += this.zoomOffsetX * diff;
        this.offsetY += this.zoomOffsetY * diff;
      }
    }
  }
}
</script>
