<template>
  <div class="container-fluid mt-4">
    <div class="row" v-if="showHealth || health <= 0">
      <div class="col">
        <HealBox />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12">
        <Card>
          <template #header>
            Search for an opponent
          </template>
          <template #body>
            <FightResult :result="outcome" v-if="outcome?.outcome"/>
            <form action="" @submit.prevent="findOpponent"
                  class="d-flex justify-content-center m-5">
              <CountdownButton timer="fight_find_opponent" :loading="loading" type="submit"
                               text="Find new opponents"/>
            </form>
            <div v-if="opponents.length > 0" class="container-fluid">
              <div class="row">
                <div v-for="(opponent, index) in opponents"
                     class="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3 text-center" :key="index">
                  <Avatar :user="opponent" class="mb-4"/>
                  <h3 class="mb-3">{{ opponent.username }}</h3>
                  <div class="d-flex justify-content-around w-100 mb-5">
                    <Button @click="fightOpponent(opponent.id)" :loading="loading">Fight</Button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/layout/Card.vue";
import Button from "@/components/Button.vue";
import Avatar from "@/components/user/Avatar.vue";
import CountdownButton from "@/components/CountdownButton.vue";
import FightResult from "@/components/FightResult.vue";
import HealBox from "@/components/HealBox.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'Fight',
  components: {HealBox, FightResult, CountdownButton, Avatar, Button, Card},
  data() {
    return {
      loading: false,
      outcome: null,
      showHealth: false,
    }
  },
  computed: {
    ...mapState({
      health: state => state.user.stats.health
    }),
    opponents() {
      return this.$store.getters["fight/opponents"]
    },
    opponent() {
      return this.$store.getters["fight/opponent"]
    },
    findTimer() {
      return this.$store.getters["user/getTimerAbsoluteTime"]('fight_find_opponent')
    }
  },
  methods: {
    fightOpponent(id) {
      this.$store.commit("fight/SET_OPPONENT", id)
      this.loading = true;
      this.$http.post('fight', {
        user: id
      })
        .then(res => {
          if(res.data.outcome) {
            this.outcome = res.data
          }
        })
        .catch((res) => {
          console.log(res.response.data);
          if(res.response.data?.showHeal) {
            this.showHealth = true;
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },
    run() {
      this.loading = true;
      this.$http.post('fight/run')
        .then(res => console.log(res))
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        })
    },
    findOpponent() {
      this.loading = true;
      this.$http.post('fight/find')
        .then(res => {
          this.$store.commit("fight/SET_OPPONENTS", res.data)
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        })
    }
  }
}

</script>
