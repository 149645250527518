export const ChatStore = {
  namespaced: true,
  state: () => ({
    messages: [],
    channels: {
      general: []
    },
    newMessage: false,
  }),
  mutations: {
    SOCKET_CHAT_MESSAGE(state, data) {

      state.channels.general.push(data.data.chat_message);
    },
    SET_NEW_MESSAGE(state, data) {
      state.newMessage = data
    }
  }
}
