export const GameStore = {
  namespaced: true,
  state: () => ({
    time: new Date(),
    ranks: null,
    cities: [],
    crimes: [],
    gta: [],
    smuggleItems: [],
    readyToPlay: false,
    isMobile: false,
    serverConfig: {}
  }),
  mutations: {
    SOCKET_WEATHER_CHANGE(state, data) {
      data = data.data
      const cities = state.cities;
      const city = cities.find(c => c.id === data.id);
      city.weather = data.weather;
      city.temperature = data.temperature;
    },
    SET_MOBILE(state, isMobile) {
      state.isMobile = isMobile
    }
  },
  getters: {
    time(state, ctx, rootState) {
      const hours = state.time.getUTCHours();
      const minutes = state.time.getUTCMinutes();
      const seconds = state.time.getUTCSeconds();

      let h = hours,
          m = minutes,
          s = seconds;

      if(hours < 10)
        h = `0${hours}`;

      if(minutes < 10)
        m = `0${minutes}`;

      if(seconds < 10)
        s = `0${seconds}`;

      return  `${h}:${m}:${s}`;
    },
    isMobile(state) {
      return state.isMobile
    },
    smuggleItems(state) {
      return state.smuggleItems
    },
    skills(state) {
      return state.serverConfig.skills;
    }
  }
}
