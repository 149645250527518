export const ExtortionStore = {
  namespaced: true,
  state: () => ({
    data: {
      attempts: []
    }
  }),
  mutations: {
    SOCKET_EXTORTION_LOG(state, data) {

      state.data.attempts.unshift(data.data);
    },
    SET_HISTORY(state, data) {
      state.data.attempts = data;
    },
  },
  actions: {
  },
  getters: {
    logs: (state) => {
      return state.data.attempts
    }
  }
}
