<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col">
        <Card class="mb-4">
          <template #body>
            Every 15 minutes you can train one stat, these stats help in both boxing and fighting. Every hour a random stat will degrade by 1.
          </template>
        </Card>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4" v-for="(option, index) in options" :key="index">
        <Card>
          <template #body>
            <div class="d-flex align-items-center flex-column">
              <i :class="`flaticon-${option.icon}`" style="font-size: 48px;" />
              <div class="w-100 text-center" v-html="option.description"></div>
              <div class="progress bg-dark w-100 mt-4">
                <div class="bg-success text-black text-center" :style="`width: ${Math.max(10, getStat(option.icon))}%`">
                  {{ getStat(option.icon) }}%
                </div>
              </div>
            </div>
          </template>
          <template #footer>
            <form @submit.prevent="train(option.icon)">
              <CountdownButton :text="`Train ${option.name}`" timer="gym" class="w-100" :loading="loading" />
            </form>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/layout/Card.vue";
import CountdownButton from "@/components/CountdownButton.vue";

export default {
  name: 'Gym',
  components: {Card, CountdownButton},
  data() {
    return {
      loading: false,
      options: [{
        name: 'Strength',
        icon: 'strength',
        description: "Gain an upper hand when fighting <p>(increases attack power)</p>",
      },{
        name: 'Defense',
        icon: 'defense',
        description: "Put up more of a fight by training your defense <p>(increases defensive power)</p>",
      }, {
        name: 'Endurance',
        icon: 'endurance',
        description: "Faster, quicker, more agile <p>(reduces damage taken in a fight)</p>",
      }]
    }
  },
  methods: {
    getStat(stat) {
      return this.$store.getters["user/getStat"](stat)
    },
    train(type) {
      this.loading = true;

      this.$http.post('gym/train', {
        type
      })
        .catch(() => {

        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
