<template>
  <div id="mobile-nav">
    <ul class="list-unstyled mb-0 d-flex">
      <li>
        <router-link :to="{name: 'feed'}">
          <i class="flaticon-home menu-icon"/>
          Home
        </router-link>
      </li>
      <li :class="timerReady('crimes') ? 'ready' : 'not-ready'" class="timer">
        <router-link :to="{name: 'crimes'}">
          <i class="flaticon-crimes menu-icon"/>
          Crimes
        </router-link>
      </li>
      <li :class="timerReady('gta') ? 'ready' : 'not-ready'" class="timer">
        <router-link :to="{name: 'gta'}">
          <i class="flaticon-car-grade-4 menu-icon"/>
          Autotheft
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'criminal-jobs'}">
          <i class="flaticon-jobs menu-icon"/>
          Jobs

          <stat-progress
            v-if="getJobProgress !== false"
            type="line"
            class="position-absolute bottom-0"
            style="height: 3px;"
            :percent="getJobProgress" :stroke="`green`" :height="21" :hover="getJobProgress + '%'"/>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import StatProgress from "@/components/stat-progress.vue";
import {mapGetters, mapState} from "vuex";

export default {
  components: {StatProgress},
  methods: {
    timerReady(timer) {
      return this.$store.getters['user/timerReady'](timer)
    },
  },
  computed: {
    ...mapState({
      jobs: state => state.jobs.jobs,
    }),
    ...mapGetters({
      userid: "user/id",
    }),
    getJobProgress() {
      if (!this.jobs)
        return;

      const job = this.jobs.find(j => j.user_id === this.userid && j.completed_at === null);

      if (!job)
        return false;

      let neededReqs = 0,
        progress = 0;

      job.requirements.map((j) => {
        neededReqs += j.amount;
        progress += job.progress[j.name];
      });

      return ((100 / neededReqs) * progress).toFixed(2);
    }
  }
}
</script>
