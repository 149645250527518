<template>
  <span :title="timeFormatted">
    <i class="bi bi-clock me-0 text-small" v-if="icon"/> {{ timeAgo }} {{ text || 'left'}}
  </span>
</template>
<script>
export default {
  name: 'TimeUntil',
  props: {
    time: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      required: false
    }
  },
  computed: {
    timeAgo() {

      let seconds = Math.floor((new Date(this.time) - (new Date(this.$store.state.game.time))) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }

      if(seconds < 0)
        seconds = 0;

      return Math.floor(seconds) + " seconds";
    },
    timeFormatted() {
      return (new Intl.DateTimeFormat('en-gb', {
        dateStyle: "full",
        timeStyle: "full"
      })).format(new Date(this.time))
    }
  }
}
</script>
