<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <Card class="mb-4 mt-4">
          <template #header>
            Account Overview
          </template>
          <template #body>
            <table class="table table-hover table-bordered">
              <tbody>
              <tr>
                <td><strong>Rank:</strong> {{ rank }}</td>
                <td><strong>Crew:</strong> {{ crew }}</td>
                <td></td>
              </tr>
              <tr>
                <td><strong>Money:</strong> ${{ money }}</td>
                <td><strong>Credits:</strong> {{ getItem('credits').amount }}</td>
                <td><strong>BlocPoints:</strong> {{ getItem('bloc_points').amount }}</td>
              </tr>
              </tbody>
            </table>

          </template>
        </Card>
      </div>
      <div class="col-12 col-md-6">
        <Card class="mb-4">
          <template #header>Account Information</template>
          <template #body>
            <table class="table table-bordered table-hover">
              <tbody>
              <tr>
                <td>Username: <strong>{{ user.username }}</strong></td>
                <td>Email: <strong>{{ user.email }}</strong></td>
              </tr>
              </tbody>
            </table>
          </template>
        </Card>
      </div>
      <div class="col-12 col-md-6">
        <Card class="mb-4">
          <template #header>Fight Statistics</template>
          <template #body>
            <table class="table table-bordered table-hover">
              <tbody>
              <tr>
                <td>Fights Results</td>
                <td>
                  <span class="text-success">{{ stats['fight_wins'] ?? 0 }}</span> /
                  <span class="text-danger">{{ stats['fights_lost'] ?? 0 }}</span>
                  <span class="text-muted ms-2">({{ (stats['fight_wins'] ?? 0) + (stats['fights_lost'] ?? 0) }})</span>
                </td>
              </tr>
              <tr>
                <td>Fights Defends Won</td>
                <td>
                  <span class="text-success">{{ stats['fight_defends_won'] ?? 0 }}</span> /
                  <span class="text-danger">{{ stats['fight_defends_lost'] ?? 0 }}</span>
                  <span class="text-muted ms-2">({{ (stats['fight_defends_won'] ?? 0) + (stats['fight_defends_lost'] ?? 0) }})</span>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </Card>
      </div>
      <div class="col-12 col-md-6">
        <Card class="mb-4">
          <template #header>Criminal Activity</template>
          <template #body>
            <table class="table table-bordered table-hover">
              <tbody>
              <tr v-for="(stat, index) in visibleStats" :key="index">
                <td class="text-capitalize">
                  {{ getNiceName(stat) }}
                </td>
                <td>
                  <span class="text-success">{{ stats['successful_' + stat] }}</span> / <span
                  class="text-danger">{{ stats['failed_' + stat] }}</span>
                  ({{ stats['successful_' + stat] + stats['failed_' + stat] }})
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/layout/Card.vue";

export default {
  components: {Card},
  data() {
    return {
      visibleStats: [
        'extortions',
        'gtas',
        'crimes',
        'jail_busts',
      ],
    }
  },
  computed: {
    stats() {
      return this.$store.getters["user/stats"]
    },
    user() {
      return this.$store.getters["user/user"]
    },
    formattedStats() {
      return this.stats;
    },
    rank() {
      return this.$store.getters["user/rank"]
    },
    money() {
      return this.$store.getters["user/money"]
    },
    crew() {
      return this.$store.getters["user/crew"];
    }
  },
  methods: {
    getNiceName(stat) {
      return stat
        .replaceAll('_', ' ')
        .replaceAll('-', ' ')
    },
    getItem(item) {
      return this.$store.getters["user/getItem"](item)
    }
  }
}


</script>
