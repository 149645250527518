<template>
  <div>
    <Card :class="{'bg-success': user.id === job.user_id}"
          class="bg-primary m-3 mb-3">
      <template #header>
        <h4 class="d-flex align-items-center">
          {{ job.name }}
        </h4>
      </template>
      <template #body>
        <div class="position-relative">
          <i :class="[`flaticon-${className}`, 'icon-large']" class="position-absolute" style="right: 15px; top: 15px;"/>
          <div class="d-flex flex-row">
            <div class="flex-grow-1">
              <h6>Rewards</h6>
              <ul class="list-unstyled">
                <li v-for="(reward, index) in job.rewards" :key="index"
                    class="d-flex align-items-center">
                  <i :class="[`flaticon-${reward.name}`, 'me-2']"/> {{ reward.amount }}
                </li>
              </ul>
              <h4>Requirements</h4>
              <ul>
                <li v-for="(reward, index) in job.requirements" :key="index"
                    class="text-capitalize">
                  {{ unsnake(reward.name) }}:
                  {{ reward.amount }}
                </li>
              </ul>
              <template v-if="job.user_id === user.id">
                <h4>Progress</h4>
                <ul class="list-unstyled">
                  <li v-for="(progress, index) in job.progress" :key="index"
                      class="text-capitalize">
                    {{ unsnake(index) }}
                    {{ progress }}/{{ getRequirement(index).amount }}
                    {{ percent(getRequirement(index).amount, progress) }}%


                    <div class="progress bg-dark">
                      <div class="progress-bar bg-success"
                           :style="{'width': percent(getRequirement(index).amount, progress) + '%'}"></div>
                    </div>
                  </li>
                </ul>
              </template>
            </div>
          </div>

        </div>
      </template>
      <template #footer>
        <Button :loading="loading" v-if="!job.user_id" block @click="takeJob">Take Job</Button>
      </template>
    </Card>
  </div>
</template>
<style scoped>


.overlay > div {
  transition: all ease-in-out 0.3s;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

</style>
<script>
import {mapState} from "vuex";
import formats from "@/helpers/formats"
import Card from "@/components/layout/Card.vue";
import Button from "@/components/Button.vue";

export default {
  name: 'Job',
  components: {Button, Card},
  props: {
    id: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      jobs: state => state.jobs.jobs,
      user: state => state.user,
    }),
    job() {
      return this.jobs.find(j => j.id === this.id)
    },
    className() {
      const name = this.job.name.toLowerCase();

      if (name.indexOf('car') > -1)
        return 'gta';

      return 'gta';
    }
  },
  methods: {
    takeJob() {
      if (this.job.user_id) return;
      this.loading = true;
      this.$http.post(`jobs`, {
        jobId: this.id
      })
        .finally(() => {
          this.loading = false;
        })
    },
    getRequirement(index) {
      return this.job.requirements.find(r => r.name === index)
    },
    percent(requirement, progress) {
      return (100 / requirement * progress).toFixed(2)
    },
    unsnake(text) {
      return formats.unsnake(text);
    }
  }
}
</script>
