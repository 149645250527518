export const UserStore = {
  namespaced: true,
  state: () => ({}),
  mutations: {
    SOCKET_SET_STAT(state, data) {
      state.stats[data.data.key] = data.data.value
    },
    SOCKET_DELETE_STAT(state, data) {
      const stat = data.data;

      if (state.stats.hasOwnProperty(stat.key)) {
        delete state.stats[stat.key];
      }
    },
    SOCKET_SET_SKILL(state, data) {
      console.log(data.data)
      state.skills[data.data.skill] = data.data.level
    },
    SOCKET_LOGOUT(state, data) {
      if (localStorage.getItem('_token')) {
        localStorage.removeItem('_token')
        localStorage.removeItem('_character')
        location.reload();
      }
    },
    SOCKET_UPDATE() {
      // @todo implement game update to notify and refresh?
    },
    SOCKET_SET_TIMER(state, data) {
      const timer = data.data;
      state.timers[timer.key] = timer.value;
    },
    SOCKET_DELETE_TIMER(state, data) {
      // @todo implement implement delete timer
    },
    SOCKET_SET_COUNTER(state, data) {
      const counter = data.data;
      state.counters[counter.key] = counter.value;
    },
    SOCKET_SYNC_COUNTER(state, data) {

    },
    SOCKET_UPDATE_ITEM(state, data) {
      const item = data.data;

      state.items[item.item.name] = item;
    },
    SOCKET_ADD_ITEM(state, data) {
      const item = data.data;
      const userItems = state.items;

      if (userItems.hasOwnProperty(item.item.name))
        userItems[item.item.name].dupes += item.amount;
      else
        userItems[item.item.name] = item;
    },
    SOCKET_RELEASE_SELF_FROM_JAIL(ctx, data) {
      // @todo fix toast notifications

      // toast.success("You have been let free");
    },
    SOCKET_AVATAR_CHANGE(state, data) {
      state.avatar = data.data;
      // @todo fix toast notifications
      // toast.success("Your avatar has been successfully updated");
    },
    SOCKET_FRIENDSHIP_CREATED(state, data) {
      state.relationships.push(data.data);
    },
    SOCKET_FRIENDSHIP_REMOVED(state, data) {
      let relationship = state.relationships.findIndex(s => s.id === data.data);
      state.relationships.splice(relationship, 1);
    },
    SOCKET_FRIENDSHIP_ACCEPTED(state, data) {
      let relationship = state.relationships.findIndex(s => s.id === data.data);
      let rel = state.relationships[relationship];
      rel.status = 'accepted'
    }
  },
  getters: {
    id(state) {
      return state.id
    },
    notificationLastRead(state) {
      return state.timers['last_read_notifications'];
    },
    rank(state, commit, rootState) {
      return rootState.game.ranks[rootState.user.stats['rank']]?.name;
    },
    rankMeta(state, commit, rootState) {
      return rootState.game.ranks[rootState.user.stats['rank']];
    },
    timerReady: (state, commit, rootState) => (timer) => {
      const now = rootState.game.time;

      if (timer === 'crimes') {
        let keys = Object.keys(state.timers).filter((k, v) => {

          if (~k.indexOf('crimes_timer')) {
            const time = new Date(state.timers[k])

            return time < now;
          }

          return false;
        });

        return keys.length || 0;
      }

      return new Date(state.timers[timer]) < now || !state.timers[timer];
    },
    timerTimeLeft: (state, ctx, rootState) => (action) => {
      const timer = state.timers[action];

      if (!timer) return '';

      const timeLeft = ((new Date(timer) - (new Date(rootState.game.time))) / 1000);

      return timeLeft < 0 ? '' : Math.floor(timeLeft);
    },
    getTimerAbsoluteTime: (state) => (action) => {
      const timer = state.timers[action];

      if (!timer)
        return 0;

      return timer;
    },
    getItem: (state) => (item) => {
      return state.items[item];
    },
    city: (state, commit, rootState) => {
      const cities = rootState.game.cities;
      const city = state.stats['city_id'];

      return cities.find(c => c.id === city);
    },
    getStat: (state) => (stat) => {
      return state.stats[stat] || 0
    },
    country: (state, commit, rootState) => {
      const cities = rootState.game.cities;
      const country = state.stats['country_id'];

      return cities.find(c => c.id === country);
    },
    money: (state, commit, rootState) => {
      const amount = rootState.user.items?.money?.amount || 0;
      return new Intl.NumberFormat('en-gb').format(amount);
    },
    moneyRaw: (state, commit, rootState) => {
      return rootState.user.items?.money?.amount || 0;
    },
    bulletsRaw: (state, commit, rootState) => {
      return rootState.user.items?.bullets?.amount || 0;
    },
    bullets: (state, commit, rootState) => {
      const amount = rootState.user.items?.bullets?.amount || 0;
      return new Intl.NumberFormat('en-gb').format(amount);
    },
    cashInBank: (state, commit, rootState) => {
      const amount = rootState.user.stats.cash_in_bank;
      return new Intl.NumberFormat('en-gb').format(amount)
    },
    fullBodyAvatar: (state) => {
      return state.avatar?.links?.body;
    },
    accessLevel: (state) => {
      return state.user.groups;
    },
    crew: (state) => {
      return state.crew
    },
    relationships: (state) => {
      return state.relationships
    },
    isAdmin: (state) => {
      return state.groups.findIndex(g => g.name === 'administrator') > -1
    },
    isHdo: (state) => {
      return state.groups.findIndex(g => g.name === 'hdo') > -1
    },
    isModerator: (state) => {
      return state.groups.findIndex(g => g.name === 'moderator') > -1
    },
    friends: (state) => {
      return state.relationships.filter(r => r.status === 'accepted' || r.sender.id === state.id && r.status === 'pending');
    },
    blocked: (state) => {
      return state.relationships.filter(r => r.status === 'blocked');
    },
    pending: (state) => {
      return state.relationships.filter(r => r.sender_id !== state.id && r.status === 'pending')
    },
    isFriend: (state) => (username) => {
      return state.relationships.find(s => (s.recipient.username === username || s.sender.username === username) && s.status === 'accepted')
    },
    isPendingFriendRequest: (state) => (username) => {
      return state.relationships.find(s => s.recipient.username === username && (s.status === 'pending'))
    },
    isBlocked: (state) => (username) => {
      return state.relationships.find(s => s.recipient.username === username && s.status === 'blocked')
    },
    stats: (state) => {
      return state.stats;
    },
    items: (state) => {
      return state.items;
    },
    user: (state) => {
      return state
    },
    skills: (state) => {
      return state.skills
    }
  }
}
