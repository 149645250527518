<template>
  <div class="mobile-nav-stats">
    <div class="d-md-none d-flex flex-row align-items-center px-4">
      <ul class="list-unstyled d-block">
        <li>
          <i class="flaticon-money"></i> {{ money }}
        </li>
        <li>
          <i class="flaticon-bullets"></i> {{ bullets }}
        </li>
        <li>
          <i class="flaticon-airport"></i> {{ city.name }}
        </li>
        <li>
          <i class="flaticon-star"></i> {{ ranks[rank].name }}
        </li>
        <li>
          <i class="flaticon-crew"></i> {{ inCrew ? crew : 'None' }}
        </li>
      </ul>
    </div>
    <div class="w-50 ms-auto d-flex align-items-center flex-column">
      <div>
        <div
          class="d-flex flex-row justify-content-center ms-auto stat-progress-bars mt-3">
          <stat-progress :percent="health"
                         stroke="red"
                         icon="health"
                         :hover="`Your health`"
                         class="me-3"
                         :height="36" />
          <stat-progress :percent="calculate_rank_progress"
                         stroke="cyan"
                         icon="experience"
                         class="me-3"
                         :height="36">
            <template #popper>

              <h4>{{ currentRank.name }}</h4>

              <h6>Rankup requirements</h6>
              <ul>
                <li v-for="(requirement, index) in Object.keys(nextRank.requirements)" :key="index"
                    :class="{
                'text-success': stats[requirement] >= nextRank.requirements[requirement]
              }">
                  {{ requirement }}
                  {{ stats[requirement] }} / {{ nextRank.requirements[requirement] }}
                </li>
              </ul>

            </template>
          </stat-progress>
          <stat-progress :percent="calculate_happiness"
                         stroke="orange"
                         :icon="activityIcon"
                         :height="36"
                         :hover="`Your happiness affects your actions, do things that make you happy to gain more happiness`" />
        </div>
      </div>
<!--      <Avatar :user="user.avatar" id="game-avatar" style="max-width:120px" />-->
    </div>
  </div>
</template>

<script>
import StatProgress from "@/components/stat-progress.vue";
import {mapGetters, mapState} from "vuex";
import Avatar from "@/components/user/Avatar.vue";

export default {
  components: {StatProgress},
  computed: {
    ...mapGetters({
      city: "user/city",
      crew: "crew/crew",
      inCrew: "crew/inCrew",
      money: "user/money",
      bullets: "user/bullets"
    }),
    ...mapState({
      stats: state => state.user.stats,
      rank: state => state.user.stats.rank,
      ranks: state => state.game.ranks,
      health: state => state.user.stats.health,
      xp: state => state.user.stats.xp,
      currentRankId: state => state.user.stats.rank,
      user: state => state.user,
    }),
    calculate_happiness() {
      return Math.floor(
        (100 / this.stats.max_happiness) * (this.stats.happiness)
      )
    },
    nextRank() {
      return this.ranks[this.currentRankId + 1]
    },
    activityIcon() {

      if (this.calculate_happiness > 90) {
        return 'happy'
      } else if (this.calculate_happiness > 50) {
        return 'smile'
      } else if (this.calculate_happiness > 40) {
        return 'neutral'
      } else if (this.calculate_happiness > 20) {
        return 'sad'
      } else {
        return 'dead'
      }
    },
    calculate_rank_progress() {
      const currentRankId = this.$store.state.user.stats['rank'];
      let nextRank = this.ranks[currentRankId + 1],
        currentRank = this.ranks[currentRankId];

      if (!nextRank)
        return 100;

      const xp = this.xp;

      // if (nextRank.experience <= 100)
      //   return xp;

      const actualPercent = Math.floor(100 / (nextRank.experience - (currentRank.experience)) * (xp - currentRank.experience));

      // You can gain more xp than rank but not rank up if requirements are not met
      if (actualPercent > 100)
        return 100;

      return Number(actualPercent);
    },
    rank_percent() {
      const currentRankId = this.rank;
      let nextRank = this.ranks[currentRankId + 1],
        currentRank = this.ranks[currentRankId];

      if (!nextRank)
        return 100;

      const xp = this.xp;

      // if (nextRank.experience <= 100)
      //   return xp;

      const actualPercent = Math.floor(100 / (nextRank.experience - (currentRank.experience)) * (xp - currentRank.experience));

      // You can gain more xp than rank but not rank up if requirements are not met
      if (actualPercent > 100)
        return 100;

      return actualPercent;
    },

    currentRank() {
      return this.ranks[this.rank]
    },
  },
  methods: {
    goToProfile() {
      this.$router.push({
        name: 'profile',
        params: {
          username: this.username
        }
      })
    }
  }

}
</script>
