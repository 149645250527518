<template>
  <div class="d-flex flex-row">
    <Avatar :user="item.item.author" class="me-3"/>
    <div class="flex-grow-1">
      <div class="d-flex flex-row mb-2 flex-wrap">
        <username :username="item.item.author.username" :icon="false"/>
        &nbsp;created a new thread in&nbsp;
        <router-link :to="{
          name: 'forums',
          params: {
            category: item.item.category.slug
          }
        }">
          {{ item.item.category.name }}
        </router-link>
        <span class="ms-auto">
          <TimeAgo :time="item.item.created_at"/>
        </span>
      </div>

      <Card>
        <template #body>
          <div v-html="item.item.formatted_post"></div>
        </template>
      </Card>
    </div>
  </div>
</template>
<script>
import Username from "@/components/Username.vue";
import Card from "@/components/layout/Card.vue";
import Avatar from "@/components/user/Avatar.vue";
import TimeAgo from "@/components/timeago.vue";

export default {
  name: "ForumThread",
  components: {TimeAgo, Avatar, Card, Username},
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
