<template>
<div class="mb-3">
  <PageBar>
    <div>
      <h4>{{ crew.name }} <br/><span class="text-muted">base</span></h4>
    </div>
    <div class="ms-auto d-flex flex-row">
        <span class="border-dark d-flex align-items-center me-3">
          <i class="flaticon-user me-2"/>
          {{ crew.members.length }} crew members
        </span>
      <span class="d-flex align-items-center border-dark">
          <i class="flaticon-money"/>
          <CountUp :number="bank"/>
        </span>
    </div>
  </PageBar>
  <ul class="nav nav-pills bg-dark p-3">
    <li class="nav-item me-3">
      <router-link :to="{name: 'crew-base'}" active-class="active" class="nav-link">Crew Base</router-link>
    </li>
    <!--    <li class="nav-item">-->
    <!--      <router-link :to="{name: 'crew-turf'}" active-class="active" class="nav-link">Crew Turf</router-link>-->
    <!--    </li>-->
    <li class="nav-item me-3">
      <router-link :to="{name: 'crew-members'}" active-class="active" class="nav-link">Crew Members</router-link>
    </li>
  </ul>
</div>
</template>
<script>
import PageBar from "@/components/PageBar.vue";
import CountUp from "@/components/count-up.vue";

export default {
  name: "CrewHeader",
  components: {CountUp, PageBar}
}
</script>
