<template>
  <div>
    <PageBar>
      <div>
        You have <strong>{{ spaceLeftInGarage }}</strong> spaces left in your garage. Your capacity
        is <strong>{{ capacity }}</strong>
      </div>
      <div class="ms-auto">
        <Button @click="extendGarage">
          Extend garage +5 spaces for
          &pound;{{ number_format(costForNewSpace) }}
        </Button>
      </div>
    </PageBar>
    <div class="garage-container position-relative">
      <div class="garage-car-list">
        <VueDragSelect attribute="id" @change="selectMultiple" :disabled="isMobile">
          <div class="container-fluid g-0" style="z-index: -1">
            <div class="row g-0">
              <TransitionGroup>
                <DragSelectOption v-for="car in cars" :key="car.id" :id="car.id" :value="car.id"
                                  class="col-12 col-md-6 col-lg-4 col-xl-4 g-0">
                  <Car :id="car.id" class="m-3"/>
                </DragSelectOption>
              </TransitionGroup>
            </div>
          </div>
        </VueDragSelect>
      </div>
      <div class="garage-options mt-3 me-3">
        <Card class="sticky-top">
          <template #header>Selected {{ countSelectedCars }} car(s)</template>
          <template #body>

            <ul class="list-unstyled garage-option-list mb-0">
              <li class="mb-3" @click="repair">
                <div class="d-flex align-items-center mb-2">
                  <i class="flaticon-money"/>
                  {{ number_format(totalRepairAmount) }} Repair Cost
                </div>
                <Button @click="repair" :loading="loading" block>
                  Pay &pound;{{ number_format(totalRepairAmount) }} for repairs
                </Button>
              </li>
              <li class="mb-3" @click="smelt">
                <div class="d-flex align-items-center mb-2">
                  <i class="flaticon-bullets"/>
                  {{ this.number_format(totalSmeltAmount) }} Smelt Amount
                </div>
                <Button block :loading="loading" @click="smelt">
                  Smelt {{ countSelectedCars }} for {{ this.number_format(totalSmeltAmount) }} bullets
                </Button>

              </li>
              <li class="mb-3" @click="sell">
                <div class="d-flex align-items-center mb-2">
                  <i class="flaticon-bullets"/>
                  {{ number_format(totalSellAmount) }} Sell Amount
                </div>
                <Button block :loading="loading" @click="sell">
                  Sell {{ countSelectedCars }} for &pound;{{ number_format(totalSellAmount) }}
                </Button>
              </li>
            </ul>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import Car from "@/components/Car";
import Card from "@/components/layout/Card";
import PageBar from "@/components/PageBar";
import VueDragSelect, {DragSelectOption} from "@coleqiu/vue-drag-select";
import Button from "@/components/Button.vue";

export default {
  name: 'Garage',
  data() {
    return {
      holdingCtrl: false,
      loading: false,
    }
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.code === 'ControlLeft' && !this.holdingCtrl)
        this.holdingCtrl = true;
    });

    document.addEventListener("keyup", (e) => {
      if (e.code === 'ControlLeft' && this.holdingCtrl)
        this.holdingCtrl = false
    })
  },
  components: {Button, DragSelectOption, PageBar, Card, Car, VueDragSelect},
  computed: {
    ...mapState({
      cars: state => state.garage.cars,
      selectedCars: state => state.garage.selectedCars,
      user: state => state.user
    }),
    ...mapGetters({
      totalSmeltAmount: "garage/totalSmeltAmount",
      totalSellAmount: "garage/totalSellAmount",
      totalShipAmount: "garage/totalCarShippingAmount",
      totalRepairAmount: "garage/totalRepairAmount",
      isMobile: "game/isMobile"
    }),
    countSelectedCars() {
      return this.$store.state.garage.selectedCars.length;
    },
    capacity() {
      return this.user.stats.garage_total_space || 10;
    },
    spaceLeftInGarage() {
      let spaceLeft = this.capacity - this.cars.length

      return spaceLeft < 0 ? 0 : spaceLeft;
    },
    costForNewSpace() {
      return ((this.capacity + 5) * 500) * 1.2
    },
  },
  methods: {
    sell() {
      this.$http.post('garage/sell', {
        cars: this.selectedCars
      })
    },
    smelt() {
      this.$http.post('garage/smelt', {
        cars: this.selectedCars
      })
    },
    repair() {
      this.$http.post('garage/repair', {
        cars: this.selectedCars
      })
    },
    number_format(number) {
      return new Intl.NumberFormat('en-gb').format(number);
    },
    extendGarage() {
      let cost = this.costForNewSpace;
      this.loading = true;
      if (!confirm(`Are you sure you want to pay &pound; ${cost}`)) {
        return;
      }

      this.$http.post('garage/extend')
        .finally(() => this.loading = false)

    },
    selectMultiple(cars) {

      const state = this.$store.state.garage;
      if (this.holdingCtrl) {
        state.selectedCars = [...new Set([...state.selectedCars, ...cars])];
      } else {
        state.selectedCars = [...cars];
      }
    }
  }
}
</script>
