<template>
  <div>

    <div class="container mt-5">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-4 offset-md-3">
          <Card>
            <template #header>
              <h5>War</h5>
            </template>
            <template #body>
              <p>War is simple, one card each, highest wins</p>

              <div class="input-group mb-3">
                <FancyNumber :id="'savings-cash'" @ev="(input) => {bet = input}"
                             :value="bet"></FancyNumber>
                <input type="submit" name="" value="Bet" @click="makeBet" class="btn">
              </div>


            </template>

            <template #footer>
              <div class="container">
                <div class="row">
                  <div class="col-6 text-center">
                    <p>
                      Your Card
                    </p>
                    <img :src="getCard(result.casino)" alt="" style="max-height: 150px;">
                  </div>
                  <div class="col-6 text-center">
                    <p>
                      Casinos Card
                    </p>
                    <img :src="getCard(result.player)" alt="" style="max-height: 150px;">
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FancyNumber from "@/components/FancyNumber.vue";
import Card from "@/components/layout/Card.vue";

export default {
  name: 'War',
  components: {Card, FancyNumber},
  data() {
    return {
      bet: "$",
      result: {}
    }
  },
  methods: {
    getCard(card) {
      if(!card)
        card = 'cover';

      return "/images/cards/" + card + ".png"
    },
    makeBet() {
      this.$http.post('casino/war', {
        bet: this.bet
      })
        .then(res => {

          this.result = res.data.cards;
        }).catch(() => {
      });
    }
  }
}
</script>
