<template>
  <router-view/>
</template>
<script>
export default {
  name: 'App',
  mounted() {
    this.$socket.emit('authentication', localStorage.getItem('_token'));
  },
  sockets: {
    // 'logout'() {
    //   if(localStorage.getItem('_token')) {
    //     localStorage.removeItem('_token')
    //     localStorage.removeItem('_character')
    //     location.reload();
    //   }
    // }
  }
}
</script>
