<template>
  <div class="progress-ring-container" v-if="type === 'circle'">
    <VTooltip :delay="0" :placement="'bottom'" :triggers="triggers">
      <circle-progress :percent="percent" :size="height" :border-bg-width="3" :border-width="3"
                       :fill-color="stroke" empty-color="#333"></circle-progress>
      <div class="overlay" :style="`width: ${height}px;height:${height}px;`" v-if="icon">
        <div>
          <i :class="`flaticon-${icon} d-flex`" :style="`color: ${stroke}; font-size: 1.2rem`"/>
        </div>
        <div class="hover" :style="`border-color: ${stroke}`">
          {{ percent }}%
        </div>
      </div>
      <template #popper>
        {{ hover }}
        <slot name="popper"></slot>
      </template>
    </VTooltip>
  </div>
  <div v-else class="progress bg-transparent w-100 d-flex justify-content-center">
    <div class="progress-bar" :style="{width: percent + '%'}"></div>
  </div>
</template>
<style lang="scss" scoped>
.progress-ring-container {
  position: relative;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  border-radius: 50%;
}

.overlay {
  left: 0;
  top: 0;
  position: absolute;
  color: white;
  z-index: 5;

  > div {
    transition: all ease-in-out 0.3s;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .hover {
    opacity: 0;
  }

  i {
    transition: all ease-in-out 0.3s;
  }

  &:hover {

    i {
      transform: scale(3);
    }

    div:not(.hover) {
      opacity: 0;
    }

    div.hover {
      opacity: 1;
    }
  }
}

</style>
<script>


import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";

export default {
  name: 'StatProgress',
  components: {CircleProgress},
  data() {
    return {
      styles: {
        'stroke-dasharray': 0,
        'stroke-dashoffset': 0,
        'circumference': 0,
      }
    }
  },
  props: {
    percent: {
      required: true,
    },
    stroke: {
      type: String,
      default: 'red'
    },
    icon: {
      type: String,
      required: false,
    },
    height: {
      type: Number,
      default: 60
    },
    hover: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'circle'
    },
    triggers: {
      type: Array,
      required: false,
      default: () => ['click', 'hover']
    }
  },
  watch: {
    percent() {
      this.calculateStyles()
    }
  },
  mounted() {
    this.calculateStyles()
  },
  methods: {
    calculateStyles() {

      const circle = this.$refs.circle;

      if (!circle)
        return;

      const radius = circle.r.baseVal.value;
      const circumference = radius * 2 * Math.PI;
      const offset = circumference - this.percent / 100 * circumference;

      this.styles['stroke-dasharray'] = `${circumference} ${circumference}`;
      this.styles['circumference'] = `${circumference}`;
      this.styles['stroke-dashoffset'] = offset;
    }
  }
}
</script>
