<template>
  <PageBar>
    <div class="filter d-flex align-items-center">
      <i class="flaticon-filter me-3"/>
      {{ city.name }} Bank
    </div>
    <div class="ms-auto d-flex align-items-center me-3">
      <i class="flaticon-money me-2"/>
      <CountUp :number="user.stats.cash_in_bank"/>
    </div>

    <div class="d-flex flex-row">
      <router-link :to="{name: 'bank'}" class="d-flex align-items-center">
        <Button class="me-2">
          <i class="flaticon-transactions me-2"/> Transactions
        </Button>
      </router-link>
      <router-link :to="{name: 'bank-deposit'}" class="d-flex align-items-center">
        <Button>
          <i class="flaticon-deposit me-2"/> Deposit
        </Button>
      </router-link>
    </div>
  </PageBar>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import CountUp from "@/components/count-up";
import PageBar from "@/components/PageBar";
import Button from "@/components/Button.vue";

export default {
  name: "BankBar",
  components: {Button, PageBar, CountUp},
  computed: {
    ...mapGetters({
      city: "user/city",
    }),
    ...mapState({
      user: state => state.user
    }),
    bankMoney() {
      return new Intl.NumberFormat('en-gb').format((this.user.stats.cash_in_bank || 0));
    },
  }
}
</script>
