<template>
  <div :class="classBackground" class="p-3">
    <div class="text-center">
      <Avatar :user="user" class="mb-2"/>
    </div>
    <h5 class="mb-0"><Username :username="user.username" :icon="false"/></h5>

    <ul class="list-unstyled my-3" v-if="user.roles?.length > 0">
      <li><span v-html="user.roles.join(', ')"></span></li>
    </ul>
    <span v-else>Member</span>

    <ul class="list-unstyled">
      <li>Rank: {{ rank }}</li>
      <li>Posts: {{ total_posts }}</li>
    </ul>
  </div>
</template>
<style scoped lang="scss">

.bg-border {
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    z-index: 10;
    clip-path: polygon(0% 100%, 1px 100%, 1px 1px, calc(100% - 1px) 1px, calc(100% - 1px) calc(100% - 1px), 1px calc(100% - 1px), 1px 100%, 100% 100%, 100% 0%, 0% 0%);
  }
}

.bg-admin {
  background: linear-gradient(35deg, black 35%, var(--admin-primary-color-shade));

  &:after {
    background: linear-gradient(320deg, black 75%, var(--admin-primary-color));
    opacity: 0.5;
  }
}

.bg-mod {
  background: linear-gradient(35deg, black 35%, var(--mod-primary-color-shade));

  &:after {
    background: linear-gradient(320deg, black 75%, var(--mod-primary-color));
    opacity: 0.5;
  }
}

.bg-hdo {
  background: linear-gradient(35deg, black 35%, var(--hdo-primary-color-shade));

  &:after {
    background: linear-gradient(320deg, black 75%, var(--hdo-primary-color));
    opacity: 0.5;
  }
}

.bg-normal {
  background: linear-gradient(35deg, black 35%, #222);

  &:after {
    background: linear-gradient(320deg, black 75%, #555);
    opacity: 0.5;
  }
}
</style>
<script>
import Avatar from "@/components/user/Avatar.vue";
import Username from "@/components/Username.vue";
import {mapState} from "vuex";

export default {
  name: "PostBit",
  components: {Username, Avatar},
  props: {
    user: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      ranks: state => state.game.ranks,
    }),
    classBackground() {
      let className = '';
      const adminTest = [/Administrator/];
      const modTest = [/Mod/];
      const hdoTest = [/HDO/];

      if (adminTest.some(rx => rx.test(this.user.roles))) {
        return 'bg-border bg-admin';
      }

      if (modTest.some(rx => rx.test(this.user.roles))) {
        return 'bg-border bg-mod'
      }

      if (hdoTest.some(rx => rx.test(this.user.roles))) {
        return 'bg-border bg-hdo'
      }

      return 'bg-border bg-normal';
    },
    rank() {
      return this.ranks[this.user.stats.find(s => s.key === 'rank').value].name;
    },
    forum_posts() {
      return this.user.stats.find(s => s.key === 'forum_posts').value;
    },
    forum_threads() {
      return this.user.stats.find(s => s.key === 'forum_threads').value;
    },
    total_posts() {
      return this.forum_posts + this.forum_threads;
    }
  }
}
</script>
