<template>
  <div
    class="bg-opacity-75 z-100 opacity-0 d-flex justify-content-center align-items-center top-0 start-0 loader-container"
    :class="{
      'opacity-100': loading,
      'pointer-events-none': !loading,
      'h-100 w-100 position-absolute': !inline,
      'bg-black': !transparent
  }">
    <div class="loader-3" :style="styles"></div>
  </div>
</template>
<style>

.loader-container {
  transition: all ease-in-out 0.2s;
}

@keyframes rotate {
  0% {
    transform: rotate(0)
  }
  100% {
    transform: rotate(360deg)
  }
}


.loader-3 {
  position: relative;
}

.loader-3:before, .loader-3:after {
  content: '';
  border-radius: 50%;
  position: absolute;
  inset: 0;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
}

.loader-3:after {
  box-shadow: 0 2px 0 #FF3D00 inset;
  animation: rotate 2s linear infinite;
}
</style>
<script>
export default {
  name: 'LoadingSpinner',
  props: {
    loading: {
      type: Boolean,
    },
    size: {
      type: Number,
      default: 100,
      required: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    styles() {
      return {
        height: this.size + 'px',
        width: this.size + 'px',
      }
    }
  }

}
</script>
