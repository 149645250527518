<template>
  <input class="form-control" type="text" :id="id" @keyup="fancyInput" :value="value">
</template>

<script>
export default {
  name: 'FancyNumber',
  props: {
    id: String,
    value: String,
    prefix: {
      type: String,
      default: '$'
    }
  },
  watch: {},
  methods: {
    number_format(number) {
      let q = String(number),
        f = '',
        g = 0,
        s = (parseInt(q.length % 3) === 0) ? 3 : parseInt(q.length % 3);
      while (1) {
        if (q.substr(g, s) !== '') {
          f += q.substr(g, s) + ',';
          g += (g === 0) ? s : 3;
          s = 3;
        } else {
          break;
        }
      }
      return (f.substr(0, f.length - 1));
    },

    fancyInput(e) {
      let h = e.target,
        d = h.value.replace(/ /, ''),
        f = '';

      for (let i = 0; i <= d.length; i++) {
        if (isNaN(d.substr(i, 1))) {
        } else {
          f += d.substr(i, 1);
        }
      }

      h.value = (typeof (arguments[1]) === 'undefined' || arguments[1] === true) ? this.prefix +
        this.number_format(f) :
        '' +
        this.number_format(f);

      this.$emit('ev', h.value);

    }
  }
}
</script>
