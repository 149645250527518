import {createStore} from 'vuex';
import {UserStore} from "@/store/stores/user";
import {GameStore} from "@/store/stores/game";
import {JailStore} from "@/store/stores/jail";
import {LayoutStore} from "@/store/stores/layout";
import {NotificationStore} from "@/store/stores/notification";
import {ForumStore} from "@/store/stores/forum";
import {UserCollectionStore} from "@/store/stores/usercollection";
import {GarageStore} from "@/store/stores/garage";
import {Jobs} from "@/store/stores/jobs";
import {AchievementsStore} from "@/store/stores/achievements";
import {LotteryStore} from "@/store/stores/lottery";
import {CrewCities} from "@/store/stores/crew_cities";
import {Crew} from "@/store/stores/crew";
import {SearchStore} from "@/store/stores/search";
import {ChatStore} from "@/store/stores/chat";
import {MessagingStore} from "@/store/stores/messaging";
import {ExtortionStore} from "@/store/stores/extortion";
import {HelpdeskStore} from "@/store/stores/helpdesk";
import {FightStore} from "@/store/stores/fight";

export default createStore({
  modules: {
    user: UserStore,
    game: GameStore,
    jail: JailStore,
    layout: LayoutStore,
    notifications: NotificationStore,
    forum: ForumStore,
    userCollection: UserCollectionStore,
    garage: GarageStore,
    jobs: Jobs,
    achievements: AchievementsStore,
    lottery: LotteryStore,
    crewCities: CrewCities,
    crew: Crew,
    search: SearchStore,
    chat: ChatStore,
    messaging: MessagingStore,
    extortion: ExtortionStore,
    helpdesk: HelpdeskStore,
    fight: FightStore
  },
});
