<template>
  <div class="m-3">
    <div @click="goBack" class="d-md-none">
      Back
    </div>
    <Card>
      <template #header>
        Create a new thread
      </template>
      <template #body>
        <div class="py-3" :class="{'is-invalid border border-danger': errors.category_id}">
          <div class="mb-3">
              <span
                class="badge rounded-pill d-inline-block bg-primary px-3 py-2 me-3 cursor-pointer"
                :class="{'bg-success': thread.category_id === cat.id}"
                @click="thread.category_id = cat.id"
                v-for="cat in categories" :key="cat.id">
                  {{ cat.name }}
              </span>
          </div>

          <div v-if="errors.category_id">
            {{ errors.category_id[0] }}
          </div>
        </div>

        <div class="form-group mb-3">
          <label for="thread_title">Thread Title</label>
          <input type="text" id="thread_title" name="thread_title" class="form-control"
                 :class="{'is-invalid': errors.title}"
                 v-model="thread.title"/>
          <div class="invalid-feedback" v-if="errors.title">
            {{ errors.title[0] }}
          </div>
        </div>
        <div class="form-group mb-3">
          <label for="thread_post">Thread Content</label>
          <textarea name="thread_post" id="thread_post" class="form-control"
                    :class="{'is-invalid': errors.post}"
                    v-model="thread.post" cols="" rows="10"></textarea>
          <div class="invalid-feedback" v-if="errors.post">
            {{ errors.post[0] }}
          </div>
        </div>


        <button @click.prevent="createThread()" class="btn">Create Thread</button>

      </template>
    </Card>

  </div>
</template>

<script>
import Card from "@/components/layout/Card";
import {mapState} from "vuex";

export default {
  name: 'CreateThread',
  components: {Card},
  data() {
    return {
      errors: [],
      thread: {
        title: null,
        post: null,
        category_id: null,
      },
      loading: false,
    }
  },
  methods: {

    goBack() {
      this.$router.go(-1);
    },
    createThread() {
      this.$http.post(`/forums`, this.thread)
        .then(res => {
          this.$router.push({
            name: 'forum.thread',
            params: {
              slug: res.data.slug
            }
          })
        })
        .catch(err => {

          this.errors = err.response.data.errors;
        })
        .then(() => {
          this.loading = false;
        })
    }
  },
  computed: {
    ...mapState({
      categories: state => state.forum.categories
    })
  }
}
</script>
