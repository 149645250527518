<template>
  <div class="w-100 h-100">
    <div class="wrap d-flex flex-row h-100 align-items-center">
      <div class="title mx-5 my-0 ms-4 d-flex flex-column">
        <h1>Crime<span class="text-danger">Bloc</span></h1>
      </div>

      <div
        class="d-none d-md-flex flex-row justify-content-center ms-auto me-3 stat-progress-bars">
        <stat-progress :percent="calculate_health" stroke="red" icon="health" :hover="`Your health`"
                       class="me-3"></stat-progress>
        <stat-progress :percent="calculate_rank_progress"
                       stroke="cyan" icon="experience" class="me-3">
          <template #popper>

            <h4>{{ currentRank.name }}</h4>

            <h6>Rankup requirements</h6>
            <ul class="list-unstyled">
              <li v-for="(requirement, index) in Object.keys(nextRank.requirements)" :key="index"
              :class="{
                'text-success': stats[requirement] >= nextRank.requirements[requirement]
              }">
                {{ niceName(requirement) }}
                {{ stats[requirement] }} / {{ nextRank.requirements[requirement] }}
              </li>
            </ul>

          </template>
        </stat-progress>
        <stat-progress :percent="calculate_happiness" stroke="orange" :icon="activityIcon"
        :hover="`Your happiness affects your actions, do things that make you happy to gain more happiness`"></stat-progress>
      </div>


      <div class="d-none d-md-flex flex-row">
        <div id="layout-avatar-head" @click="goToProfile">
<!--          <Avatar :user="user" id="game-avatar" width="auto" />-->
        </div>
      </div>

      <div class="d-block d-lg-none ms-auto nav-icon" @click="toggleNav">
        <i class="flaticon-menu me-4" />
      </div>
    </div>
  </div>
</template>
<script>
import StatProgress from "@/components/stat-progress";
import {mapGetters, mapState} from "vuex";
import Avatar from "@/components/user/Avatar.vue";

export default {
  name: 'TopBar',
  components: { StatProgress},
  computed: {
    ...mapState({
      health: state => state.user.stats.health,
      username: state => state.user.username,
      stats: state => state.user.stats,
      ranks: state => state.game.ranks,
      user: state => state.user,
      xp: state => state.user.stats.xp,
      currentRankId: state => state.user.stats.rank,
    }),
    currentRank() {
      return this.ranks[this.currentRankId]
    },
    nextRank() {
      return this.ranks[this.currentRankId + 1]
    },
    time() {
      return this.$store.state.game.time.getUTCHours() + ":" + this.$store.state.game.time.getUTCMinutes() + ":" + (this.$store.state.game.time.getUTCSeconds() < 10 ? '0' : '') + this.$store.state.game.time.getUTCSeconds();
    },
    calculate_happiness() {
      return Math.floor(
        (100 / this.stats.max_happiness) * (this.stats.happiness)
      )
    },
    calculate_health() {
      return Math.floor(
        (100 / this.stats.max_health) * (this.stats.health)
      )
    },

    calculate_rank_progress() {
      const currentRankId = this.$store.state.user.stats['rank'];
      let nextRank = this.ranks[currentRankId + 1],
        currentRank = this.ranks[currentRankId];

      if(!nextRank)
        return 100;

      const xp = this.xp;

      // if (nextRank.experience <= 100)
      //   return xp;

      const actualPercent = Math.floor(100 / (nextRank.experience - (currentRank.experience)) * (xp - currentRank.experience));

      // You can gain more xp than rank but not rank up if requirements are not met
      if(actualPercent > 100)
        return 100;

      return actualPercent;
    },
    title() {
      return this.$route.name
    },
    activityIcon() {

      if(this.calculate_happiness > 90) {
        return 'happy'
      } else if(this.calculate_happiness > 50) {
        return 'smile'
      } else if(this.calculate_happiness > 40) {
        return 'neutral'
      } else if(this.calculate_happiness > 20) {
        return 'sad'
      } else {
        return 'dead'
      }

    }
  },
  methods: {
    toggleNav() {
      this.$store.state.layout.menu_nav_open = !this.$store.state.layout.menu_nav_open
    },
    goToProfile() {
      this.$router.push({
        name: 'profile',
        params: {
          username: this.username
        }
      })
    },
    niceName(name) {
      return name.replace('_', ' ');
    }
  }
}
</script>
