<template>
  <li>
    <VTooltip placement="right" :triggers="getTriggers">
      <router-link :to="{name: link, params: linkParams}">
        <i :class="`flaticon-${icon} menu-icon`"/>
        <span class="text-capitalize">{{ getName }}</span>
        <slot name="append">
        <span class="ms-auto dot transition-medium" v-if="timer" :class="{
          'bg-success': timerReady(timer),
          'bg-danger': !timerReady(timer)
        }"></span>
        </slot>

      </router-link>

      <template #popper class="d-flex align-items-center">
        <span v-if="!timerReady(timer)">
          <time-until :time="getAbsoluteTimer(timer)"></time-until>
        </span>
        <span v-else>
          Ready
        </span>
      </template>
    </VTooltip>
  </li>
</template>
<script>
import TimeUntil from "@/components/timeuntil.vue";

export default {
  name: "MenuItem",
  components: {TimeUntil},
  props: {
    timer: {
      default: false,
      required: false,
    },
    progress: {},
    name: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    hover: {
      default: false,
      type: Boolean
    },
    linkParams: {
      type: Object,
      required: false,
    }
  },
  computed: {
    getTriggers() {
      if (!this.hover) {
        return [];
      }

      return ['hover', 'click']
    },
    getName() {
      return this.name || this.link.replaceAll('-', ' ')
        .replaceAll('_', ' ')
    }
  },
  methods: {
    timerReady(timer) {
      return this.$store.getters['user/timerReady'](timer)
    },
    getAbsoluteTimer(timer) {
      return this.$store.getters['user/getTimerAbsoluteTime'](timer)
    }
  }
}
</script>
