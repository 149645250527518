<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-12">
        <div class="alert bg-dark" v-for="request in pending" :key="request.id">
          {{ request.sender.username }} has sent you a friend request
          <p class="mt-2 mb-0">
            <button @click="acceptFriendRequest(request.sender.username)" class="btn me-3">
              Accept
            </button>
            <button @click="declineFriendRequest(request.sender.username)" class="btn bg-danger">
              Decline
            </button>
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <Card class="mb-4">
          <template #header>
            <h5>Friends</h5>
          </template>
          <template #body>

            <div class="table-responsive">
              <table class="table table-striped table-bordered align-middle">
                <thead>
                <tr>
                  <th>Username</th>
                  <th>Rank</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="r in friends" :key="r.id">
                  <td>
                    <username :username="getOtherUser(r).username" :icon="false"/>
                    <span v-if="r.status === 'pending'" class="font-italic">(Pending)</span>
                  </td>
                  <td>{{ getOtherUser(r).rank }}</td>
                  <td>
                    <button @click="removeRequest(getOtherUser(r).username)" class="btn">
                      <template v-if="r.status === 'pending'">Cancel</template>
                      <template v-else>Remove</template>
                    </button>
                  </td>
                </tr>
                <tr v-if="friends.length === 0">
                  <td colspan="10">
                    You have no friends :(
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-12 col-md-6">
        <Card class="mb-4">
          <template #header>
            <h5>Block list</h5>
          </template>
          <template #body>

            <div class="table-responsive">
              <table class="table table-striped table-bordered align-middle">
                <thead>
                <tr>
                  <th>Username</th>
                  <th>Rank</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="r in blocked" :key="r.id">
                  <td>
                    <username :username="getOtherUser(r).username" :icon="false"/>
                    <span v-if="r.status === 'pending'" class="font-italic">(Pending)</span>
                  </td>
                  <td>{{ getOtherUser(r).rank }}</td>
                  <td>
                    <button @click="unblock(getOtherUser(r).username)" class="btn">
                      <template v-if="r.status === 'pending'">Cancel</template>
                      <template v-else>Remove</template>
                    </button>
                  </td>
                </tr>
                <tr v-if="blocked.length === 0">
                  <td colspan="10">
                    You haven't blocked anyone yet
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div class="row" v-if="canHavePushNotifications">
      <div class="col-12">
        <Card>
          <template #header>
            <h3>Notifications</h3>
          </template>
          <template #body>
            <button @click="enablePushNotifications" class="btn"
                    v-if="pushNotificationStatus !== 'granted'">
              Enable Push Notifications
            </button>
            <button @click="disablePushNotifications" class="btn" v-else>
              Disable Push Notifications
            </button>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import Card from "@/components/layout/Card.vue";
import Username from "@/components/Username.vue";
import {PushNotifications} from "@capacitor/push-notifications";

export default {
  name: "MyAccount",
  components: {Username, Card},
  computed: {
    ...mapState({}),
    ...mapGetters({
      friends: "user/friends",
      blocked: "user/blocked",
      pending: "user/pending",
      id: "user/id",
    }),
  },
  data() {
    return {
      canHavePushNotifications: true,
      pushNotificationStatus: 'denied'
    }
  },
  mounted() {
    PushNotifications.checkPermissions()
      .then(perm => {
        this.canHavePushNotifications = true;
        this.pushNotificationStatus = perm.receive

      })
      .catch(err => {
        this.canHavePushNotifications = false;

      });
  },
  methods: {
    acceptFriendRequest(username) {
      this.$http.post('/relationships/accept', {
        username
      }).catch(() => {
      })
    },
    declineFriendRequest(username) {
      this.$http.post('/relationships/ignore', {
        username
      }).catch(() => {
      })
    },
    getOtherUser(request) {
      return request.recipient.id === this.id ? request.sender : request.recipient;
    },
    removeRequest(username) {
      if (window.confirm("Are you sure you want to remove this person?"))
        this.$http.post('/relationships/removeFriend', {
          username
        }).catch(() => {
        })
    },
    unblock(username) {
      if (window.confirm("Are you sure you want to remove this person?"))
        this.$http.post('/relationships/unblock', {
          username
        }).catch(() => {
        })
    },
    async enablePushNotifications() {

      let permStatus = await PushNotifications.checkPermissions();

      // if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
      // }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    },
    async disablePushNotifications() {
      let permStatus = await PushNotifications.checkPermissions()

      if (permStatus.receive === 'granted') {
        await PushNotifications.unregister();

        await this.$http.post('/push-notifications/unsubscribe')
      }
    },
  }
}
</script>
