<template>
  <div>
    <img :src="getAvatar" class="mh-100" :width="width" v-if="user.avatar"/>
    <img src="https://ui-avatars.com/api/?name=John+Doe&rounded=true&size=32"/>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object
    },
    width: {
      default: 60,
    }
  },
  computed: {
    getAvatar() {
      if (this.user.avatar.head)
        return this.user.avatar.head

      return this.user.avatar.head_url
    }
  }
}
</script>
