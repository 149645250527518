<template>
  <div class="position-relative bg-image-hover p-5 cursor-pointer animate-on-hover" @click="commit">
    <div class="bg-image" :style="{backgroundImage: `url(${gta.image})`}" style="color:red"></div>
    <div class="d-flex flex-column p-4 mt-auto">
      <div class="p-2">
        <h4 class="bg-opacity-75 bg-black d-inline-block p-3 mb-0">
          {{ gta.name }}
        </h4>
        <p class="bg-opacity-75 bg-black p-2 slide-in fade-in transition-fast">{{ gta.description }}</p>

      </div>
      <div class="ms-auto mt-auto">
        <Button>Attempt</Button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";
import Button from "@/components/Button.vue";

export default {
  name: "GtaSingle",
  components: {Button},
  props: {
    id: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapState({
      gtaOptions: state => state.game.gta
    }),
    gta() {
      return this.gtaOptions[this.id];
    }
  },
  methods: {
    commit() {
      this.$http.post(
        'gta',
        {
          id: this.id
        }
      )
    }
  }
}
</script>
