<template>
  <div v-if="inCrew">

    <CrewHeader/>

    <div class="row g-0">
      <div class="col-12 col-lg-6">
        <Card class="m-3">
          <template #header>
            <h5>Deposit into crew bank</h5>
          </template>
          <template #body>
            <form action="" @submit.prevent="donate">
              <div class="form-group mb-2">
                <label for="amount">Amount</label>
                <FancyNumber :id="'amount'" @ev="(input) => {form.amount = input}"
                             :value="form.amount"/>
              </div>

              <Button type="submit" :loading="loading">Donate</Button>
            </form>
          </template>
        </Card>
      </div>
      <div class="col-12 col-lg-6">
        <Card class="m-3">
          <template #header>
            <h5>Withdraw from crew bank</h5>
          </template>
          <template #body>
            <form action="" @submit.prevent="withdraw">

              <div class="form-group mb-2">
                <label for="amount">Amount</label>
                <FancyNumber :id="'amount'" @ev="(input) => {form.withdraw = input}"
                             :value="form.withdraw"/>
              </div>

              <Button type="submit" :loading="loading">Withdraw</Button>
            </form>
          </template>
        </Card>
      </div>
    </div>
    base

    <button @click="destroy">delet</button>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import FancyNumber from "@/components/FancyNumber";
import Card from "@/components/layout/Card.vue";
import Button from "@/components/Button.vue";
import CrewHeader from "@/views/Crews/CrewHeader.vue";

export default {
  name: 'CrewBase',
  components: {CrewHeader, Button, Card, FancyNumber},
  data() {
    return {
      form: {
        amount: "$",
        withdraw: "$",
      },
      loading: false,
    }
  },
  mounted() {
    // if(!this.inCrew) {
    //   this.$router.push('/game/crews')
    // }
  },
  computed: {
    ...mapGetters({
      crew: "crew/crew",
      inCrew: "crew/inCrew"
    }),
    bank() {
      return this.crew.bank;
    }
  },
  methods: {
    donate() {
      this.loading = true;
      this.$http.post('crews/deposit', {
        amount: this.form.amount
      })
        .then(() => {
          this.form.amount = "$";
          this.loading = false;

        })
    },
    withdraw() {
      this.loading = true;

      this.$http.post('crews/withdraw', {
        amount: this.form.withdraw
      })
        .then(() => {
          this.form.withdraw = "$";
          this.loading = false;
        })
    },
    destroy() {
      this.$http.post('crews/destroy')
        .then(() => {
          this.$router.push({
            name: 'crews'
          })
        })
    }
  }
}
</script>
