<template>
  <div class="position-relative h-100 mt-4 mt-lg-5">
    <div class="container-fluid">
      <div class="row">
        <Crime v-for="(crime, index) in crimes" :key="index" :id="index" class="col-12 col-md-3"></Crime>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import JailWaitTimer from "@/components/JailWaitTimer";
import Crime from "@/views/game/Crimes/Crime";

export default {
  name: 'Crimes',
  components: {Crime},
  data() {
    return {
      ready: true,
    }
  },
  mounted() {
    if (this.crimes.length === 0)
      this.$http.get('crimes')
        .then(res => this.$store.state.game.crimes = res.data)
  },
  computed: {
    ...mapState({
      crimes: state => state.game.crimes
    }),
    ...mapGetters({
      timeLeft: "user/timerTimeLeft"
    })
  },
  methods: {
    doCrime(id) {
    }
  }
}
</script>
