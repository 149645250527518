<template>
  <div class="card card-primary">
    <div class="card-header" v-if="showHeader && hasHeader">
      <slot name="header">

      </slot>
    </div>
    <div class="card-body p-0 rounded-0">
      <slot name="body">

      </slot>
    </div>
    <div class="card-footer">
      <slot name="footer">

      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Card',
  props: {
    showHeader: {
      default: true,
    }
  },
  computed: {
    hasFooter() {
      return this.$slots.footer;
    },
    hasHeader() {
      return this.$slots.header;
    }
  }
}
</script>
