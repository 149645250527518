<template>
  <div class="d-inline-block">
    <div class="username-container">
      <VTooltip :shown="false" :delay="0" :placement="'top'">
        <router-link :to="{name: 'profile', params: {username}}" class="text-decoration-none d-flex align-items-center">
          <i class="flaticon-user text-white me-1" v-if="icon"/>{{ username }}
        </router-link>

        <template #popper>
          <div class="d-flex flex-row align-items-center p-3" v-if="user && Object.keys(user).length > 0">
            <div class="player-avatar me-4">
              <Avatar :user="user" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="p-0 m-0">{{ user.username }}</h4>
              <div>
                <ul class="list-unstyled mb-0">
                  <li>{{ user.rank.name }}</li>
                  <li><span v-html="user.roles.join(', ')"></span></li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </VTooltip>
    </div>
  </div>
</template>
<style lang="scss">
.username-container {
  position: relative;
  cursor: pointer;

  .hover {
    display: none;
    background: black;
    padding: 15px;

  }

  &:hover {
    .hover {
      position: absolute;
      bottom: 0;
      display: block !important;
    }
  }
}
</style>
<script>
import {mapGetters} from "vuex";
import Avatar from "@/components/user/Avatar.vue";

export default {
  name: 'Username',
  components: {Avatar},
  props: {
    username: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    icon: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapGetters({
      userObject: "userCollection/getUserByUsername"
    }),
    user() {
      return this.userObject(this.username);
    }
  }
}
</script>
