<template>
  <button class="btn d-flex align-items-center justify-content-center px-4 btn-crimebloc"
          :disabled="loading" :type="type"
          :class="{
    'w-100': block,
    'pointer-events-none': loading
  }">
    <LoadingSpinner :loading="loading" :size="24" :inline="true" class="me-2"
                    :class="{'d-none': !loading}" :transparent="true"/>
    <span v-if="loading">{{ text }}</span>
    <span v-else class="d-flex align-items-center">
      <slot></slot>
    </span>
    <span class="btn-bg-shine"></span>
    <span class="btn-border-animated-top"></span>
    <span class="btn-border-animated-bottom"></span>
  </button>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: {LoadingSpinner},
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: 'Loading ...'
    },
    dots: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'button',
    },
    block: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    text() {
      let string = this.loadingText;

      if (this.dots)
        string += '...'

      return string
    }
  },
}
</script>
