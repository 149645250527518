function hex2rgb(rgb) {
  rgb = rgb
    .replace('rgba(', '')
    .replace('rgb(', '')
    .replaceAll(',', '')
    .replaceAll(' ', '')
    .replace(')', '');

  const aRgbHex = rgb.match(/.{1,2}/g)
    .slice(0, 3);

  const a = [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16)
  ];

  return a;
}

function split_color(color) {
  const c = color
    .replace('rgba(', '')
    .replace('rgb(', '')
    .replace(')', '');

  return c;
}

export const LayoutStore = {
  namespaced: true,
  state: () => ({
    layout: {
      social_active_tab: 'notifications',
      colors: {
        primary: '#830000',
        secondary: '#0a759f',
      },
      menu_nav_open: false,
    }
  }),
  mutations: {
    LOAD_THEME(state) {
      state.layout = Object.assign(state.layout, (JSON.parse(localStorage.getItem('layout')) || {}))
    },
    UPDATE_THEME(state, data) {
      let primary = data.primary,
        secondary = data.secondary;

      if (primary)
        state.layout.colors.primary = primary

      if(secondary)
        state.layout.colors.secondary = secondary
    },
    SAVE_LAYOUT(state) {
      localStorage.setItem('layout', JSON.stringify(state.layout))
    },
    RENDER_THEME(state, data) {
      const primary = state.layout.colors.primary;
      const secondary = state.layout.colors.secondary;

      const root = document.querySelector(':root');

      // root.style.setProperty('--bs-primary', primary)
      // root.style.setProperty('--bs-primary-rgb', split_color(primary))

      // root.style.setProperty('--bs-secondary', secondary)
      // root.style.setProperty('--bs-secondary-rgb', split_color(secondary))
    }
  },
  getters: {
    layout(state) {
      return state.layout
    }
  },
  actions: {
    UPDATE_THEME({commit}, data) {
      commit('UPDATE_THEME', data)
      commit('RENDER_THEME')
      commit('SAVE_LAYOUT')
    },
    INIT({commit}) {
      commit('LOAD_THEME')
      commit("RENDER_THEME")
    }
  }
}
