<template>
  <div>
    <MobileNavStats class="d-md-none"/>
    <h4 class="menu-bg-game"><span>Game</span></h4>
    <ul class="list-unstyled">
      <MenuItem link="airport" icon="airport" :hover="true" timer="airport"/>
      <MenuItem link="gta" icon="car-grade-4" :hover="true" timer="gta"/>
      <MenuItem link="bullet-factory" icon="bullets"/>
      <MenuItem link="cities" icon="city"/>
      <MenuItem link="crew-base" icon="crew-turf" v-if="inCrew"/>
      <MenuItem link="crews" icon="crew" name="Crew Turf" v-if="inCrew"/>
      <MenuItem link="crews" icon="crew"/>
      <MenuItem link="crimes" icon="crimes" :hover="true" timer="crimes"/>

      <li>
        <VTooltip placement="right">
          <router-link :to="{name: 'criminal-jobs'}">
            <div
              class="overlay justify-content-center align-items-center d-flex animate-complete-job"
              v-if="justFinishedJob === true">
              <i class="flaticon-jobs me-2"/> <h6 class="mb-0">Completed</h6>
            </div>
            <i class="flaticon-jobs menu-icon"/>Criminal Jobs
            <span class="ms-auto" v-if="getJobProgress !== false">
              <stat-progress :percent="getJobProgress" :stroke="`green`" :height="24"
                             :triggers="[]"/></span>
          </router-link>

          <template #popper>
            <span v-if="getJobProgress !== false">
              {{ getJobProgress + '%' }}
            </span>
            <span v-else>
              No Job In Progress
            </span>
          </template>
        </VTooltip>
      </li>

      <MenuItem link="extortion" icon="extortion" :hover="true" timer="extortion"/>
      <MenuItem icon="health-care" link="health-care"/>
      <MenuItem icon="garage" link="garage"/>
      <MenuItem icon="gym" link="gym" :hover="true" timer="gym"/>
      <MenuItem link="fight" icon="murder" />
      <MenuItem icon="inventory" link="inventory"/>
      <MenuItem icon="operations" link="operations"/>
      <MenuItem icon="organised-crime" link="organised-crime" :hover="true" timer="oc"/>

      <li>
        <VTooltip placement="right">
          <router-link :to="{name: 'prison'}">
            <i class="flaticon-jail menu-icon"/>
            Prison
            <span class="ms-auto dot transition-medium" :class="{
              'bg-success': usersInJail.length > 0,
              'bg-danger': usersInJail.length === 0
            }"></span>
          </router-link>
          <template #popper>
            <span v-if="usersInJail.length > 0">
              {{ usersInJail.length }} users in jail
            </span>
            <span v-else>No one in jail</span>
          </template>
        </VTooltip>
      </li>

      <MenuItem icon="protection-racket" name="Protection Racket" timer="protection-racket"
                link="protection-racket" :hover="true"/>
      <MenuItem icon="robbery" name="Robbery Test" timer="robbery" link="robbery" :hover="true"/>
      <MenuItem icon="smuggle" name="Smuggling" timer="smuggle_negotiate" link="smuggling"
                :hover="true"/>
    </ul>
    <h4 class="menu-bg-money">
      <span>Money</span>
    </h4>
    <ul class="list-unstyled">
      <MenuItem link="bank" icon="bank" :name="`${city.name} bank`"/>
      <MenuItem link="credits" icon="diamond"/>
      <MenuItem link="gun-store" icon="gun-store"/>
      <MenuItem link="marketplace" icon="marketplace"/>
    </ul>
    <h4 class="menu-bg-communications">
      <span>Communication</span>
    </h4>
    <ul class="list-unstyled">
      <MenuItem link="forums" icon="forums" :link-params="{category: 'general-discussion'}"/>
      <li>
        <a href="https://crimebloc.help" target="_blank">
          <i class="flaticon-wiki menu-icon"/>
          Wiki
        </a>
      </li>
      <MenuItem link="help-desk" icon="help-desk"/>
      <MenuItem link="messaging" icon="messaging">
        <template #append>
          <span class="ms-auto" v-if="unreadCount > 0">{{ unreadCount }}</span>
        </template>
      </MenuItem>
    </ul>
    <h4 class="menu-bg-casino">
      <span>Casino</span>
    </h4>
    <ul class="list-unstyled">
      <MenuItem link="blackjack" icon="playing-cards"/>
      <MenuItem link="keno" icon="keno"/>
      <MenuItem link="lottery" icon="lottery"/>
      <MenuItem link="roulette" icon="roulette"/>
      <MenuItem link="slots" icon="slots"/>
      <MenuItem link="war" icon="war"/>
    </ul>
    <h4 class="menu-bg-various">
      <span>Various</span>
    </h4>
    <ul class="list-unstyled">
      <MenuItem link="achievements" icon="achievements" />
      <MenuItem link="home" icon="home" />
      <MenuItem link="my-account" icon="my-account" />
      <MenuItem link="my-progress" icon="my-progress" />
      <MenuItem link="my-skills" icon="skills">
        <template #append>
        <span class="ms-auto dot transition-medium" v-if="true" :class="{
          'bg-success': hasStat('skill_points'),
          'd-none': !hasStat('skill_points')
        }"></span>
        </template>
      </MenuItem>
      <li @click.prevent="logout">
        <a href="#">
          <i class="flaticon-logout menu-icon"/>
          Logout
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import StatProgress from "@/components/stat-progress";
import MobileNavStats from "@/components/layout/mobile-nav-stats.vue";
import TimeUntil from "@/components/timeuntil.vue";
import MenuItem from "@/components/layout/menu-item.vue";

export default {
  name: 'Menu',
  components: {MenuItem, MobileNavStats, StatProgress},
  methods: {
    logout() {
      if(localStorage.getItem('_token')) {
        localStorage.removeItem('_token')
        localStorage.removeItem('_character')
        location.reload();
      }
    },
    timerReady(timer) {
      return this.$store.getters['user/timerReady'](timer)
    },
    timerTimeLeft(timer) {
      return this.$store.getters['user/timerTimeLeft'](timer)
    },
    getAbsoluteTimer(timer) {
      return this.$store.getters['user/getTimerAbsoluteTime'](timer)
    },
    hasStat(stat) {
      return this.userHasStat(stat)
    }
  },
  sockets: {
    JOB_UPDATE() {
      // Javascript is fun, stores get executed first, but this should always be second to we wrap it in an interval lol
      setTimeout(() => {
        if (this.justFinishedJob) {
          setTimeout(() => {
            this.$store.state.jobs.justFinishedJob = false;
          }, 3500)
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      city: "user/city",
      inCrew: "crew/inCrew",
      userid: "user/id",
      usersInJail: "jail/usersInJail",
      userHasStat: "user/getStat",
    }),
    ...mapState({
      jobs: state => state.jobs.jobs,
      user: state => state.user,
      justFinishedJob: state => state.jobs.justFinishedJob,
    }),
    unreadCount() {
      return this.$store.getters["messaging/unreadCount"](
        this.userid
      );
    },
    getJobProgress() {
      if (!this.jobs)
        return;

      const job = this.jobs.find(j => j.user_id === this.user.id && j.completed_at === null);

      if (!job)
        return false;

      let neededReqs = 0,
        progress = 0;

      job.requirements.map((j) => {
        neededReqs += j.amount;
        progress += job.progress[j.name];
      });

      return Math.round(Math.floor((100 / neededReqs) * progress));
    },
  }
}
</script>
