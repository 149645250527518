<template>
  <div>
    <h3>Friend Request</h3>
    You became friends with <username :icon="false" :username="item.item.user.username" />
  </div>
</template>
<script>
import Username from "@/components/Username.vue";

export default {
  name: "FriendRequest",
  components: {Username},
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
