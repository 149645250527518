function money(money) {
  return new Intl.NumberFormat('en-gb').format(money)
}

function unsnake(text) {
  return text.split('_').join(' ');
}

export default {
  money,
  unsnake
}
