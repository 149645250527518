<template>
  <div class="position-fixed bg-black bg-opacity-75 z-30 jail-timer-overlay">
    <div class="row mt-5">
      <div class="col d-flex justify-content-center align-items-center flex-column mt-5">
        <h4 class="fw-bold text-uppercase">In Jail</h4>

        <h1 class="fw-bolder text-primary shadow-secondary">{{ jailTime }}s</h1>
        <h6>until released</h6>
      </div>
    </div>
  </div>
</template>
<script>
import TimeUntil from "@/components/timeuntil";
import {mapGetters, mapState} from "vuex";

export default {
  name: 'JailWaitTimer',
  computed: {
    ...mapState({
      jailTimer: state => state.user.timers.jail
    }),
    ...mapGetters({
      timeLeft: "user/timerTimeLeft"
    }),
    jailTime() {
      return this.timeLeft('jail')
    }
  }
}
</script>
