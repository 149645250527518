<template>
  <div class="card">
    <div class="card-body d-flex flex-column align-items-center">
      <i :class="`flaticon-${skill.icon || 'help-desk'}`" style="font-size:4rem" class="mb-4"/>
      <p class="mb-0">{{ skill.description }}</p>
      <p>Current Skill Level {{ currentSkillLevel }}</p>
      <form action="" @submit.prevent="increaseSkill">
        <Button type="submit" :loading="loading">+ Increase</Button>
      </form>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Button from "@/components/Button.vue";

export default {
  name: "Skillbox",
  components: {Button},
  props: {
    skill: {
      type: Object,
      required: true,
    },
    skillName: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      "skills": "user/skills"
    }),
    currentSkillLevel() {
      return this.skills[this.skillName] || 0;
    }
  },
  methods: {
    increaseSkill() {
      this.loading = true;

      this.$http.post('skills/increase', {
        skill: this.skillName
      })
        .catch(() => {
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>
