<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-header-image mb-4">
                      header
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Blank'
    }
</script>
