<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-12 col-lg-6">
        <Card class="mb-3">
          <template #header>
            Create a new ticket
          </template>

          <template #body>
            <form method="post" @submit.prevent="submit">
              <div class="mb-3">
                <label for="category" class="form-label">Category</label>
                <select name="" id="" class="form-control" v-model="form.category">
                  <option :value="o" v-for="(o, i) in categories" :key="i">{{ o }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="category" class="form-label">Subject</label>
                <input type="text" class="form-control" v-model="form.subject">
              </div>
              <div class="mb-3">
                <label for="category" class="form-label">Subject</label>
                <textarea type="text" class="form-control" v-model="form.question" />
              </div>

              <Button type="submit">Submit Ticket</Button>
            </form>
          </template>
        </Card>
      </div>
      <div class="col-12 col-lg-6">

      </div>
    </div>
    <div class="row">
      <div class="col">
        <Card>
          <template #header>
            Your tickets
          </template>
          <template #body>
            <table class="table table-hover table-striped table-bordered table-dark">
              <thead>
              <tr>
                <th>Category</th>
                <th>Subject</th>
                <th>Status</th>
                <th>Last Updated</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in tickets" :key="index" class="cursor-pointer" @click="goToTicket(item.id)">
                <td>{{ item.category }}</td>
                <td>{{ item.subject }}</td>
                <td>{{ item.display_status }}</td>
                <td><TimeAgo :time="item.updated_at" /></td>
              </tr>
              </tbody>
            </table>
          </template>

        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/layout/Card.vue";
import {mapGetters} from "vuex";
import Button from "@/components/Button.vue";
import TimeAgo from "@/components/timeago.vue";

export default {
  name: "Helpdesk",
  components: {TimeAgo, Button, Card},
  created() {
    this.getTickets()
  },
  data() {
    return {
      form: {},
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      categories: "helpdesk/categories",
      tickets: "helpdesk/tickets"
    })
  },
  methods: {
    getTickets() {
      this.$http.get('helpdesk/tickets')
        .then(res => {
          this.$store.commit("helpdesk/SET_TICKETS", res.data.tickets);
          this.$store.commit("helpdesk/SET_CATEGORIES", res.data.categories)
        })
    },
    submit() {
      this.$http.post('helpdesk/ticket', this.form)
        .then((res) => {
          this.form = {}
          this.$store.commit("helpdesk/ADD_TICKET", res.data.ticket)
        })
    },
    goToTicket(id) {
      this.$router.push({
        name: 'help-desk.ticket',
        params: {
          id
        }
      })
    }
  }
}
</script>
