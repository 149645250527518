<template>
  <router-link :to="{name: 'forum.thread', params: {slug: thread.slug}}"
               class="text-decoration-none d-block px-2 forum-link"
               :class="{'bg-warning': thread.new}">
    <div class="row g-0">
      <div class="col-12">

        <div class="d-flex flex-row py-2">
          <div style="width: 50px" class="me-2">
            <Avatar :user="user" style="max-height: 40px" v-if="user"/>
          </div>
          <div class="d-flex flex-column">
            <h5 class="mb-1">
              {{ thread.title }}
            </h5>
            <span class="text-muted" v-if="thread.new">New Posts</span>

            <div class="mt-auto d-flex align-items-center">
              Written by
              <Username :username="thread.author.username" class="ms-1"/>
            </div>
          </div>
          <div class="ms-auto d-flex flex-column">
            <TimeAgo :time="thread.created_at" class="mt-auto"/>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import {mapGetters} from "vuex";
import TimeAgo from "@/components/timeago";
import Username from "@/components/Username";
import Avatar from "@/components/user/Avatar.vue";

export default {
  name: 'ThreadBit',
  components: {Avatar, Username, TimeAgo},
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      findThread: "forum/thread"
    }),
    thread() {
      return this.findThread(this.id)
    },
    excerpt() {
      return this.thread?.post?.substr(0, 120)
    },
    head_url() {
      return this.user?.avatar.head;
    },

    ...mapGetters({
      userObject: "userCollection/getUserByUsername"
    }),
    user() {
      return this.userObject(this.thread.author.username);
    }
  }
}
</script>
