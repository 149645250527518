<template>
  <div class="mt-3">
    <div class="container-fluid ">
      <div class="row">
        <div class="col-12 col-lg-6 mb-3">
          <Card class="mb-3">
            <template #header>
              <h4>Attempt Extortion</h4>
            </template>
            <template #body>
              <MultiUserAutoComplete @usersChanged="updateRecipients"
                                     placeholder="Search for a user"
                                     :max-tags="1"
                                     class="mb-2"></MultiUserAutoComplete>
              <div class="d-flex">
                <Button class="btn" @click="extort" :loading="loading"
                        loading-text="Extorting user">
                  Extort
                </Button>
                <!--                <Button class="btn ms-auto" @click="extortRandom" :loading="loading" loading-text="Extorting user">-->
                <!--                  Extort Random Player-->
                <!--                </Button>-->
              </div>
            </template>
          </Card>

          <Card>
            <template #header>
              <h4>Recent Attempts</h4>
            </template>

            <template #body>
              <div class="table-responsive">
                <table class="table table-dark table-bordered table-hover">
                  <thead>
                  <tr>
                    <th>Victim</th>
                    <th>Result</th>
                    <th>Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(log, index) in logs" :key="index" class="align-middle">
                    <td>{{ log.victim.username }}</td>
                    <td v-if="log.outcome">
                      Success
                      <div>
                        {{ log.amount }}x {{ log.type }}
                      </div>
                    </td>
                    <td v-else-if="!log.outcome">Fail</td>
                    <td>
                      <TimeAgo :time="log.created_at"/>
                    </td>
                  </tr>
                  <tr v-if="!logs.length">
                    <td colspan="3">No recent attempts have been made</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </Card>
        </div>
        <div class="col-12 col-lg-6">
          <Card>
            <template #header>
              <h4>Statistics</h4>
            </template>
            <template #body>
              <p>Extort money, bullets and cars from other players. But be careful, you could become
                a
                target yourself!</p>
              <div class="table-responsive">
                <table class="table table-dark table-bordered table-hover">
                  <thead>
                  <tr>
                    <th>Item</th>
                    <th>Amount Won</th>
                    <th>Amount Lost</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th colspan="4">Items Stolen</th>
                  </tr>
                  <tr v-for="(s, index) in extortionStats" :key="index">
                    <td class="text-capitalize">{{ prettyName(s) }}</td>
                    <td>{{ stats[s + '_won'] || 0 }}</td>
                    <td>{{ stats[s + '_lost'] || 0 }}</td>
                  </tr>
                  <tr>
                    <th colspan="4">Attempts</th>
                  </tr>
                  <tr>
                    <td class="text-capitalize">Attempts</td>
                    <td>{{ stats['extortion_attempts_won'] || 0 }}</td>
                    <td>{{ stats['extortion_attempts_lost'] || 0 }}</td>
                  </tr>
                  <tr>
                    <td class="text-capitalize">Counters</td>
                    <td>{{ stats['extortion_counters_won'] || 0 }}</td>
                    <td>{{ stats['extortion_counters_lost'] || 0 }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import Card from "@/components/layout/Card.vue";
import MultiUserAutoComplete from "@/components/MultiUserAutoComplete.vue";
import Button from "@/components/Button.vue";
import TimeAgo from "@/components/timeago.vue";

export default {
  name: "Extortion",
  components: {TimeAgo, Button, MultiUserAutoComplete, Card},
  data() {
    return {
      extortionStats: [
        'extortion_item_vehicles',
        'extortion_item_robbery_items',
        'extortion_item_bullets',
        'extortion_item_smuggled_goods',
        'extortion_item_money',
        'extortion_prison_time'
      ],
      loading: false,
      userToExtort: '',
      loadingHistory: true,
    }
  },
  computed: {
    ...mapGetters({
      stat: "user/getStat",
      stats: "user/stats",
      logs: "extortion/logs"
    }),
    getExtortionItemStats() {
      return Object.keys(this.stats).filter(s => {
        return s.includes('extortion_item')
      })
    },
    getExtortionAttemptsStats() {
      return Object.keys(this.stats).filter(s => {
        return s.includes('extortion_attempts')
      })
    },
  },
  mounted() {
    this.loadingHistory = true;
    this.$http.get(
      'extortion/logs'
    ).then(res => {
      this.$store.commit("extortion/SET_HISTORY", res.data)
    }).finally(() => {
      this.loadingHistory = false;
    })
  },
  methods: {
    getStat(stat) {
      return this.stat(stat);
    },
    prettyName(s) {
      return s.replaceAll('extortion_item', '')
        .replaceAll('extortion_attempts', '')
        .replace("_won", '')
        .replace("_lost", '')
        .replaceAll('_', ' ')
    },
    updateRecipients(recipients) {
      this.userToExtort = recipients;
    },
    extort() {
      this.loading = true;
      this.$http.post('/extortion', {
        username: this.userToExtort[0]?.text
      })
        .then(res => {
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
    extortRandom() {
      this.loading = true;
      this.$http.post('/extortion/random')
        .then(res => {
        }).catch(() => {
      }).finally(() => {
        this.loading = false;
      });
    },
  }
}
</script>
