<template>
  <div>
    <div class="d-flex flex-row">
      <AchievementBarSingle v-for="(achievement, index) in achievements"
                            :key="index"
                            :id="achievement.achievement_id"/>
    </div>
  </div>
</template>

<script>
import AchievementBarSingle from "@/components/achievements/AchievementBarSingle";

export default {
  name: "AchievementBar",
  components: {AchievementBarSingle},
  props: {
    achievements: {
      type: Object
    }
  }
}
</script>
