export const Crew = {
  namespaced: true,
  state: () => ({
    crew: null,
  }),
  mutations: {
    SOCKET_CITY_BUILDING_PLACED(state, data) {
      state.crew.city.buildings.push(data.data.message);
    },
    SOCKET_CREW_BANK_UPDATE(state, data) {
      state.crew.bank = data.data.bank;
    },
    SOCKET_SET_CREW(state, data)
    {
      state.crew = data;
    },
    SOCKET_USER_UPDATE_CREW(state, data) {
      state.crew = data.data.crew;
    },
    SOCKET_LEAVE_CREW(state, data) {
      state.crew = null;
    },
  },
  actions: {
    // SET_LOTTERY(ctx, data) {
    //   ctx.state.data.jackpot = data;
    // },
  },
  getters: {
    crew(state) {
      return state.crew
    },
    inCrew(state) {
      return state.crew && Object.keys(state.crew).length > 0
    }
  }
}
