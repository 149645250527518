export const FightStore = {
  namespaced: true,
  state: () => ({
    data: {
      currentOpponents: [],
      opponent: null,
    }
  }),
  mutations: {
    SET_OPPONENTS(state, data) {
      state.data.currentOpponents = data;
    },
    SET_OPPONENT(state, data) {
      state.data.opponent = data;
    }
  },
  actions: {

  },
  getters: {
    opponents(state) {
      return state.data.currentOpponents
    },
    opponent(state) {
      return state.data.opponent;
    }
  }
}
