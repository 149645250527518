<template>
  <div>
    <div class="table-responsive mb-5">
      <table class="table table-dark">
        <thead>
        <tr>
          <th>Crew Name</th>
          <th>Boss</th>
          <th>Underboss</th>
          <th>Members</th>
          <th>Recruiting</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="crew in crews" :key="crew.id">
          <td>{{ crew.name }}</td>
          <td>
            <Username :username="crew.boss.username"/>
          </td>
          <td>{{ crew.underboss ? crew.underboss.username : 'None' }}</td>
          <td>{{ crew.members ? crew.members.length : '1' }}</td>
          <td v-if="crew.recruiting === 0">No</td>
          <td v-if="crew.recruiting === 1">Yes</td>
        </tr>
        <tr v-if="crews.length === 0">
          <td colspan="10" class="text-center">
            No crews have been formed yet
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row g-0">
      <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
        <Card>
          <template #header>
            <h5>Create a new crew</h5>
          </template>
          <template #body>
            <div class="form-group">
              <label for="crew-name">Crew Name</label>
              <input type="text" v-model="crew_form.name" class="form-control" id="crew-name"
                     :class="{'is-invalid': errors.name}">
              <div class="invalid-feedback" v-if="errors.name">
                {{ errors.name[0] }}
              </div>
            </div>
            <div class="form-group">
              <label for="crew-size">Crew Size</label>
              <select name="" v-model="crew_form.size" class="form-control" id="crew-size"
                      :class="{'is-invalid': errors.size}">
                <option value="10">10 members - $10,000,000</option>
                <option value="20">20 members - $25,000,000</option>
                <option value="50">50 members - $100,000,000</option>
              </select>

              <div class="invalid-feedback" v-if="errors.size">
                {{ errors.size[0] }}
              </div>
            </div>
          </template>
          <template #footer>
            <Button @click="createCrew" :loading="loading">Create your crew</Button>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Username from "@/components/Username";
import Card from "@/components/layout/Card.vue";
import Button from "@/components/Button.vue";

export default {
  name: 'CrewHome',
  components: {Button, Card, Username},
  data() {
    return {
      crews: [],
      crew_form: {
        name: null,
        size: 10,
      },
      loading: false,
      errors: {}
    }
  },
  mounted() {
    this.$http.get('crews')
      .then(res => this.crews = res.data)
  },
  methods: {

    createCrew: function () {
      this.loading = true;
      this.$http.post('/crews/create', {
        name: this.crew_form.name,
        size: this.crew_form.size
      }).then((res) => {

        this.errors = {};
        this.$router.push({
          name: 'crew-base'
        })
        this.$socket.emit('refresh channels')
      })
        .catch((err) => {

          // this.errors = err.response.data.errors;
        }).then(() => {
          this.loading = false;
      })
    }
  }
}
</script>
