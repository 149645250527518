<template>
  <div>
    <VueTagsInput
      v-model="user"
      :autocomplete-items="userResults"
      :add-only-from-autocomplete="true"
      @tags-changed="update"
      :placeholder="users.length >= maxTags ? 'User Limit Reached' : placeholder"
      :max-tags="maxTags"
      @keydown="disableTypingIfFull"
    >

      <template #autocomplete-item="{performAdd, item}">
        <div @click="performAdd(item)">
          {{ item.text }} - {{ item.rank }}
        </div>
      </template>
    </VueTagsInput>
  </div>
</template>

<script>
import VueTagsInput from "@sipec/vue3-tags-input";

export default {
  name: 'MultiUserAutoComplete',
  components: {
    VueTagsInput
  },
  props: {
    maxTags: {
      type: Number,
      default: 5
    },
    placeholder: {
      type: String,
      default: "Add User"
    }
  },
  data: function () {
    return {
      user: '',
      users: [],
      userResults: []
    }
  },
  methods: {
    disableTypingIfFull(e) {
      if(this.users.length >= this.maxTags) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    },
    checkUser(user) {
      if (!user.hasOwnProperty('id'))
        alert("Invalid user selected")
    },
    update: function (newUsers) {

      this.user = '';
      this.userResults = [];
      this.users = newUsers;
      this.$emit('usersChanged', newUsers);
    },
    searchUsers: function () {
      if(this.users.length > this.maxTags)
        return;

      return this.$http.post('/search/players/multi', {player: this.user})
        .then(res => {
          if (res.data.length > 0) {
            this.userResults = res.data.map(a => {
              return {
                text: a.username,
                rank: a.rank,
                id: a.id
              }
            });
          } else {
            this.userResults = []
          }

        });
    }
  },
  watch: {
    'user': 'searchUsers'
  }
}
</script>
