<template>
  <div class="position-relative h-100 d-flex flex-column">
    <loading-spinner :loading="loading"/>
    <div class="row g-0">
      <div class="col bg-dark">
        <div class="d-flex flex-row py-4 px-4">
          <div class="filter d-flex align-items-center">
            <i class="flaticon-filter me-3"/>
            <button @click="genderChange('male')" class="btn"><i class="flaticon-man"/>
            </button>
            <button @click="genderChange('female')" class="btn"><i
              class="flaticon-woman"/>
            </button>
          </div>
          <div class="ms-auto d-flex align-items-center">
            <i class="flaticon-money me-2"/>
          </div>
          <div class="d-flex align-items-center">
            <button @click="random" class="btn me-2">Randomize</button>
            <Button @click="save" :loading="loading" loading-text="Saving">
              Save Avatar
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="row g-0 flex-grow-1">
      <div class="avatar-builder">
        <div id="avatar-builder-preview">
          <div class="preview">
            <div class="spotlight">
              <div class="blur">
                <div class="light"></div>
              </div>
            </div>
            <div class="build">
              <img :src="img.url" alt="" v-for="(img, index) in buildParsed" :class="img.category"
                   :key="index">
            </div>
          </div>
        </div>
        <div id="avatar-builder-selection">
          <ul class="categories">
            <li v-for="(value, index) in categories" @click="activeCategory = value"
                class="btn btn-primary" :key="index">
              <VTooltip :delay="0">
                <img :src="`/images/avatar_icons/${value}.png`" :alt="value"/>

                <template #popper>
                  {{ value }}
                </template>
              </VTooltip>
            </li>
          </ul>

          <li @click="remove(activeCategory)" v-if="canClear(activeCategory)" class="w-100">
            <button class="btn btn-primary p-3 bg-secondary w-100">Clear</button>
          </li>
          <PerfectScrollbar>
            <ul class="pieces d-flex d-row w-100">
              <li v-for="(piece) in categoryItems" class="piece" @click="select(piece)"
                  :key="piece.id">
                <img :src="piece.trimmed_url" alt="">
              </li>
            </ul>
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.avatar-builder {
  display: grid;
  grid-template-areas: "preview selection";
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;

  #avatar-builder-header {
    grid-area: header;
  }

  #avatar-builder-selection {
    grid-area: selection;
    display: flex;
    flex-direction: column;

    .pieces {
      height: 100%;
    }
  }

  #avatar-builder-preview {
    grid-area: preview;
  }

  #avatar-builder-options {
    grid-area: options;
  }
}
</style>
<script>
import {mapState} from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Button from "@/components/Button.vue";

export default {
  name: "AvatarBuilder",
  components: {Button, LoadingSpinner},
  data: () => {
    return {
      options: [],
      activeCategory: 'skin',
      old_build: {
        male: [],
        female: [],
      },
      loading: false,
    }
  },
  mounted() {
    this.$http.get('avatar/parts')
      .then(res => {
        this.options = res.data;
      });
  },
  methods: {
    select(piece) {
      this.$store.state.user.avatar.pieces = this.$store.state.user.avatar.pieces.filter(i => i.category !== piece.category)
      this.$store.state.user.avatar.pieces.push(piece);
    },
    remove(cat) {
      if (cat !== 'skin' && cat !== 'head' && cat !== 'hand')
        this.$store.state.user.avatar.pieces = this.$store.state.user.avatar.pieces.filter(i => i.category !== cat)

    },
    canClear(cat) {
      return !['skin', 'head', 'eyes', 'mouth', 'nose'].includes(cat)
    },
    genderChange(gender) {
      if (['male', 'female'].includes(gender)) {
        this.old_build[this.gender] = this.$store.state.user.avatar.pieces;

        if (this.old_build[gender])
          this.$store.state.user.avatar.pieces = this.old_build[gender];


        this.$store.state.user.avatar.avatar.gender = gender;

        if (this.$store.state.user.avatar.pieces.length === 0)
          this.random();
      }
    },
    random() {
      this.$http.get('avatar/random', {
        params: {
          gender: this.$store.state.user?.avatar?.avatar?.gender || 'male'
        }
      })
        .then(res => {
          if (!this.$store.state.user.avatar)
            this.$store.state.user.avatar = {}
          this.$store.state.user.avatar.pieces = res.data.pieces
        })
    },
    save() {
      this.loading = true;

      this.$http.post('avatar/save', {
        pieces: this.build.map(i => i.id),
        gender: this.gender,
      })
        .then(res => {
          this.$store.state.user.avatar = res.data;

          this.loading = false;
        })
    }
  },
  computed: {
    ...mapState({
      build: state => state.user.avatar?.pieces,
      gender: state => state.user.avatar?.avatar?.gender || 'male'
    }),
    previewUrl() {
      const url = new URL(`avatar`);
      for (const k in this.build) {
        const build = this.build[k];
        url.searchParams.append(build.category, build.id)
      }
      return url.toString()
    },
    categoryItems() {
      return this.options.filter(i => i.category === this.activeCategory && i.gender === this.gender)
        .map(s => {
          if (!s.trimmed_url.startsWith('http'))
          s.trimmed_url = process.env.VUE_APP_CDN_PATH + s.trimmed_url
          return s;
        })
    },
    categories() {
      let cat = [];
      let blacklist = ['back_item', 'wallpaper']
      this.options.filter(i => {
        if (!cat.includes(i.category) && i.gender === this.gender && !blacklist.includes(i.category))
          cat.push(i.category)
      })

      return cat;
    },
    buildParsed() {
      return this.build
        .map(s => {
          if (!s.url.startsWith('http'))
            s.url = process.env.VUE_APP_CDN_PATH + s.url
          return s;
        })
    }
  }
}
</script>
