<template>
  <span>
    {{ new Intl.NumberFormat('en-gb').format(displayNumber) }}
  </span>
</template>

<script>
export default {
  name: 'CountUp',
  props: {
    number: {
      type: Number,
      default: 0,
    }
  },
  data: function () {
    return {
      displayNumber: 0,
      interval: false
    }
  },
  mounted: function () {
    this.displayNumber = Math.floor(this.number) ? Math.floor(this.number) : 0;
  },
  watch: {
    number: function () {
      clearInterval(this.interval);
      if (this.number === this.displayNumber) {
        return;
      }
      this.interval = setInterval(function () {
        if (this.displayNumber !== this.number) {
          let change = (this.number - this.displayNumber) / 10;
          change = change >= 0 ? Math.ceil(change) : Math.floor(change);
          this.displayNumber = this.displayNumber + change;
        }
      }.bind(this), 20);
    }

  }
}
</script>
