<template>
  <div>
    Heal now!

    <div>Health: {{ health }}</div>
    <div>Max Health: {{ maxHealth }}</div>
    <div>Money: {{ money }}</div>

    <form action="" @submit.prevent="heal">
      <Button :loading="loading" type="submit">Heal for <i class="flaticon-money ms-1" /> {{ cost }}</Button>
    </form>
  </div>
</template>
<script>
import Button from "@/components/Button.vue";
import {mapState} from "vuex";

export default {
  name: "HealBox",
  components: {Button},
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      health: state => state.user.stats.health,
      maxHealth: state => state.user.stats.max_health,
      money: state => state.user.items.money?.amount
    }),
    cost() {
      const baseCost = Math.floor(this.money / 1000);
      let percent = 100 - Math.floor((100 / this.maxHealth) * this.health);

      return new Intl.NumberFormat('en-gb')
        .format(percent * baseCost);
    }
  },
  methods: {
    heal() {
      this.loading = true;
      this.$http.post('healthcare/heal')
        .finally(() => this.loading = false)
    }
  }
}
</script>
