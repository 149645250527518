<template>
  <VTooltip>

    <div class="p-3 me-2 mb-2 bg-opacity-50">
      <div class="ms-auto icon-container bg-transparent"
           :class="[`bg-grade-${achievement.grade}`]">
        <i :class="`flaticon-${achievement.icon} icon-large fs-0 icon-gradient d-block`"/>
      </div>
    </div>

    <template #popper>
      <h5>{{ achievement.title }}</h5>
      Completed {{ achievement.msg }}
    </template>
  </VTooltip>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'AchievementBarSingle',
  props: {
    id: {
      type: Number,
      require: true,
    },
  },
  computed: {
    ...mapState({
      achievements: state => state.achievements.items
    }),
    achievement() {
      return this.achievements.find(a => a.id === this.id)
    }
  }
}
</script>
