<template>
  <div class="reel" ref="reel">
    <div v-for="(item, i) in reel.reel" :key="i">
      <img :src="`/images/misc/slots/${item}.png`" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Reel',
  props: {
    reel: {
      type: Object,
      required: true,
    },
    running: {
      type: Boolean,
      required: false,
    },
    winningPlace: {
      type: Number,
    },
    index: {
      type: Number,
    }
  },
  watch: {
    reel: {
      handler(current, old) {
        //
        const reel = this.$refs['reel'];
        const offset = (current.winPos - 1) * 93.3;

        if (current.running === false) {
          reel.style.transition = 'none';
          reel.style.transform = `translateY(0px)`;
        } else {
          reel.style.transition = `all ease-in-out 6s`;
          reel.style.transform = `translateY(-${offset}px)`;
        }
      },
      deep: true
    },
  }
}
</script>
