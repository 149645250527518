<template>
  <div v-if="lottery">
    <div class="row g-0 bg-warning bg-gradient-black-transparent p-4">
      <div class="col d-flex flex-row justify-content-center align-items-center">
        <div class="d-flex align-items-center">
          <h1 class="mb-0 d-flex align-items-center"><i class="flaticon-money icon-large fs-0 me-2" /> <CountUp :number="lottery.jackpot" /> <span class="ms-5 text-muted">jackpot</span></h1>
        </div>
        <div class="ms-auto">
          <h3 class="mb-0"><TimeUntil :time="lottery.timeToGo" :icon="false" text="until next draw" /></h3>
          <h5 class="mb-0 text-muted"><CountUp :number="lottery.totalTicketsBought" /> tickets bought</h5>
        </div>
      </div>
    </div>
    <div class="row g-0 bg-dark p-4">
      <div class="col">
        <h3>Purchase Lottery Tickets</h3>
        <h5 class="text-muted">You currently have <strong class="text-white"><CountUp :number="lottery.ticketsBought" /></strong> tickets</h5>
        <div class="form-group mb-4">
          <label for="amount">Amount of tickets to buy</label>
          <input type="text" v-model="purchaseForm.amount" id="amount" class="form-control">
        </div>

        <button @click="purchaseTickets" class="btn btn-primary">Buy {{ purchaseForm.amount }} Tickets</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import TimeUntil from "@/components/timeuntil";
import formats from '@/helpers/formats'
import CountUp from "@/components/count-up";

export default {
  name: 'Lottery',
  components: {CountUp, TimeUntil},
  data() {
    return {
      purchaseForm: {
        amount: 0
      }
    }
  },
  beforeMount() {
    this.$http.get('casino/lottery')
      .then(res => {
        this.$store.commit("lottery/SOCKET_SET_JACKPOT", res.data)
      })
  },
  computed: {
    ...mapState({
      lottery: state => state.lottery.data
    })
  },
  methods: {

    purchaseTickets() {
      this.$http.post('casino/lottery/purchase-tickets', {
        amount: this.purchaseForm.amount
      })
        .then(() => {
          this.purchaseForm.amount = 0;
        })
    },
    money_format(money) {
      return formats.money(money)
    }

  }
}
</script>
