export const JailStore = {
  namespaced: true,
  state: () => ({
    users: [],
  }),
  actions: {
    SOCKET_JAIL_ADD(ctx, data) {
      ctx.state.users.push(data.data)

    },
    SOCKET_PUT_IN_JAIL(ctx, data) {
      // @todo implement a nice way to notify the user they are in jail
    },
    SOCKET_JAIL_REMOVE(ctx, data) {
      ctx.state.users = ctx.state.users.filter(s => s.user_id !== data.data.user_id)
    },
    SOCKET_JAIL_INIT(ctx, data) {
      ctx.state.users = data;
    }
  },
  getters: {
    isInJail(ctx, data, rootState) {
      return !!ctx.state.users.find(i => i.user_id === rootState.user.id)
    },
    usersInJail(ctx, data, rootState, rootGetters) {
      const currentCity = rootGetters["user/city"].id;
      return ctx.users;
    }
  }
}
