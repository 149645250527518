export const LotteryStore = {
  namespaced: true,
  state: () => ({
    data: {
      jackpot: null,
      totalTicketsBought: 0,
      ticketsBought: 0,
      timeToGo: null,
    }
  }),
  mutations: {
    SOCKET_SET_JACKPOT(state, data) {
      state.data = data;
    },
    SOCKET_LOTTERY_JACKPOT_UPDATE(state, data) {
      state.data.jackpot = data.data.jackpot;
    },
    SOCKET_UPDATE_LOTTERY_GLOBAL_TICKETS(state, data) {
      state.data.totalTicketsBought += Number(data.data);
    },
    SOCKET_PERSONAL_LOTTERY_TICKET_PURCHASE(state, data) {
      state.data.ticketsBought = Number(data.data);
    }
  },
  actions: {
    SET_LOTTERY(ctx, data) {
      ctx.state.data.jackpot = data;
    },
  }
}
