<template>
  <TransitionGroup>
    <div id="game" v-if="ready" :class="{'noscroll': isOpen}">
      <div id="detail-bar">
        <DetailBar/>
      </div>
      <div id="layout-topbar" class="">
        <TopBar/>
      </div>
      <Transition name="slide">
        <div id="layout-menu" :class="{'layout-nav-open': isOpen}" v-if="isOpen">
          <Menu/>
        </div>
      </Transition>
      <div id="layout-content" :class="{'overflow-hidden': showScrollBar}">
        <Transition>
          <JailWaitTimer v-if="timeLeft('jail') !== '' && shouldShowTimer"/>
        </Transition>
          <WaitTimerOverlay timer="gta" v-if="timeLeft('gta') !== '' && isGta" post-text="until ready"
                            class="jail-timer-overlay w-100"
                            :whole-page="true"
                            header="rest"/>
        <router-view></router-view>
      </div>
      <MobileNav class="d-md-none"/>
    </div>
    <div v-else class="position-absolute">
      <div
        class="d-flex flex-column text-center justify-content-center align-items-center vh-100 vw-100 position-relative px-4">
        <div class="position-relative" style="width: 200px;height: 200px;">
          <loading-spinner :loading="true"/>
        </div>
        <template v-if="!progress.error">
          <div class="progress w-50 bg-dark">
            <div class="progress-bar bg-primary text-black"
                 :style="{width: progress.count * (100 / progress.total) + '%'}"
            >
            </div>
          </div>
        </template>
        <template v-else>
          Error has occurred, please refresh
        </template>
      </div>
    </div>
  </TransitionGroup>
</template>
<script>
import Menu from "@/components/layout/menu";
import TopBar from "@/components/layout/topbar";
import DetailBar from "@/components/layout/detail-bar";
import {mapGetters, mapState} from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import MobileNav from "@/components/MobileNav.vue";
import JailWaitTimer from "@/components/JailWaitTimer.vue";
import WaitTimerOverlay from "@/components/WaitTimerOverlay.vue";

export default {
  name: 'Game',
  data() {
    return {
      root: null,
      ready: false,
      progress: {
        count: 0,
        total: 0,
        error: false,
      },
    }
  },
  components: {
    WaitTimerOverlay,
    JailWaitTimer,
    MobileNav, LoadingSpinner, DetailBar, TopBar, Menu
  },
  mounted() {

    window.addEventListener('resize', () => {
      this.$store.state.layout.menu_nav_open = window.innerWidth >= 992;
      this.$store.commit("game/SET_MOBILE", window.innerWidth < 992)
    });

    if(window.innerWidth >= 992)
      this.$store.state.layout.menu_nav_open = true;
    else
      this.$store.commit("game/SET_MOBILE", window.innerWidth < 992)

    // Set initial colors if they have them in their localStorage
    this.$store.dispatch("layout/INIT");

    const gameInit = [
      {
        path: 'user',
        cb: (res) => {
          this.$store.state.user = res.data.user;
        },
      },
      {
        path: 'cities',
        cb: (res) => {
          this.$store.state.game.cities = res.data;
        },
      },
      {
        path: 'crews/crew',
        cb: (res) => {
          this.$store.commit("crew/SOCKET_SET_CREW", res.data);
        },
      },
      {
        path: 'jobs',
        cb: (res) => {
          this.$store.state.jobs.jobs = res.data;
        },
      },
      {
        path: 'achievements',
        cb: (res) => {
          this.$store.state.achievements.items = res.data;
        },
      },
      {
        path: 'garage',
        cb: (res) => {
          this.$store.state.garage.cars = res.data.cars;
        },
      },
      {
        path: 'conversations',
        cb: (res) => {
          this.$store.state.messaging.conversations = res.data;
        }
      },
      {
        path: 'ranks',
        cb: (res) => {
          this.$store.state.game.ranks = res.data
        }
      },
      {
        path: 'gta',
        cb: (res) => {
          this.$store.state.game.gta = res.data
        }
      },
      {
        path: 'crimes',
        cb: (res) => {
          this.$store.state.game.crimes = res.data
        }
      },
      {
        path: 'user/notifications?page=0',
        cb: (res) => {
          this.$store.commit("notifications/SET_NOTIFICATIONS", res.data)
        }
      },
      {
        path: 'smuggle',
        cb: (res) => {
          this.$store.state.game.smuggleItems = res.data;
        }
      },
      {
        path: 'game/config',
        cb: (res) => {
          this.$store.state.game.serverConfig = res.data;
        }
      },
    ];

    this.progress.total = gameInit.length;

    let apiCalls = [];

    for (const gameInitKey in gameInit) {
      const key = gameInit[gameInitKey];
      apiCalls.push(this.apiCall(key.path, key.cb))
    }

    Promise.all(apiCalls)
      .then(() => {

        this.ready = true;
      }).catch(() => {
      this.progress.error = true;
    })

  },
  methods: {
    apiCall(path, cb) {
      return this.$http.get(path)
        .then(res => {
          if (res.status === 200) {
            cb(res)
            this.progress.count++;
          }
        });
    }
  },
  computed: {
    showScrollBar() {
      return this.timeLeft('gta') && this.isGta
    },
    isGta() {
      return this.$route.name === 'gta'
    },
    isOpen() {
      return this.$store.state.layout.menu_nav_open;
    },
    hasUser() {
      return Object.keys(this.$store.state.user).length > 0
    },
    ...mapGetters({
      timeLeft: "user/timerTimeLeft"
    }),
    shouldShowTimer() {

      return !!this.$route.meta.showTimer;
    },
    ...mapState({
      layout: state => state.layout
    })
  },
}
</script>
