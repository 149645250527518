<template>
  <div>
    <div class="container-fluid g-0">
      <div class="row g-0">
        <div class="col">
          <div class="card card-crimebloc">
            <div class="card-body">
              <h1 class="page-title">Start a new Conversation</h1>
              <p>Below you can find our upgraded messaging platform, you can invite up to 5 users to
                a single group chat, or if you want it more personal, keep it one on one.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-0">
        <div class="col">
          <div class="card card-crimebloc">
            <loading-spinner :loading="loading === true"></loading-spinner>
            <div class="card-body">
              <div class="form-group">
                <label for="subject">Recipient</label>
                <multi-user-auto-complete  @usersChanged="updateRecipients"
                                           placeholder="Search for a user"
                                           :max-tags="1"
                                           class="mb-2">
                </multi-user-auto-complete>
              </div>
              <div class="form-group">
                <label for="question">Message</label>
                <textarea name="" id="question" class="form-control" cols="30" rows="10"
                          v-model="thread.message"></textarea>
              </div>
            </div>
            <div class="card-footer">
              <button class="btn btn-primary" @click="createThread">Start a new conversation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '../../../components/LoadingSpinner'
import store from "@/store";
import MultiUserAutoComplete from "@/components/MultiUserAutoComplete.vue";

export default {
  name: 'SendMessage',
  components: {
    MultiUserAutoComplete,
    LoadingSpinner
  },
  data: function () {
    return {
      thread: {
        message: '',
        recipient: ''
      },
      loading: false,
    }
  },
  beforeMount() {
    if (this.$route.query.user)
      this.thread.recipient = this.$route.query.user;
  },
  methods: {
    updateRecipients(recipient) {
      this.thread.recipient = recipient[0].text;
    },
    createThread() {

      this.loading = true;
      this.$http.post(`/conversations/create`, this.thread)
        .then(res => {

          // this.$root.$data.user.messages.unshift(res.data.thread);
          this.$store.commit('messaging/ADD_CONVERSATION', res.data.thread);
          this.$router.push({
            name: 'messaging.view',
            params: {
              conversation: res.data.thread.id,
            }
          })
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(() => {})
    },
  }
}
</script>
