<template>
  <div>
    <template v-if="result?.outcome">
      <div :class="{
                'border border-success': result.outcome === 'won',
                'border border-danger': result.outcome === 'lost'
              }" class="p-3 d-flex flex-column flex-lg-row">
        <div>
          You <span :class="{
                'text-success': result.outcome === 'won',
                'text-danger': result.outcome === 'lost'
              }">{{ result.outcome }}</span> against {{ result.defender.username }}, you
          {{ result.outcome === 'lost' ? 'lost' : 'won' }}
          <span class="d-flex align-items-center" :class="{
                    'text-danger': result.outcome === 'lost',
                    'text-success': result.outcome === 'won',
                  }">
              <i class="flaticon-money me-1"></i> {{ result.cash_gained }}
          </span>
          <span class="d-flex align-items-center text-danger">
              <i class="flaticon-health me-1"></i> You lost {{ result.attacker.health_lost }}
          </span>
          <span class="d-flex align-items-center text-danger">
              <i class="flaticon-health me-1"></i> They lost {{ result.defender.health_lost }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "FightResult",
  props: {
    result: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      dataToShow: [
        {
          name: 'cash_gained',
          icon: 'flaticon-money'
        },
      ]
    }
  }
}
</script>
