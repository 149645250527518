<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-3">
          <img :src="avatarBody" alt="" class="position-sticky top-0">
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col">

              <div class="card mb-3">
                <div class="card-body">
                  <h1>Welcome back
                    <Username :username="username"/>
                  </h1>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <Card class="mb-3 flex-fill">
                  <template #header>
                    <i class="bi bi-list-check"/> Todo
                  </template>
                  <template #body>
                    <ul>
                      <li>Show current rank</li>
                      <li>Show benefits of next rank</li>
                      <li>Show rankbar</li>
                    </ul>
                  </template>
                </Card>
              </div>
              <div class="col-6 d-flex" v-for="n in 20" :key="n">
                <Card class="mb-3 flex-fill d-flex flex-grow-1">
                  <template #header>
                    <i class="bi bi-list-check"/> Todo
                  </template>
                  <template #body>
                    <ul>
                      <li>Home page</li>
                      <li>Homepage widgets</li>
                    </ul>
                  </template>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import Username from "@/components/Username";
import Card from "@/components/layout/Card";

export default {
  name: 'Home',
  components: {Card, Username},
  computed: {
    ...mapGetters({
      avatarBody: "user/fullBodyAvatar"
    }),
    ...mapState({
      username: state => state.user.username
    })
  }
}
</script>
