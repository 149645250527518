<template>
  <li class="border-right-0 position-relative notification-bell cursor-pointer" @click="open = !open"
      :class="{'open': open}">
    <i class="flaticon-bell icon-gradient">
      <span class="small-dot"
            :class="{'notification-pulse': hasNewNotifications, 'opacity-0': !hasNewNotifications}"></span></i>
    <div class="notification-popup d-flex flex-column" :class="{'d-none': !open, 'open': open}" @click.stop>
      <LoadingSpinner :loading="loading" :inline="false" :size="30" />
      <div class="overlay-bg" @click="open = false"></div>
      <div class="notification-body cursor-auto" id="notification-container" ref="notification-container">

        <ul class="list-unstyled h-auto">
          <Notification :id="item.id" v-for="(item, index) in notifications" :key="index"/>
        </ul>


        <div v-if="noMoreResults" class="text-center py-2">
          No more results
        </div>
      </div>
    </div>
  </li>
</template>
<script>
import {mapGetters} from "vuex";
import TimeAgo from "@/components/timeago.vue";
import Notification from "@/components/layout/social/notifications/Notification.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "NotificationIcon",
  components: {LoadingSpinner, Notification},
  data() {
    return {
      open: false,
      loading: false,
      noMoreResults: false,
    }
  },
  computed: {
    ...mapGetters({
      "notifications": "notifications/privateItems",
      "lastRead": "user/notificationLastRead",
      "time": "game/time",
      "page": "notifications/page"
    }),
    hasNewNotifications() {
      const firstNotification = this.notifications.filter(s => !s.read_at);

      return firstNotification.length > 0
    },
  },
  mounted() {
    document.getElementById("notification-container")?.addEventListener("scroll", this.handleScroll)
  },
  unmounted() {
    document.getElementById("notification-container")?.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    toggle(e) {
      this.open = !this.open
      e.stopImmediatePropagation();

      // if(this.open) {
      //   this.$http.post('/user/notifications/read')
      // }
    },
    timerReady(timer) {
      return this.$store.getters['user/timerReady'](timer)
    },
    handleScroll() {
      let element = this.$refs['notification-container'];

      console.log(element.scrollTop + element.offsetHeight, element.scrollHeight)
      if (element.scrollTop + element.offsetHeight >= element.scrollHeight && !this.loading && !this.noMoreResults) {
        this.loading = true;
        console.log('load more posts')
        const page = this.page;

        this.$http.get(`user/notifications?page=${page}`)
          .then(res => {
            if(res.data.length > 0) {
              this.$store.commit("notifications/SET_NOTIFICATIONS", res.data)
            } else {
              this.noMoreResults = true;
            }
          })
          .finally(() => {
            this.loading = false;
          })
      }

    }
  },
}
</script>
