export const HelpdeskStore = {
  namespaced: true,
  state: () => ({
    tickets: [],
    categories: [],
    status: {
      1: "Approved",
      2: "Closed",
      3: "Waiting on your reply",
      4: "Waiting on staff"
    }
  }),
  mutations: {
    SET_TICKETS(state, data) {
      state.tickets = data
    },
    SET_CATEGORIES(state, data) {
      state.categories = data
    },
    ADD_TICKET(state, data) {
      state.tickets.push(data)
    },
    ADD_REPLY(state, data) {
      const ticket = state.tickets.find(s => s.id === Number(data.id));

      ticket.replies.push(data.reply)
    }
  },
  actions: {},
  getters: {
    tickets: (state) => {
      return state.tickets
    },
    categories: (state) => {
      return state.categories
    },
    ticket: (state) => (id) => {
      return state.tickets.find(s => s.id === Number(id))
    }
  }
}
