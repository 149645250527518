export const ForumStore = {
  namespaced: true,
  state: () => ({
    categories: [],
    threads: [],
    selectedCategory: null,
  }),
  mutations: {
    SOCKET_NEW_FORUM_THREAD(state, data) {
      data.data.new = true;
      state.threads.data.unshift(data.data)
    },
    SOCKET_NEW_FORUM_POST(state, data) {
      const thread = state.threads.data.find(t => t.id === data.data.thread_id);


      if(thread)
      {
        thread.comments.data.unshift(data.data)
      }
    }
  },
  getters: {
    thread: (state) => (id) => {
      if (!state.threads.data) return;
      if (Object.keys(state.threads.data).length === -1) return;
      return state.threads.data.find(t => t.id === id)
    },
    threadBySlug: (state) => (slug) => {

      if (!state.threads.data) return;
      return state.threads.data.find(t => t.slug === slug)
    },
    threads: (state) => {
      return state.threads;
    }
  }
}
