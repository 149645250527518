<template>
  <div>
    <div class="container">
      <div class="row" v-if="!timerReady('train')">
        <div class="col">
          <div class="card">
            <div class="card-body text-center">
              <time-until :time="$store.state.user.timers.train" /> before the next train arrives
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <CityCard class="col-sm-12 col-md-6" v-for="(location, index) in city.train_prices" :key="index" :name="location.destination" :city-id="city.id" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import TimeUntil from "@/components/timeuntil";
import CityCard from "@/components/cities/Card";

export default {
  name: 'TrainStation',
  components: {CityCard, TimeUntil},
  beforeMount() {
    const airports = this.$store.state.game.cities;

    if (airports.length === 0)
      this.$http.get('train-station').then(res => {
        this.$store.state.game.cities = res.data.cities;
      })
  },
  data: function () {
    return {
      loading: false,
      timer: 0
    }
  },
  computed: {
    cities() {
      let currentCity = this.$store.getters['user/city'];
      return this.cityList.find(c => c.id === this.city.id);
    },
    ...mapGetters({
      timerReady: 'user/timerReady',
      isAdmin: "user/isAdmin",
      city: "user/city"
    }),
    ...mapState({
      cityList: state => state.game.cities
    })
  },
  methods: {
  }
}
</script>
