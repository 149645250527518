<template>
  <div id="forum-grid" class="mh-game-container">
    <PageBar id="forum-grid-header">

      <router-link :to="{name: 'forums.new.thread'}">
        <Button>
          New Thread
        </Button>
      </router-link>


      <ul v-if="categories" class="ms-auto list-unstyled mb-0 d-flex flex-row">
        <li v-for="category in categories" :key="category.id" class="me-3">
          <router-link :to="{name: 'forums', params: {category: category.slug}}"
                       class="badge rounded-pill bg-primary px-3 py-2"
                       :class="{'bg-success': isActiveForum(category.slug)}">
            {{ category.name }}
          </router-link>
        </li>
      </ul>
    </PageBar>
    <div id="forum-grid-threads" class="">
      <div v-if="!threads || threads?.data?.length === 0">
        <div class="alert alert-info m-3">
          No threads have been created here yet
        </div>
      </div>
      <ThreadBit v-for="thread in threads.data" :id="thread.id" :key="thread.id"></ThreadBit>
    </div>
    <Transition name="slide">
      <div id="forum-grid-thread" :class="{'active': isActive}" v-if="isActive">
        <router-view name="thread"></router-view>
      </div>
    </Transition>
    <div id="forum-grid-pagination">
      <Bootstrap5Pagination :data="threads_meta" :show-disabled="true"
                            @pagination-change-page="getThreads"/>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import ThreadBit from "@/components/forums/ThreadBit.vue";
import PageBar from "@/components/PageBar";
import {Bootstrap5Pagination} from "laravel-vue-pagination";
import Button from "@/components/Button.vue";

export default {
  name: 'Forum',
  components: {Button, PageBar, ThreadBit, Bootstrap5Pagination},
  data() {
    return {
      page: 1,
    }
  },
  watch: {
    $route() {

      if (this.$route.name === 'forums')
        this.getThreads();
    }
  },
  beforeMount() {
    if (this.categories.length === 0)
      this.$http.get('forums/categories')
        .then(res => this.$store.state.forum.categories = res.data)


    if (!this.threads || Object.keys(this.threads).length === 0) {
      this.getThreads();
    }
  },
  methods: {
    getThreads(page = 0) {
      this.$http.get(`forums?page=${page}&category=${this.category}`)
        .then(res => {
          this.$store.state.forum.threads = res.data
          // this.$refs['forum-thread'].$el.scrollTop = 0;
        }).catch(() => {
      })
    },
    selectTab(name) {
      let cat = this.$store.state.forum.selectedCategory;

      if (cat === name)
        this.$store.state.forum.selectedCategory = null;
      else
        this.$store.state.forum.selectedCategory = name;
    },
    isActiveForum(forum) {
      return this.$route.params.category === forum;
    },
  },
  computed: {
    ...mapState({
      threads_meta: state => state.forum.threads,
      categories: state => state.forum.categories,
      selectedTab: state => state.forum.selectedCategory
    }),
    ...mapGetters({
      threads: "forum/threads"
    }),
    isActive() {
      return this.$route.name === 'forum.thread' || this.$route.name === 'forums.new.thread'
    },
    category() {
      return this.$route.params.category;
    }
  }
}
</script>
