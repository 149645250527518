<template>
  <li class="d-flex align-items-start flex-row align-items-center notification item"
      :class="{'new': isNew}" @mouseover="test">
    <div class="notification-icon">
      <i :class="[`flaticon-${iconClass}`]" :alt="iconClass"/>
    </div>
    <div class="d-flex flex-column flex-fill align-items-start ps-3">
      <div v-html="notification.data.message"></div>
      <time-ago :time="notification.created_at"/>
    </div>
    <span v-if="isNew" class="small-dot notification-pulse position-relative right-0"></span>

  </li>
</template>
<script>
import TimeAgo from "@/components/timeago";
import {mapGetters} from "vuex";

export default {
  name: 'Notification',
  components: {TimeAgo},
  props: {
    id: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      "lastRead": "user/notificationLastRead",
    }),
    notification() {
      return this.$store.getters['notifications/item'](this.id)
    },
    image() {
      return this.notification.data.image;
    },
    iconClass() {
      const type = this.notification.type;
      const icon = this.notification.data?.icon;

      if (icon)
        return icon;

      switch (type) {
        case 'SYSTEM':
          return 'star-fill'
      }

      return 'question-mark'
    },
    isNew() {
        const firstNotification = this.notification.created_at;

        return !this.notification.read_at
    },
  },
  methods: {
    test() {
      if(this.isNew) {

        this.notification.read_at = new Date();
        this.$http.post('user/notifications/read', {
          id: this.id
        })
      }
    }
  }
}
</script>
