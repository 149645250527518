<template>
  <div class="container-fluid g-0">
    <div class="row g-0">
      <div class="col-12 col-lg-6" v-for="(cat, index) in statCategories" :key="index">
        <h3 class="bg-gradient-black-transparent bg-primary mb-0 p-3 text-capitalize">{{ cat }}</h3>

        <table class="table table-striped table-dark align-middle mb-0">
          <tbody>

          <tr v-for="(stat, i) in statsInCategory(cat)" :key="i">
            <td class="text-capitalize">
              {{ stat.name }}
            </td>
            <td>
              <div class="d-flex align-items-center">
                <i class="flaticon-money me-2" v-if="['financial', 'gambling'].includes(cat)" />
                {{ new Intl.NumberFormat('en-gb').format(stat.value) }}
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'GameStats',
  data() {
    return {
      gameStats: [],
    }
  },
  mounted() {
    this.$http.get('game/stats')
      .then(res => this.gameStats = res.data)
  },
  computed: {
    stats() {
      return this.gameStats.map(stat => {
        const split = stat.key.split(':');

        stat.category = split[0].replaceAll('_', ' ');
        stat.name = split[1].replaceAll('_', ' ')
        return stat;
      })
    },
    statCategories() {
      return [...new Set(this.stats.map(s => s.category))]
    }
  },
  methods: {
    statsInCategory(category) {
      return this.stats.filter(s => s.category === category)
    }
  }
}
</script>
