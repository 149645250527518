export const CrewCities = {
  namespaced: true,
  state: () => ({
    city: null,
    buildings: [],
    selectedBuilding: null,
  }),
  getters: {
    tilesUsed(state) {
      let tiles = [];

      if(!state.city)
        return;

      for(const b of state.city.buildings) {
        for(const tile of b.tiles) {
          tiles.push(tile)
        }
      }



      return tiles;
    }
  },
  mutations: {
    SOCKET_CITY_BUILDING_PLACED(state, data) {
      state.city.buildings.push(data.data.message);
    },
  },
  actions: {
    // SET_LOTTERY(ctx, data) {
    //   ctx.state.data.jackpot = data;
    // },
  }
}
