<template>
  <div class="container-fluid position-relative">
    <div class="row">
      <GtaSingle v-for="(act, index) in gta" :key="index" :id="index" class="col-lg-6"/>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import GtaSingle from "@/components/gta/GtaSingle";
import TimeUntil from "@/components/timeuntil";
import JailWaitTimer from "@/components/JailWaitTimer";
import WaitTimerOverlay from "@/components/WaitTimerOverlay";

export default {
  name: 'GTA',
  components: {GtaSingle},
  mounted() {
    if (this.$store.state.game.gta.length === 0) {
      this.$http.get('gta')
        .then(res => this.$store.state.game.gta = res.data)
    }
  },
  computed: {
    ...mapGetters({
      timeLeft: "user/timerTimeLeft"
    }),
    ...mapState({
      gta: state => state.game.gta,
    }),
  }

}
</script>
