<template>
  <div class="overlay d-flex justify-content-center align-items-center bg-dark" v-html="text"></div>
</template>
<script>
export default {
  name: "LockedFeature",
  props: {
    locked: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "This feature is locked"
    }
  },
  computed: {

  }
}
</script>
