<template>
  <div>
    <PageBar>
      <div>
        <h3>{{ city.name }} Prison</h3>
      </div>
      <div class="ms-auto" v-if="isAdmin">
        <button @click="bustSelf" class="btn btn-primary ms-auto">Bust self</button>
      </div>
    </PageBar>
  </div>
  <div class="jail-grid">
    <div class="jail-content">
      <div>
        <table class="table table-hover table-striped">
          <thead>
          <tr>
            <th>Name</th>
            <th>Punishment</th>
            <th>Time</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <TransitionGroup>
            <JailRow v-for="inmate in usersInJail" :key="inmate.id" :index="inmate.id"/>
          </TransitionGroup>
          </tbody>
        </table>
      </div>
    </div>
    <div class="jail-aside bg-dark">
      <div class="bg-light">
        <h5
          class="text-uppercase mb-0 d-flex align-items-center bg-gradient-primary p-3 bg-opacity-75">
          <i
            class="flaticon-jail me-2"/>
          Prison Stats</h5>
        <div class="bg-dark">
          <div v-for="(stat, index) in userStats" :key="index">
            <h5 class="text-capitalize mb-1 fw-normal bg-gradient-black-transparent p-3">
              {{ statName(stat[0]) }}</h5>
            <div class="mb-1 py-1 px-3">
              {{ stat[1] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
</style>
<script>
import {mapGetters, mapState} from "vuex";
import JailRow from "@/components/jail/JailRow";
import PageBar from "@/components/PageBar";

export default {
  name: 'Jail',
  components: {PageBar, JailRow},
  computed: {
    ...mapGetters({
      usersInJail: "jail/usersInJail",
      city: "user/city",
      isAdmin: "user/isAdmin",
    }),
    ...mapState({
      userStats: state => Object.entries(state.user.stats).filter(s => s[0].indexOf('jail') > -1)
    }),
    getStat() {
      return this.userStats
    }
  },
  methods: {
    statName(name) {
      return name.replaceAll('_', ' ');
    },
    bustSelf() {
      this.$http.post('prison/bust/self')
    }
  }
}
</script>
