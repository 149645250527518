<template>
  <tr class="align-middle">
    <td>
      <Username :username="user.username"/>
    </td>
    <td>
      {{ inmate.reason }}
    </td>
    <td>
      <time-until :time="inmate.release_date"></time-until>

    </td>
    <td>
      <button class="btn" @click="bustUser">Bust user</button>

    </td>
  </tr>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import TimeUntil from "@/components/timeuntil";
import Username from "@/components/Username";
import Avatar from "@/components/user/Avatar.vue";

export default {
  name: 'JailRow',
  components: {Username, TimeUntil},
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getUserById: "userCollection/getUserById",
    }),
    ...mapState({
      inmates: state => state.jail.users
    }),
    inmate() {
      return this.inmates.find(u => u.id === this.index);
    },
    user() {
      return this.getUserById(this.inmate.user_id)
    }
  },
  methods: {
    bustUser() {
      this.$http.post('/prison/bust', {
        jail: this.inmate.id
      })
    }
  }
}
</script>
